import classNames from "classnames";
import { CiroButtonStyleEnum } from "../../shared/CiroButton";
import {
  CrmObjectType,
  FinalizePushToCrm_BeginCreateCrmRecordsMutation,
  FinalizePushToCrm_BeginCreateCrmRecordsMutationVariables,
  FinalizePushToCrm_ContactListFragment,
  FinalizePushToCrm_CrmAccountsTextSearchQuery,
  FinalizePushToCrm_CrmAccountsTextSearchQueryVariables,
} from "../../../__generated__/graphql";
import CiroButton from "../../shared/CiroButton";
import { useParams } from "react-router-dom";
import FinalizePushToCrmTable from "./FinalizePushToCrmTable";
import { FormDuplicateActionsType } from "../ReviewDuplicatesComponents/AutomationListReviewDuplicates";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { AutomationListStateEnum } from "../../../routes/automationList/AutomationList";
import IntegrationTypeContext from "../../../contexts/IntegrationTypeContext";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import SkeletonLoading from "../../shared/SkeletonLoading";
import ListContext from "../../../contexts/ListContext";

export interface IFilteredContact {
  id: number;
  org_contact: {
    id: number;
    firstName: string | null | undefined;
    lastName: string | null | undefined;
    title: string | null | undefined;
    linkedinId: string | null | undefined;
    companyName: string | null | undefined;
    companyWebsiteDomain: string | null | undefined;
    companyLinkedinId: string | null | undefined;
    orgPhoneNumbers: {
      phone_number: string;
    }[];
    orgEmails: {
      email: string;
    }[];
    accountId: string | null | undefined;
  };
}

export const FinalizePushToCrm_ContactList = gql`
  fragment FinalizePushToCrm_ContactList on ContactList {
    id
    contacts {
      id
      org_contact {
        id
        first_name
        last_name
        title
        linkedin_id
        company_name
        company_website_domain
        company_linkedin_id
        orgPhoneNumbers {
          phone_number
        }
        orgEmails {
          email
        }
      }
    }
  }
`;

const FinalizePushToCrm_BeginCreateCrmRecords = gql`
  mutation FinalizePushToCrm_BeginCreateCrmRecords(
    $contactListId: Int!
    $externalObjectType: CrmObjectType!
    $orgContacts: [BeginCreateCrmRecordsOrgContactInput!]!
  ) {
    beginCreateCrmRecords(
      input: {
        contactListId: $contactListId
        externalObjectType: $externalObjectType
        orgContacts: $orgContacts
      }
    ) {
      success
      transaction {
        id
      }
      message
    }
  }
`;

const FinalizePushToCrm_CrmAccountsTextSearch = gql`
  query FinalizePushToCrm_CrmAccountsTextSearch($queries: [String!]!) {
    crmAccountsTextSearch(queries: $queries) {
      query
      records {
        id
        name
        website
      }
    }
  }
`;

const getCrmObjectLabel = (crmObject: CrmObjectType) => {
  if (crmObject === CrmObjectType.Contact) return "contacts";
  if (crmObject === CrmObjectType.Lead) return "leads";
  return "records";
};

const FinalizePushToCrm = ({
  setViewState,
  crmObject,
  duplicateActions,
  contactList,
  filteredContactIdsToOrgContactIds,
}: {
  setViewState: (viewState: AutomationListStateEnum) => void;
  crmObject: CrmObjectType;
  duplicateActions: FormDuplicateActionsType["formDuplicateActions"];
  contactList: FinalizePushToCrm_ContactListFragment | null;
  filteredContactIdsToOrgContactIds: Map<number, number>;
}) => {
  const { listId } = useParams();
  const { integrationType } = useContext(IntegrationTypeContext);
  const client = useApolloClient();
  const [
    beginCreateCrmRecords,
    {
      loading: beginCreateCrmRecordsLoading,
      error: beginCreateCrmRecordsError,
    },
  ] = useMutation<
    FinalizePushToCrm_BeginCreateCrmRecordsMutation,
    FinalizePushToCrm_BeginCreateCrmRecordsMutationVariables
  >(FinalizePushToCrm_BeginCreateCrmRecords);

  const [filteredContacts, setFilteredContacts] = useState<IFilteredContact[]>(
    [],
  );

  const { selectedContactIdsToOrgContactIds } = useContext(ListContext);

  useEffect(() => {
    const newFilteredContacts = contactList?.contacts.filter((contact) => {
      if (!filteredContactIdsToOrgContactIds.has(contact.id)) return false;
      if (
        selectedContactIdsToOrgContactIds.size > 0 &&
        !selectedContactIdsToOrgContactIds.has(contact.id)
      ) {
        return false;
      }
      const duplicateAction = duplicateActions.find(
        (action) => action.orgContactId === contact.org_contact.id,
      );
      return duplicateAction?.action !== "skip";
    });
    setFilteredContacts(
      (newFilteredContacts || []).map((contact) => ({
        id: contact.org_contact.id!,
        org_contact: {
          id: contact.org_contact.id!,
          firstName: contact.org_contact.first_name,
          lastName: contact.org_contact.last_name,
          title: contact.org_contact.title,
          linkedinId: contact.org_contact.linkedin_id,
          companyName: contact.org_contact.company_name,
          companyWebsiteDomain: contact.org_contact.company_website_domain,
          companyLinkedinId: contact.org_contact.company_linkedin_id,
          orgPhoneNumbers: contact.org_contact.orgPhoneNumbers,
          orgEmails: contact.org_contact.orgEmails,
          accountId: "NEW_ACCOUNT",
        },
      })),
    );
  }, [
    contactList,
    duplicateActions,
    filteredContactIdsToOrgContactIds,
    selectedContactIdsToOrgContactIds,
  ]);

  const {
    data: crmAccountsTextSearchData,
    loading: crmAccountsTextSearchLoading,
  } = useQuery<
    FinalizePushToCrm_CrmAccountsTextSearchQuery,
    FinalizePushToCrm_CrmAccountsTextSearchQueryVariables
  >(FinalizePushToCrm_CrmAccountsTextSearch, {
    variables: {
      queries: (filteredContacts || []).map(
        (contact) => contact.org_contact.companyName || "",
      ),
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setFilteredContacts((prevContacts) =>
        prevContacts.map((contact) => {
          const matchingAccount = data.crmAccountsTextSearch.find(
            (account) => account.query === contact.org_contact.companyName,
          );
          const firstAccountId = matchingAccount?.records[0]?.id;

          return {
            ...contact,
            org_contact: {
              ...contact.org_contact,
              accountId: firstAccountId || "NEW_ACCOUNT",
            },
          };
        }),
      );
    },
    skip: !filteredContacts || filteredContacts.length === 0,
  });

  const handleFinalizePushToCrm = async () => {
    if (!contactList || !filteredContacts) return;

    await beginCreateCrmRecords({
      variables: {
        contactListId: contactList.id,
        externalObjectType: crmObject,
        orgContacts: filteredContacts.map((contact) => ({
          orgContactId: contact.org_contact.id!,
          updatedFields: {
            firstName: contact.org_contact.firstName || "",
            lastName: contact.org_contact.lastName || "",
            linkedinId: contact.org_contact.linkedinId || "",
            companyName: contact.org_contact.companyName,
            companyWebsiteDomain: contact.org_contact.companyWebsiteDomain,
            companyLinkedinId: contact.org_contact.companyLinkedinId,
            title: contact.org_contact.title,
            phoneNumber: contact.org_contact.orgPhoneNumbers[0]?.phone_number,
            email: contact.org_contact.orgEmails[0]?.email,
            accountId: contact.org_contact.accountId,
          },
        })),
      },
      onError: (error) => {
        console.error(error);
        toast.error("Something went wrong, please contact support@ciro.io");
      },
      onCompleted: (data) => {
        const pushToCrmTransaction = data.beginCreateCrmRecords.transaction;
        if (pushToCrmTransaction) {
          toast.success("Beginning push to CRM");
          client.cache.modify({
            id: client.cache.identify({
              __typename: "ContactList",
              id: listId,
            }),
            fields: {
              mostRecentPushToCrmTransaction() {
                return {
                  id: pushToCrmTransaction.id,
                  finished_at: null,
                };
              },
            },
          });
          setViewState(AutomationListStateEnum.Home);
        } else {
          toast.error("Something went wrong, please contact support@ciro.io");
        }
      },
    });
  };

  return (
    <>
      <div
        className={classNames(
          "flex",
          "w-full",
          "justify-between",
          "items-center",
        )}
      >
        <h1 className={classNames("text-2xl", "pb-6")}>
          Finalize {getCrmObjectLabel(crmObject)} to push to{" "}
          {integrationType || "CRM"}
        </h1>
        <div className={classNames("flex", "gap-4")}>
          <CiroButton
            analyticsField="cancel-push-to-crm"
            analyticsProps={{
              listId,
            }}
            style={CiroButtonStyleEnum.INVERTED_LOUD}
            onClick={() => {
              setViewState(AutomationListStateEnum.ReviewDuplicates);
            }}
          >
            Back
          </CiroButton>
          <CiroButton
            disabled={
              beginCreateCrmRecordsLoading ||
              crmAccountsTextSearchLoading ||
              filteredContacts.length === 0
            }
            analyticsField="push-to-crm-continue"
            analyticsProps={{
              listId,
            }}
            style={CiroButtonStyleEnum.LOUD}
            onClick={handleFinalizePushToCrm}
          >
            Finalize & export
          </CiroButton>
        </div>
      </div>
      {beginCreateCrmRecordsError && (
        <div className={classNames("text-red-500")}>
          Something went wrong. Please{" "}
          <a href="mailto:support@ciro.io">contact support</a> if you continue
          to experience this issue
        </div>
      )}
      {crmAccountsTextSearchLoading && (
        <div className={classNames("w-full", "mt-6")}>
          <SkeletonLoading numSkeletons={8} skeletonHeight={"60px"} />
        </div>
      )}
      {filteredContacts && !crmAccountsTextSearchLoading && (
        <div className={classNames("w-full", "mt-6")}>
          <FinalizePushToCrmTable
            crmObject={crmObject}
            crmAccountsTextSearchData={crmAccountsTextSearchData}
            contactList={filteredContacts}
            setFilteredContacts={setFilteredContacts}
          />
        </div>
      )}
    </>
  );
};

export default FinalizePushToCrm;
