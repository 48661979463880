import { useContext, useEffect, useRef, useState } from "react";
import AutopilotCuratedSearchContext, {
  ICuratedSearchPersona,
} from "../../../../contexts/AutopilotCuratedSearchContext";
import CiroTextArea from "../../../shared/CiroTextArea";
import CiroMarkdown from "../../../shared/CiroMarkdown";
import classNames from "classnames";

const CuratedSearchPersonaEditDescription = ({
  onSave = () => {},
  persona,
  setPersona,
}: {
  onSave?: () => void;
  persona: ICuratedSearchPersona;
  setPersona: (persona: ICuratedSearchPersona) => void;
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const showEditingDescription = isEditingDescription || !persona?.description;
  const [initialState, setInitialState] = useState(persona?.description);
  const { setMayNeedToRegenerateFilters } = useContext(
    AutopilotCuratedSearchContext,
  );

  useEffect(() => {
    setError(null);
  }, [persona?.description]);

  if (!showEditingDescription) {
    return (
      <div
        className={classNames(
          "border",
          "hover:border-gray-300",
          "border-transparent",
          "rounded",
          "cursor-pointer",
          "pt-1.5",
          "pb-3",
          "px-2",
        )}
        onClick={() => {
          setIsEditingDescription(true);
          setTimeout(() => {
            textAreaRef.current?.focus();
          }, 10);
        }}
      >
        <CiroMarkdown
          options={{ addPSpacing: true }}
          markdown={persona?.description || ""}
        />
      </div>
    );
  }

  return (
    <CiroTextArea
      error={error}
      forwardRef={textAreaRef}
      placeholder="Enter a description of your ideal customer"
      fitToContentOnMount={true}
      value={persona?.description}
      onFocus={() => {
        setIsEditingDescription(true);
      }}
      onChange={(e) => {
        setError(null);
        setPersona({
          ...persona,
          description: e.target.value,
        });
        setMayNeedToRegenerateFilters(true);
      }}
      onBlur={() => {
        if (!persona?.description) {
          setError("Description is required");
          return;
        }
        if (initialState !== persona?.description) {
          onSave();
        }
        setInitialState(persona?.description);
        setIsEditingDescription(false);
      }}
    />
  );
};

export default CuratedSearchPersonaEditDescription;
