import { gql, useMutation } from "@apollo/client";
import CiroTableCell from "../../../shared/CiroTable/CiroTableCell";
import CiroTableRow from "../../../shared/CiroTable/CiroTableRow";
import classNames from "classnames";
import { ContactListContactRow_ContactFragment } from "../../../../__generated__/graphql";
import ContactListConfidenceLevelDropdown from "../ContactListConfidenceLevelDropdown";
import ContactListPersonaDropdown from "../ContactListPersonaDropdown";
import { addLinkedinIdToUrl } from "../../../../utils/linkedinUtils";
import { NavLink } from "react-router-dom";
import ContactListRationaleTextBox from "../ContactListRationaleTextBox";
import LinkedInSquareIcon from "../../../../assets/img/icons/LinkedinSquareIcon";
import CiroCheckbox from "../../../shared/CiroCheckbox";
import AutomationListFindEmailsInlineButton, {
  AutomationListFindEmailsInlineButton_OrgContact,
} from "./AutomationListFindEmailsInlineButton";
import AutomationListFindNumbersInlineButton, {
  AutomationListFindNumbersInlineButton_OrgContact,
} from "./AutomationListFindNumbersInlineButton";
import CopyButton from "./CopyButton";

export const ContactListContactRow_Contact = gql`
  fragment ContactListContactRow_Contact on ContactListSavedContact {
    id
    org_contact {
      id
      first_name
      last_name
      title
      linkedin_id
      company_name
      orgPhoneNumbers {
        phone_number
      }
      orgEmails {
        email
      }
      mostRecentPhoneNumberRequestTransaction {
        id
        finished_at
      }
      mostRecentEmailRequestTransaction {
        id
        finished_at
      }
      ...AutomationListFindEmailsInlineButton_OrgContact
      ...AutomationListFindNumbersInlineButton_OrgContact
    }
    assignedPersona {
      id
      name
    }
    persona_fit
    rationale
  }
  ${AutomationListFindEmailsInlineButton_OrgContact}
  ${AutomationListFindNumbersInlineButton_OrgContact}
`;

const ContactListContactRow_UpdateContactListSavedContact = gql`
  mutation ContactListContactRow_UpdateContactListSavedContact(
    $input: UpdateContactListSavedContactInput!
  ) {
    updateContactListSavedContact(input: $input) {
      success
      message
      contactListSavedContact {
        id
      }
    }
  }
`;

interface IContactListContactRowProps {
  contactListId: number | null;
  contact: ContactListContactRow_ContactFragment;
  isSelected: boolean;
  onSelect: () => void;
}

const ContactListContactRow = ({
  contactListId,
  contact,
  isSelected,
  onSelect,
}: IContactListContactRowProps) => {
  const [updateContactListSavedContact] = useMutation(
    ContactListContactRow_UpdateContactListSavedContact,
  );

  const phoneNumber = contact.org_contact?.orgPhoneNumbers[0]?.phone_number;
  const showPhoneNotFound =
    !phoneNumber &&
    contact.org_contact?.mostRecentPhoneNumberRequestTransaction?.finished_at;

  const email = contact.org_contact?.orgEmails[0]?.email;
  const showEmailNotFound =
    !email &&
    contact.org_contact?.mostRecentEmailRequestTransaction?.finished_at;

  const hasPersona = Boolean(contact.assignedPersona?.id);

  return (
    <CiroTableRow clickable={false}>
      <CiroTableCell
        padding={2}
        minWidth="50px"
        className={classNames({ "bg-neutral-50": !hasPersona })}
      >
        <div className={classNames("pl-2")}>
          <CiroCheckbox checked={isSelected} onClick={onSelect} />
        </div>
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top", "whitespace-normal", {
          "bg-neutral-50": !hasPersona,
        })}
        minWidth="200px"
      >
        <div>
          <div className={classNames("flex", "items-center")}>
            <div
              className={classNames(
                "font-semibold",
                "text-gray-900",
                "break-words",
              )}
            >
              {contact.org_contact?.first_name} {contact.org_contact?.last_name}
            </div>
            {contact.org_contact?.linkedin_id && (
              <NavLink
                to={addLinkedinIdToUrl(contact.org_contact.linkedin_id)}
                target="_blank"
              >
                <LinkedInSquareIcon customScale={0.5} />
              </NavLink>
            )}
          </div>
          <div className={classNames("break-words")}>
            {contact.org_contact?.title}
          </div>
        </div>
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top", { "bg-neutral-50": !hasPersona })}
        minWidth="150px"
      >
        <div>{contact.org_contact?.company_name}</div>
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top", { "bg-neutral-50": !hasPersona })}
        minWidth="200px"
      >
        <ContactListPersonaDropdown
          borderless={true}
          personaId={contact.assignedPersona?.id ?? null}
          onChange={(personaId) => {
            updateContactListSavedContact({
              variables: {
                input: { id: contact.id, assigned_persona_id: personaId },
              },
            });
          }}
        />
        {contact.assignedPersona?.id && (
          <ContactListConfidenceLevelDropdown
            borderless={true}
            personaFit={contact.persona_fit ?? null}
            onChange={(personaFit) => {
              updateContactListSavedContact({
                variables: {
                  input: { id: contact.id, persona_fit: personaFit },
                },
              });
            }}
          />
        )}
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top", { "bg-neutral-50": !hasPersona })}
        colSpan={2}
        minWidth="200px"
      >
        <ContactListRationaleTextBox
          rationale={contact.rationale ?? ""}
          contactId={contact.id}
          onSave={(rationale) => {
            updateContactListSavedContact({
              variables: { input: { id: contact.id, rationale } },
            });
          }}
        />
      </CiroTableCell>
      <CiroTableCell
        minWidth="200px"
        className={classNames("align-top", { "bg-neutral-50": !hasPersona })}
      >
        <div className={classNames("flex", "flex-col", "gap-1")}>
          {phoneNumber ? (
            <div className={classNames("flex", "items-center", "gap-2")}>
              <span title={phoneNumber} className={classNames("min-w-32")}>
                {phoneNumber}
              </span>
              <CopyButton
                analyticsField="Copy phone number from contact list"
                textToCopy={phoneNumber}
              />
            </div>
          ) : showPhoneNotFound ? (
            <span className={classNames("text-neutral-400")}>
              Phone not found
            </span>
          ) : (
            <AutomationListFindNumbersInlineButton
              contactListId={contactListId}
              orgContact={contact.org_contact}
            />
          )}
          {email ? (
            <div className={classNames("flex", "items-center", "gap-2")}>
              <span title={email}>
                {email.length > 30 ? `${email.slice(0, 30)}...` : email}
              </span>
              <CopyButton
                analyticsField="Copy email from contact list"
                textToCopy={email}
              />
            </div>
          ) : showEmailNotFound ? (
            <span className={classNames("text-neutral-400")}>
              Email not found
            </span>
          ) : (
            <AutomationListFindEmailsInlineButton
              contactListId={contactListId}
              orgContact={contact.org_contact}
            />
          )}
        </div>
      </CiroTableCell>
    </CiroTableRow>
  );
};

export default ContactListContactRow;
