import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../CiroButton";

const CiroGetStartedButton = ({
  text,
  href,
  analyticsField,
  icon,
}: {
  text: string;
  href: string;
  analyticsField: string;
  icon: React.ReactNode;
}) => {
  return (
    <CiroButton
      analyticsField={analyticsField}
      href={href}
      style={CiroButtonStyleEnum.LOUD}
      customClassName={classNames("h-[36px]", "w-[220px]")}
    >
      <div className={classNames("flex", "items-center", "gap-2")}>
        {icon}
        <span>{text}</span>
      </div>
    </CiroButton>
  );
};

export default CiroGetStartedButton;
