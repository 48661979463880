import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

interface IHasEmailFilterProps {
  hasEmailFilter: boolean | null;
  setHasEmailFilter: (v: boolean | null) => void;
}

const HasEmailFilter = ({
  hasEmailFilter,
  setHasEmailFilter,
}: IHasEmailFilterProps) => {
  const filterOptions = [
    {
      value: true,
      label: "Has email",
    },
    {
      value: false,
      label: "No email",
    },
  ];

  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={hasEmailFilter == null ? 0 : 1}
      sectionTitle="Email"
      filterOptions={[
        {
          filter: (
            <CiroDropDown
              options={filterOptions}
              value={hasEmailFilter}
              onChange={(newValue) => {
                setHasEmailFilter(newValue ?? null);
              }}
            />
          ),
        },
      ]}
    />
  );
};

export default HasEmailFilter;
