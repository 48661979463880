import classNames from "classnames";
import { PersonaFitLevel } from "../../../__generated__/graphql";
import CiroDropDown from "../../shared/CiroDropdown";
import DotIcon from "../../../assets/img/icons/DotIcon";
import { useState } from "react";

const PersonaFitLevelDisplay = {
  [PersonaFitLevel.High]: {
    text: "High confidence",
    color: "text-blue-500",
  },
  [PersonaFitLevel.Medium]: {
    text: "Medium confidence",
    color: "text-slate-400",
  },
  [PersonaFitLevel.Low]: {
    text: "Low confidence",
    color: "text-zinc-300",
  },
  "": {
    text: "-",
    color: "text-transparent",
  },
};

const ContactListConfidenceLevelDropdown = ({
  personaFit,
  onChange,
  borderless = false,
}: {
  personaFit: PersonaFitLevel | null;
  onChange: (personaFit: PersonaFitLevel) => void;
  borderless?: boolean;
}) => {
  const [currentPersonaFit, setCurrentPersonaFit] = useState(personaFit);
  return (
    <div
      className={classNames({
        "-ml-2": borderless,
      })}
    >
      <CiroDropDown
        borderless={borderless}
        isClearable={false}
        isMulti={false}
        value={currentPersonaFit}
        getOptionLabel={(option: { text: string; color: string }) => {
          return (
            <div className={classNames("flex", "items-center")}>
              <span className={classNames(option.color, "pr-1")}>
                <DotIcon />
              </span>
              <span>{option.text}</span>
            </div>
          );
        }}
        onChange={(value) => {
          setCurrentPersonaFit(value);
          onChange(value);
        }}
        options={[
          PersonaFitLevel.High,
          PersonaFitLevel.Medium,
          PersonaFitLevel.Low,
        ].map((fit) => {
          return {
            text: PersonaFitLevelDisplay[fit].text,
            color: PersonaFitLevelDisplay[fit].color,
            value: fit,
          } as any;
        })}
        menuPortalTarget={document.body}
      />
    </div>
  );
};

export default ContactListConfidenceLevelDropdown;
