import { useContext } from "react";
import AutopilotCuratedSearchContext from "../../../contexts/AutopilotCuratedSearchContext";
import CiroDropDown from "../../shared/CiroDropdown";

const CuratedSearchPersonaDropdown = () => {
  const { allPersonas, setSelectedPersona, setMayNeedToRegenerateFilters } =
    useContext(AutopilotCuratedSearchContext);
  if (!allPersonas) {
    return null;
  }

  return (
    <CiroDropDown
      isClearable={true}
      isMulti={false}
      placeholder="Choose a different persona"
      onChange={(value) => {
        setMayNeedToRegenerateFilters(true);
        if (!value) {
          setSelectedPersona(null);
          return;
        }

        const selectedPersona = allPersonas.find((p) => p.id === Number(value));
        setSelectedPersona(
          selectedPersona
            ? {
                id: selectedPersona.id,
                name: selectedPersona.name,
                description: selectedPersona.description,
              }
            : null,
        );
      }}
      options={[
        {
          label: "+ Create new persona",
          value: null,
        },
        ...(allPersonas || []).map((persona) => ({
          label: persona.name,
          value: persona.id,
        })),
      ]}
      menuPortalTarget={document.body}
    />
  );
};

export default CuratedSearchPersonaDropdown;
