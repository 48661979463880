import { gql } from "@apollo/client";
import {
  FilterContactListContacts_ContactListFragment,
  PersonaFitLevel,
} from "../../../__generated__/graphql";
import { IContactListFilters } from "./useContactListFiltersAndSort";
import { useEffect, useMemo, useState } from "react";

export const FilterContactListContacts_ContactList = gql`
  fragment FilterContactListContacts_ContactList on ContactList {
    contacts {
      id
      created_at
      org_contact {
        id
        first_name
        last_name
        company_name
        orgEmails {
          email
        }
        orgPhoneNumbers {
          phone_number
        }
      }
      assignedPersona {
        id
        name
      }
      persona_fit
    }
  }
`;

const useFilterAndSortContactListContacts = ({
  contactList,
  filters,
}: {
  contactList: FilterContactListContacts_ContactListFragment | null | undefined;
  filters: IContactListFilters;
}) => {
  const [
    filteredContactIdsToOrgContactIds,
    setFilteredContactIdsToOrgContactIds,
  ] = useState<Map<number, number>>(new Map());

  const contactIdToOrgContactId = useMemo(() => {
    return new Map(
      contactList?.contacts.map((contact) => [
        contact.id,
        contact.org_contact.id!,
      ]) ?? [],
    );
  }, [contactList]);

  const [missingPersonaCount, setMissingPersonaCount] = useState(0);

  useEffect(() => {
    if (!contactList) return;

    let newMissingPersonaCount = 0;

    const newFilteredContactIds = contactList.contacts
      .filter((contact) => {
        if (filters.companyNameFilter.length > 0) {
          if (
            !filters.companyNameFilter.some((filterName) =>
              contact.org_contact.company_name
                ?.toLowerCase()
                .startsWith(filterName.toLowerCase()),
            )
          ) {
            return false;
          }
        }
        if (filters.firstNameFilter.length > 0) {
          const firstName = contact.org_contact.first_name || "";
          if (
            !filters.firstNameFilter.some((filterName) =>
              firstName.toLowerCase().startsWith(filterName.toLowerCase()),
            )
          ) {
            return false;
          }
        }
        if (filters.lastNameFilter.length > 0) {
          const lastName = contact.org_contact.last_name || "";
          if (
            !filters.lastNameFilter.some((filterName) =>
              lastName.toLowerCase().startsWith(filterName.toLowerCase()),
            )
          ) {
            return false;
          }
        }
        if (filters.personaFilter.length > 0) {
          if (
            contact.assignedPersona?.id != null &&
            !filters.personaFilter.includes(contact.assignedPersona?.id ?? -1)
          ) {
            return false;
          }
        }
        if (filters.confidenceLevelFilter.length > 0) {
          if (
            contact.assignedPersona?.id == null ||
            !filters.confidenceLevelFilter.includes(
              contact.persona_fit ?? PersonaFitLevel.Low,
            )
          ) {
            return false;
          }
        }
        if (filters.hideDisqualifiedPersonas) {
          if (contact.assignedPersona?.id == null) {
            newMissingPersonaCount++;
            return false;
          }
        }
        if (filters.hasEmailFilter !== null) {
          const hasEmail = contact.org_contact.orgEmails?.length > 0;
          if (hasEmail !== filters.hasEmailFilter) {
            return false;
          }
        }
        if (filters.hasPhoneNumberFilter !== null) {
          const hasPhoneNumber =
            contact.org_contact.orgPhoneNumbers?.length > 0;
          if (hasPhoneNumber !== filters.hasPhoneNumberFilter) {
            return false;
          }
        }
        return true;
      })
      .sort((a, b) => {
        const directionalMultiplier = filters.sortDirection === "desc" ? -1 : 1;
        if (filters.sortBy === "companyName") {
          if (!a.org_contact.company_name) return 1 * directionalMultiplier;
          if (!b.org_contact.company_name) return -1 * directionalMultiplier;
          return (
            a.org_contact.company_name.localeCompare(
              b.org_contact.company_name,
            ) * directionalMultiplier
          );
        } else if (filters.sortBy === "name") {
          if (!a.org_contact.first_name) return 1 * directionalMultiplier;
          if (!b.org_contact.first_name) return -1 * directionalMultiplier;
          const firstNameCompare = a.org_contact.first_name.localeCompare(
            b.org_contact.first_name,
          );
          if (firstNameCompare !== 0)
            return firstNameCompare * directionalMultiplier;

          // Compare last names if first names are equal
          if (!a.org_contact.last_name) return 1 * directionalMultiplier;
          if (!b.org_contact.last_name) return -1 * directionalMultiplier;
          return (
            a.org_contact.last_name.localeCompare(b.org_contact.last_name) *
            directionalMultiplier
          );
        } else if (filters.sortBy === "persona") {
          // Sort by persona first
          if (!a.assignedPersona?.name) return 1 * directionalMultiplier;
          if (!b.assignedPersona?.name) return -1 * directionalMultiplier;
          const personaCompare = a.assignedPersona.name.localeCompare(
            b.assignedPersona.name,
          );
          if (personaCompare !== 0) {
            return personaCompare * directionalMultiplier;
          }

          // If personas are equal, sort by confidence level
          const fitOrder = {
            [PersonaFitLevel.High]: 0,
            [PersonaFitLevel.Medium]: 1,
            [PersonaFitLevel.Low]: 2,
          };
          const aFit = fitOrder[a.persona_fit ?? PersonaFitLevel.Low] ?? 3;
          const bFit = fitOrder[b.persona_fit ?? PersonaFitLevel.Low] ?? 3;
          return (aFit - bFit) * directionalMultiplier;
        } else {
          // DEFAULT SORT

          // Persona type
          if (a.assignedPersona?.name !== b.assignedPersona?.name) {
            if (!a.assignedPersona?.name) return 1 * directionalMultiplier;
            if (!b.assignedPersona?.name) return -1 * directionalMultiplier;
            return (
              a.assignedPersona.name.localeCompare(b.assignedPersona.name) *
              directionalMultiplier
            );
          }

          // Confidence (high > medium > low)
          const fitOrder = {
            [PersonaFitLevel.High]: 0,
            [PersonaFitLevel.Medium]: 1,
            [PersonaFitLevel.Low]: 2,
          };
          const aFit = fitOrder[a.persona_fit ?? PersonaFitLevel.Low] ?? 3;
          const bFit = fitOrder[b.persona_fit ?? PersonaFitLevel.Low] ?? 3;
          if (aFit !== bFit) return (aFit - bFit) * directionalMultiplier;

          // Company name
          if (!a.org_contact.company_name) return 1 * directionalMultiplier;
          if (!b.org_contact.company_name) return -1 * directionalMultiplier;
          const companyNameCompare = a.org_contact.company_name.localeCompare(
            b.org_contact.company_name,
          );
          if (companyNameCompare !== 0)
            return companyNameCompare * directionalMultiplier;

          // Finally by createdAt descending
          return (
            (new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()) *
            directionalMultiplier
          );
        }
      })
      .map((contact) => contact.id!);
    setFilteredContactIdsToOrgContactIds(
      new Map(
        newFilteredContactIds.map((contactId) => [
          contactId,
          contactIdToOrgContactId.get(contactId)!,
        ]),
      ),
    );
    setMissingPersonaCount(newMissingPersonaCount);
  }, [contactList, filters, contactIdToOrgContactId]);

  return {
    filteredContactIdsToOrgContactIds,
    missingPersonaCount,
  };
};

export default useFilterAndSortContactListContacts;
