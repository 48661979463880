const LinkedInGeographies = [
  { id: 104514572, label: "South America" },
  { id: 102393603, label: "Asia" },
  { id: 91000009, label: "Nordics" },
  { id: 91000004, label: "APJ" },
  { id: 91000005, label: "Benelux" },
  { id: 102221843, label: "North America" },
  { id: 91000006, label: "DACH" },
  { id: 91000007, label: "EMEA" },
  { id: 91000010, label: "Oceania" },
  { id: 91000003, label: "APAC" },
  { id: 91000008, label: "MENA" },
  { id: 100506914, label: "Europe" },
  { id: 103537801, label: "Africa" },
  { id: 103644278, label: "United States" },
];

export default LinkedInGeographies;
