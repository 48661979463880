import React from "react";
import classNames from "classnames";

interface ICiroModalProps {
  isOpen: boolean;
  children: any;
  onClose: () => void;
  size?: "SMALL" | "LARGE" | "XLARGE" | "FIXED_RIGHT" | "FITTED";
  hideScrollbar?: boolean;
}

const CiroModal = ({
  isOpen,
  children,
  onClose,
  size = "SMALL",
  hideScrollbar = false,
}: ICiroModalProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={classNames("fixed", "inset-0", "z-20")}>
      <div
        className={classNames(
          "flex",
          "h-screen",
          "items-center",
          "justify-center",
        )}
      >
        <div className={classNames("fixed", "inset-0", "transition-opacity")}>
          <div
            className={classNames(
              "absolute",
              "inset-0",
              "bg-gray-500",
              "opacity-75",
            )}
            onClick={onClose}
          />
        </div>
        <div
          className={classNames(
            "bg-white",
            "shadow-xl",
            "overflow-auto",
            {
              "max-h-screen-minus-4": ["SMALL", "LARGE", "XLARGE"].includes(
                size,
              ),
            },
            {
              fixed: size === "FIXED_RIGHT",
              "h-screen": size === "FIXED_RIGHT",
              "m-5": size !== "FIXED_RIGHT",
              "max-w-3xl": size === "XLARGE",
              "max-w-md": size === "SMALL",
              "max-w-xl": size === "LARGE",
              "max-w-[90%]": size === "FITTED",
              "max-h-[90%]": size === "FITTED",
              "p-5": size !== "FIXED_RIGHT",
              "right-0": size === "FIXED_RIGHT",
              "rounded-lg": size !== "FIXED_RIGHT",
              "top-0": size === "FIXED_RIGHT",
              transform: size !== "FIXED_RIGHT",
              "w-3/4": size === "FIXED_RIGHT",
              "w-full": size !== "FIXED_RIGHT",
              "z-20": size === "FIXED_RIGHT",
              "lg:w-96": size === "FIXED_RIGHT",
              "overflow-hidden": hideScrollbar,
            },
          )}
        >
          <div>
            <div className={classNames("text-left")}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CiroModal;
