// Pulled from https://www.lobstr.io/blog/linkedin-industry-list,
// https://docs.google.com/spreadsheets/d/1YAudepqU31Bmen7Z72yasCZ89zA_vGgqE0H9wkVBn0c/edit?gid=0#gid=0

const LinkedInIndustries = [
  { id: 2190, label: "Accommodation Services" },
  { id: 34, label: "Food and Beverage Services" },
  { id: 2217, label: "Bars, Taverns, and Nightclubs" },
  { id: 2212, label: "Caterers" },
  { id: 2214, label: "Mobile Food Services" },
  { id: 32, label: "Restaurants" },
  { id: 31, label: "Hospitality" },
  { id: 2197, label: "Bed-and-Breakfasts, Hostels, Homestays" },
  { id: 2194, label: "Hotels and Motels" },
  { id: 1912, label: "Administrative and Support Services" },
  { id: 1938, label: "Collection Agencies" },
  { id: 110, label: "Events Services" },
  { id: 122, label: "Facilities Services" },
  { id: 1965, label: "Janitorial Services" },
  { id: 2934, label: "Landscaping Services" },
  { id: 101, label: "Fundraising" },
  { id: 1916, label: "Office Administration" },
  { id: 121, label: "Security and Investigations" },
  { id: 1956, label: "Security Guards and Patrol Services" },
  { id: 1958, label: "Security Systems Services" },
  { id: 104, label: "Staffing and Recruiting" },
  { id: 1923, label: "Executive Search Services" },
  { id: 1925, label: "Temporary Help Services" },
  { id: 1931, label: "Telephone Call Centers" },
  { id: 108, label: "Translation and Localization" },
  { id: 30, label: "Travel Arrangements" },
  { id: 103, label: "Writing and Editing" },
  { id: 48, label: "Construction" },
  { id: 406, label: "Building Construction" },
  { id: 413, label: "Nonresidential Building Construction" },
  { id: 408, label: "Residential Building Construction" },
  { id: 51, label: "Civil Engineering" },
  { id: 431, label: "Highway, Street, and Bridge Construction" },
  { id: 428, label: "Subdivision of Land" },
  { id: 419, label: "Utility System Construction" },
  { id: 435, label: "Specialty Trade Contractors" },
  { id: 453, label: "Building Equipment Contractors" },
  { id: 460, label: "Building Finishing Contractors" },
  { id: 436, label: "Building Structure and Exterior Contractors" },
  { id: 91, label: "Consumer Services" },
  { id: 90, label: "Civic and Social Organizations" },
  { id: 1909, label: "Industry Associations" },
  { id: 107, label: "Political Organizations" },
  { id: 1911, label: "Professional Organizations" },
  { id: 2318, label: "Household Services" },
  { id: 100, label: "Non-profit Organizations" },
  { id: 2258, label: "Personal and Laundry Services" },
  { id: 2272, label: "Laundry and Drycleaning Services" },
  { id: 2259, label: "Personal Care Services" },
  { id: 2282, label: "Pet Services" },
  { id: 131, label: "Philanthropic Fundraising Services" },
  { id: 89, label: "Religious Institutions" },
  { id: 2225, label: "Repair and Maintenance" },
  { id: 2247, label: "Commercial and Industrial Machinery Maintenance" },
  { id: 2240, label: "Electronic and Precision Equipment Maintenance" },
  { id: 2255, label: "Footwear and Leather Goods Repair" },
  { id: 2253, label: "Reupholstery and Furniture Repair" },
  { id: 2226, label: "Vehicle Repair and Maintenance" },
  { id: 1999, label: "Education" },
  { id: 132, label: "E-Learning Providers" },
  { id: 68, label: "Higher Education" },
  { id: 67, label: "Primary and Secondary Education" },
  { id: 105, label: "Professional Training and Coaching" },
  { id: 2018, label: "Technical and Vocational Training" },
  { id: 2019, label: "Cosmetology and Barber Schools" },
  { id: 2025, label: "Fine Arts Schools" },
  { id: 2020, label: "Flight Training" },
  { id: 2029, label: "Language Schools" },
  { id: 2012, label: "Secretarial Schools" },
  { id: 2027, label: "Sports and Recreation Instruction" },
  { id: 28, label: "Entertainment Providers" },
  { id: 38, label: "Artists and Writers" },
  { id: 37, label: "Museums, Historical Sites, and Zoos" },
  { id: 2161, label: "Historical Sites" },
  { id: 2159, label: "Museums" },
  { id: 2163, label: "Zoos and Botanical Gardens" },
  { id: 115, label: "Musicians" },
  { id: 2130, label: "Performing Arts and Spectator Sports" },
  { id: 2139, label: "Circuses and Magic Shows" },
  { id: 2135, label: "Dance Companies" },
  { id: 39, label: "Performing Arts" },
  { id: 33, label: "Spectator Sports" },
  { id: 2143, label: "Racetracks" },
  { id: 2142, label: "Sports Teams and Clubs" },
  { id: 2133, label: "Theater Companies" },
  { id: 40, label: "Recreational Facilities" },
  { id: 2167, label: "Amusement Parks and Arcades" },
  { id: 29, label: "Gambling Facilities and Casinos" },
  { id: 2179, label: "Golf Courses and Country Clubs" },
  { id: 2181, label: "Skiing Facilities" },
  { id: 124, label: "Wellness and Fitness Services" },
  { id: 201, label: "Farming, Ranching, Forestry" },
  { id: 63, label: "Farming" },
  { id: 150, label: "Horticulture" },
  { id: 298, label: "Forestry and Logging" },
  { id: 256, label: "Ranching and Fisheries" },
  { id: 66, label: "Fisheries" },
  { id: 64, label: "Ranching" },
  { id: 43, label: "Financial Services" },
  { id: 129, label: "Capital Markets" },
  { id: 1720, label: "Investment Advice" },
  { id: 45, label: "Investment Banking" },
  { id: 46, label: "Investment Management" },
  { id: 1713, label: "Securities and Commodity Exchanges" },
  { id: 106, label: "Venture Capital and Private Equity Principals" },
  { id: 1673, label: "Credit Intermediation" },
  { id: 41, label: "Banking" },
  { id: 141, label: "International Trade and Development" },
  { id: 1696, label: "Loan Brokers" },
  { id: 1678, label: "Savings Institutions" },
  { id: 1742, label: "Funds and Trusts" },
  { id: 1743, label: "Insurance and Employee Benefit Funds" },
  { id: 1745, label: "Pension Funds" },
  { id: 1750, label: "Trusts and Estates" },
  { id: 42, label: "Insurance" },
  { id: 1738, label: "Claims Adjusting, Actuarial Services" },
  { id: 1737, label: "Insurance Agencies and Brokerages" },
  { id: 1725, label: "Insurance Carriers" },
  { id: 75, label: "Government Administration" },
  { id: 73, label: "Administration of Justice" },
  { id: 3068, label: "Correctional Institutions" },
  { id: 3065, label: "Courts of Law" },
  { id: 3070, label: "Fire Protection" },
  { id: 77, label: "Law Enforcement" },
  { id: 78, label: "Public Safety" },
  { id: 2375, label: "Economic Programs" },
  { id: 3085, label: "Transportation Programs" },
  { id: 3086, label: "Utilities Administration" },
  { id: 388, label: "Environmental Quality Programs" },
  { id: 2366, label: "Air, Water, and Waste Program Management" },
  { id: 2368, label: "Conservation Programs" },
  { id: 2353, label: "Health and Human Services" },
  { id: 69, label: "Education Administration Programs" },
  { id: 2360, label: "Public Assistance Programs" },
  { id: 2358, label: "Public Health" },
  { id: 2369, label: "Housing and Community Development" },
  { id: 2374, label: "Community Development and Urban Planning" },
  { id: 3081, label: "Housing Programs" },
  { id: 2391, label: "Military and International Affairs" },
  { id: 71, label: "Armed Forces" },
  { id: 74, label: "International Affairs" },
  { id: 79, label: "Public Policy Offices" },
  { id: 76, label: "Executive Offices" },
  { id: 72, label: "Legislative Offices" },
  { id: 3089, label: "Space Research and Technology" },
  { id: 1905, label: "Holding Companies" },
  { id: 14, label: "Hospitals and Health Care" },
  { id: 2115, label: "Community Services" },
  { id: 2112, label: "Services for the Elderly and Disabled" },
  { id: 2081, label: "Hospitals" },
  { id: 88, label: "Individual and Family Services" },
  { id: 2128, label: "Child Day Care Services" },
  { id: 2122, label: "Emergency and Relief Services" },
  { id: 2125, label: "Vocational Rehabilitation Services" },
  { id: 13, label: "Medical Practices" },
  { id: 125, label: "Alternative Medicine" },
  { id: 2077, label: "Ambulance Services" },
  { id: 2048, label: "Chiropractors" },
  { id: 2045, label: "Dentists" },
  { id: 2060, label: "Family Planning Centers" },
  { id: 2074, label: "Home Health Care Services" },
  { id: 2069, label: "Medical and Diagnostic Laboratories" },
  { id: 139, label: "Mental Health Care" },
  { id: 2050, label: "Optometrists" },
  { id: 2063, label: "Outpatient Care Centers" },
  { id: 2054, label: "Physical, Occupational and Speech Therapists" },
  { id: 2040, label: "Physicians" },
  { id: 2091, label: "Nursing Homes and Residential Care Facilities" },
  { id: 25, label: "Manufacturing" },
  { id: 598, label: "Apparel Manufacturing" },
  { id: 615, label: "Fashion Accessories Manufacturing" },
  { id: 112, label: "Appliances, Electrical, and Electronics Manufacturing" },
  { id: 998, label: "Electric Lighting Equipment Manufacturing" },
  { id: 2468, label: "Electrical Equipment Manufacturing" },
  { id: 3255, label: "Fuel Cell Manufacturing" },
  { id: 1005, label: "Household Appliance Manufacturing" },
  { id: 54, label: "Chemical Manufacturing" },
  { id: 709, label: "Agricultural Chemical Manufacturing" },
  { id: 703, label: "Artificial Rubber and Synthetic Fiber Manufacturing" },
  { id: 690, label: "Chemical Raw Materials Manufacturing" },
  { id: 722, label: "Paint, Coating, and Adhesive Manufacturing" },
  { id: 18, label: "Personal Care Product Manufacturing" },
  { id: 15, label: "Pharmaceutical Manufacturing" },
  { id: 727, label: "Soap and Cleaning Product Manufacturing" },
  { id: 3251, label: "Climate Technology Product Manufacturing" },
  { id: 24, label: "Computers and Electronics Manufacturing" },
  { id: 973, label: "Audio and Video Equipment Manufacturing" },
  { id: 964, label: "Communications Equipment Manufacturing" },
  { id: 3, label: "Computer Hardware Manufacturing" },
  { id: 3245, label: "Accessible Hardware Manufacturing" },
  { id: 994, label: "Magnetic and Optical Media Manufacturing" },
  { id: 983, label: "Measuring and Control Instrument Manufacturing" },
  { id: 3254, label: "Smart Meter Manufacturing" },
  { id: 7, label: "Semiconductor Manufacturing" },
  { id: 144, label: "Renewable Energy Semiconductor Manufacturing" },
  { id: 840, label: "Fabricated Metal Products" },
  { id: 852, label: "Architectural and Structural Metal Manufacturing" },
  { id: 861, label: "Boilers, Tanks, and Shipping Container Manufacturing" },
  { id: 871, label: "Construction Hardware Manufacturing" },
  { id: 849, label: "Cutlery and Handtool Manufacturing" },
  { id: 883, label: "Metal Treatments" },
  { id: 887, label: "Metal Valve, Ball, and Roller Manufacturing" },
  { id: 873, label: "Spring and Wire Product Manufacturing" },
  { id: 876, label: "Turned Products and Fastener Manufacturing" },
  { id: 23, label: "Food and Beverage Manufacturing" },
  { id: 562, label: "Breweries" },
  { id: 564, label: "Distilleries" },
  { id: 2500, label: "Wineries" },
  { id: 481, label: "Animal Feed Manufacturing" },
  { id: 529, label: "Baked Goods Manufacturing" },
  { id: 142, label: "Beverage Manufacturing" },
  { id: 65, label: "Dairy Product Manufacturing" },
  { id: 504, label: "Fruit and Vegetable Preserves Manufacturing" },
  { id: 521, label: "Meat Products Manufacturing" },
  { id: 528, label: "Seafood Product Manufacturing" },
  { id: 495, label: "Sugar and Confectionery Product Manufacturing" },
  { id: 26, label: "Furniture and Home Furnishings Manufacturing" },
  { id: 1080, label: "Household and Institutional Furniture Manufacturing" },
  { id: 1095, label: "Mattress and Blinds Manufacturing" },
  { id: 1090, label: "Office Furniture and Fixtures Manufacturing" },
  { id: 145, label: "Glass, Ceramics and Concrete Manufacturing" },
  { id: 799, label: "Abrasives and Nonmetallic Minerals Manufacturing" },
  { id: 773, label: "Clay and Refractory Products Manufacturing" },
  { id: 779, label: "Glass Product Manufacturing" },
  { id: 794, label: "Lime and Gypsum Products Manufacturing" },
  { id: 616, label: "Leather Product Manufacturing" },
  { id: 622, label: "Footwear Manufacturing" },
  { id: 625, label: "Women's Handbag Manufacturing" },
  { id: 55, label: "Machinery Manufacturing" },
  {
    id: 901,
    label: "Agriculture, Construction, Mining Machinery Manufacturing",
  },
  { id: 147, label: "Automation Machinery Manufacturing" },
  { id: 3247, label: "Robot Manufacturing" },
  { id: 918, label: "Commercial and Service Industry Machinery Manufacturing" },
  { id: 935, label: "Engines and Power Transmission Equipment Manufacturing" },
  { id: 3241, label: "Renewable Energy Equipment Manufacturing" },
  { id: 923, label: "HVAC and Refrigeration Equipment Manufacturing" },
  { id: 135, label: "Industrial Machinery Manufacturing" },
  { id: 928, label: "Metalworking Machinery Manufacturing" },
  { id: 17, label: "Medical Equipment Manufacturing" },
  { id: 679, label: "Oil and Coal Product Manufacturing" },
  { id: 61, label: "Paper and Forest Product Manufacturing" },
  { id: 743, label: "Plastics and Rubber Product Manufacturing" },
  { id: 146, label: "Packaging and Containers Manufacturing" },
  { id: 117, label: "Plastics Manufacturing" },
  { id: 763, label: "Rubber Products Manufacturing" },
  { id: 807, label: "Primary Metal Manufacturing" },
  { id: 83, label: "Printing Services" },
  { id: 20, label: "Sporting Goods Manufacturing" },
  { id: 60, label: "Textile Manufacturing" },
  { id: 21, label: "Tobacco Manufacturing" },
  { id: 1029, label: "Transportation Equipment Manufacturing" },
  { id: 52, label: "Aviation and Aerospace Component Manufacturing" },
  { id: 1, label: "Defense and Space Manufacturing" },
  { id: 53, label: "Motor Vehicle Manufacturing" },
  { id: 3253, label: "Alternative Fuel Vehicle Manufacturing" },
  { id: 1042, label: "Motor Vehicle Parts Manufacturing" },
  { id: 62, label: "Railroad Equipment Manufacturing" },
  { id: 58, label: "Shipbuilding" },
  { id: 784, label: "Wood Product Manufacturing" },
  { id: 332, label: "Oil, Gas, and Mining" },
  { id: 56, label: "Mining" },
  { id: 341, label: "Coal Mining" },
  { id: 345, label: "Metal Ore Mining" },
  { id: 356, label: "Nonmetallic Mineral Mining" },
  { id: 57, label: "Oil and Gas" },
  { id: 3096, label: "Natural Gas Extraction" },
  { id: 3095, label: "Oil Extraction" },
  { id: 1810, label: "Professional Services" },
  { id: 47, label: "Accounting" },
  { id: 80, label: "Advertising Services" },
  { id: 148, label: "Government Relations Services" },
  { id: 98, label: "Public Relations and Communications Services" },
  { id: 97, label: "Market Research" },
  { id: 50, label: "Architecture and Planning" },
  { id: 3246, label: "Accessible Architecture and Design" },
  { id: 11, label: "Business Consulting and Services" },
  { id: 86, label: "Environmental Services" },
  { id: 137, label: "Human Resources Services" },
  { id: 1862, label: "Marketing Services" },
  { id: 2401, label: "Operations Consulting" },
  { id: 123, label: "Outsourcing and Offshoring Consulting" },
  { id: 102, label: "Strategic Management Services" },
  { id: 99, label: "Design Services" },
  { id: 140, label: "Graphic Design" },
  { id: 3256, label: "Regenerative Design" },
  { id: 3126, label: "Interior Design" },
  { id: 3242, label: "Engineering Services" },
  { id: 3248, label: "Robotics Engineering" },
  { id: 3249, label: "Surveying and Mapping Services" },
  { id: 96, label: "IT Services and IT Consulting" },
  { id: 118, label: "Computer and Network Security" },
  { id: 3244, label: "Digital Accessibility Services" },
  { id: 3102, label: "IT System Custom Software Development" },
  { id: 3106, label: "IT System Data Services" },
  { id: 1855, label: "IT System Design Services" },
  { id: 3104, label: "IT System Installation and Disposal" },
  { id: 3103, label: "IT System Operations and Maintenance" },
  { id: 3107, label: "IT System Testing and Evaluation" },
  { id: 3105, label: "IT System Training and Support" },
  { id: 10, label: "Legal Services" },
  { id: 120, label: "Alternative Dispute Resolution" },
  { id: 9, label: "Law Practice" },
  { id: 136, label: "Photography" },
  { id: 70, label: "Research Services" },
  { id: 12, label: "Biotechnology Research" },
  { id: 114, label: "Nanotechnology Research" },
  { id: 130, label: "Think Tanks" },
  { id: 3243, label: "Services for Renewable Energy" },
  { id: 16, label: "Veterinary Services" },
  { id: 1757, label: "Real Estate and Equipment Rental Services" },
  { id: 1779, label: "Equipment Rental Services" },
  { id: 1798, label: "Commercial and Industrial Equipment Rental" },
  { id: 1786, label: "Consumer Goods Rental" },
  { id: 44, label: "Real Estate" },
  { id: 128, label: "Leasing Non-residential Real Estate" },
  { id: 1759, label: "Leasing Residential Real Estate" },
  { id: 1770, label: "Real Estate Agents and Brokers" },
  { id: 27, label: "Retail" },
  { id: 1339, label: "Food and Beverage Retail" },
  { id: 22, label: "Retail Groceries" },
  { id: 1445, label: "Online and Mail Order Retail" },
  { id: 19, label: "Retail Apparel and Fashion" },
  {
    id: 1319,
    label: "Retail Appliances, Electrical, and Electronic Equipment",
  },
  { id: 3186, label: "Retail Art Dealers" },
  { id: 111, label: "Retail Art Supplies" },
  { id: 1409, label: "Retail Books and Printed News" },
  { id: 1324, label: "Retail Building Materials and Garden Equipment" },
  { id: 1423, label: "Retail Florists" },
  { id: 1309, label: "Retail Furniture and Home Furnishings" },
  { id: 1370, label: "Retail Gasoline" },
  { id: 1359, label: "Retail Health and Personal Care Products" },
  { id: 3250, label: "Retail Pharmacies" },
  { id: 143, label: "Retail Luxury Goods and Jewelry" },
  { id: 1292, label: "Retail Motor Vehicles" },
  { id: 1407, label: "Retail Musical Instruments" },
  { id: 138, label: "Retail Office Equipment" },
  { id: 1424, label: "Retail Office Supplies and Gifts" },
  { id: 1431, label: "Retail Recyclable Materials & Used Merchandise" },
  { id: 1594, label: "Technology, Information and Media" },
  { id: 3133, label: "Media & Telecommunications" },
  { id: 82, label: "Book and Periodical Publishing" },
  { id: 1602, label: "Book Publishing" },
  { id: 81, label: "Newspaper Publishing" },
  { id: 1600, label: "Periodical Publishing" },
  { id: 36, label: "Broadcast Media Production and Distribution" },
  { id: 1641, label: "Cable and Satellite Programming" },
  { id: 1633, label: "Radio and Television Broadcasting" },
  { id: 35, label: "Movies, Videos and Sound" },
  { id: 127, label: "Animation and Post-production" },
  { id: 126, label: "Media Production" },
  { id: 1611, label: "Movies and Sound Recording" },
  { id: 1623, label: "Sound Recording" },
  { id: 1625, label: "Sheet Music Publishing" },
  { id: 8, label: "Telecommunications" },
  { id: 1649, label: "Satellite Telecommunications" },
  { id: 1644, label: "Telecommunications Carriers" },
  { id: 119, label: "Wireless Services" },
  { id: 6, label: "Technology, Information and Internet" },
  { id: 2458, label: "Data Infrastructure and Analytics" },
  { id: 3134, label: "Blockchain Services" },
  { id: 3128, label: "Business Intelligence Platforms" },
  { id: 3252, label: "Climate Data and Analytics" },
  { id: 84, label: "Information Services" },
  { id: 3132, label: "Internet Publishing" },
  { id: 3129, label: "Business Content" },
  { id: 113, label: "Online Audio and Video Media" },
  { id: 3124, label: "Internet News" },
  { id: 85, label: "Libraries" },
  { id: 3125, label: "Blogs" },
  { id: 1285, label: "Internet Marketplace Platforms" },
  { id: 3127, label: "Social Networking Platforms" },
  { id: 4, label: "Software Development" },
  { id: 109, label: "Computer Games" },
  { id: 3131, label: "Mobile Gaming Apps" },
  { id: 5, label: "Computer Networking Products" },
  { id: 3130, label: "Data Security Software Products" },
  { id: 3101, label: "Desktop Computing Software Products" },
  { id: 3099, label: "Embedded Software Products" },
  { id: 3100, label: "Mobile Computing Software Products" },
  { id: 116, label: "Transportation, Logistics, Supply Chain and Storage" },
  { id: 94, label: "Airlines and Aviation" },
  { id: 87, label: "Freight and Package Transportation" },
  { id: 1495, label: "Ground Passenger Transportation" },
  { id: 1504, label: "Interurban and Rural Bus Services" },
  { id: 1512, label: "School and Employee Bus Services" },
  { id: 1517, label: "Shuttles and Special Needs Transportation Services" },
  { id: 1532, label: "Sightseeing Transportation" },
  { id: 1505, label: "Taxi and Limousine Services" },
  { id: 1497, label: "Urban Transit Services" },
  { id: 95, label: "Maritime Transportation" },
  { id: 1520, label: "Pipeline Transportation" },
  { id: 1573, label: "Postal Services" },
  { id: 1481, label: "Rail Transportation" },
  { id: 92, label: "Truck Transportation" },
  { id: 93, label: "Warehousing and Storage" },
  { id: 59, label: "Utilities" },
  { id: 383, label: "Electric Power Generation" },
  { id: 385, label: "Fossil Fuel Electric Power Generation" },
  { id: 386, label: "Nuclear Electric Power Generation" },
  { id: 3240, label: "Renewable Energy Power Generation" },
  { id: 390, label: "Biomass Electric Power Generation" },
  { id: 389, label: "Geothermal Electric Power Generation" },
  { id: 384, label: "Hydroelectric Power Generation" },
  { id: 387, label: "Solar Electric Power Generation" },
  { id: 2489, label: "Wind Electric Power Generation" },
  { id: 382, label: "Electric Power Transmission, Control, and Distribution" },
  { id: 397, label: "Natural Gas Distribution" },
  { id: 398, label: "Water, Waste, Steam, and Air Conditioning Services" },
  { id: 404, label: "Steam and Air-Conditioning Supply" },
  { id: 1981, label: "Waste Collection" },
  { id: 1986, label: "Waste Treatment and Disposal" },
  { id: 400, label: "Water Supply and Irrigation Systems" },
  { id: 133, label: "Wholesale" },
  { id: 1267, label: "Wholesale Alcoholic Beverages" },
  { id: 1222, label: "Wholesale Apparel and Sewing Supplies" },
  { id: 1171, label: "Wholesale Appliances, Electrical, and Electronics" },
  { id: 49, label: "Wholesale Building Materials" },
  { id: 1257, label: "Wholesale Chemical and Allied Products" },
  { id: 1157, label: "Wholesale Computer Equipment" },
  { id: 1221, label: "Wholesale Drugs and Sundries" },
  { id: 1231, label: "Wholesale Food and Beverage" },
  { id: 1230, label: "Wholesale Footwear" },
  { id: 1137, label: "Wholesale Furniture and Home Furnishings" },
  { id: 1178, label: "Wholesale Hardware, Plumbing, Heating Equipment" },
  { id: 134, label: "Wholesale Import and Export" },
  { id: 1208, label: "Wholesale Luxury Goods and Jewelry" },
  { id: 1187, label: "Wholesale Machinery" },
  { id: 1166, label: "Wholesale Metals and Minerals" },
  { id: 1128, label: "Wholesale Motor Vehicles and Parts" },
  { id: 1212, label: "Wholesale Paper Products" },
  { id: 1262, label: "Wholesale Petroleum and Petroleum Products" },
  { id: 1153, label: "Wholesale Photography Equipment and Supplies" },
  { id: 1250, label: "Wholesale Raw Farm Products" },
  { id: 1206, label: "Wholesale Recyclable Materials" },
];

export default LinkedInIndustries;
