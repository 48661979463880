import CiroFilterModalDropdownSection from "../../../shared/CiroFilter/CiroFilterModalDropdownSection";
import LinkedInIndustries from "../../../../constants/LinkedinFilters/LinkedInIndustries";
import CiroDropDown from "../../../shared/CiroDropdown";

const CuratedSearchFilterIndustrySection = ({
  currentIndustryFilter,
  setCurrentIndustryFilter,
  defaultOpen = false,
}: {
  currentIndustryFilter: { label: string; id: number }[];
  setCurrentIndustryFilter: (v: { label: string; id: number }[]) => void;
  defaultOpen?: boolean;
}) => {
  return (
    <CiroFilterModalDropdownSection
      defaultOpen={defaultOpen}
      numFiltersApplied={currentIndustryFilter.length}
      sectionTitle={"Industry"}
      previews={currentIndustryFilter.map((i) => i.label)}
      filterOptions={[
        {
          label: "Industry includes any of:",
          filter: (
            <CiroDropDown
              isMulti={true}
              value={currentIndustryFilter.map((c) => c.id)}
              onChange={(newValue) => {
                const selectedIndustries = LinkedInIndustries.filter(
                  (industry) => newValue.includes(industry.id),
                );
                setCurrentIndustryFilter(selectedIndustries);
              }}
              options={LinkedInIndustries.map((industry) => {
                return {
                  value: industry.id,
                  label: industry.label,
                };
              })}
            />
          ),
        },
      ]}
    />
  );
};

export default CuratedSearchFilterIndustrySection;
