import { Routes, Route } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import BusinessDetails from "./routes/businessDetails/BusinessDetails";
import Collections from "./routes/collections/Collections";
import Auth from "./Auth";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  NormalizedCacheObject,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Loading from "./components/shared/Loading";
import AppContext from "./contexts/AppContext";
import Profile from "./routes/profile/Profile";
import Accounts from "./routes/accounts/Accounts";
import useApi from "./utils/useApi";
import classNames from "classnames";
import Admin from "./routes/admin/Admin";
import NavigationContainer from "./components/navigation/NavigationContainer";
import CollectionSearch from "./routes/collections/CollectionSearch";
import Enrichments from "./routes/enrichments/Enrichments";
import EnrichmentFlow from "./routes/enrichments/EnrichmentFlow";
import MobileNumbers from "./routes/enrichments/MobileNumbers";
import { Toaster } from "react-hot-toast";
import { usePostHog } from "posthog-js/react";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { Helmet } from "react-helmet";
import CrmMobileConnection from "./routes/enrichments/CrmMobileConnection";
import ApolloMobileConnection from "./routes/enrichments/ApolloMobileConnection";
import MobileNumbersWorkflow from "./routes/enrichments/MobileNumbersWorkflow";
import Home from "./routes/home/Home";
import ProfileDataProviderConnectionSettings from "./routes/profile/ProfileDataProviderConnectionSettings";
import Automation from "./routes/automation/Automation";
import AutomationList from "./routes/automationList/AutomationList";
import AutopilotCuratedSearch from "./routes/autopilot/AutopilotCuratedSearch";
import sendPayloadToExtension, { CHECK_EXTENSION_INSTALLED } from "./utils/sendPayloadToExtension";

const apiOrigin = document.location.origin;

const errorLink = onError(({ networkError, operation }) => {
  if (
    networkError &&
    "statusCode" in networkError &&
    networkError.statusCode >= 500
  ) {
    const requestBody = operation.getContext().body;

    Sentry.withScope((scope) => {
      scope.setExtra("requestBody", requestBody);
      Sentry.captureException(networkError);
    });
  }
});

// Doing this outside of the component since useParams resets the app constantly
const params = new URL(document.location.toString()).searchParams;
const readyForRedirect = Boolean(params.get("code") && params.get("state"));

const App = () => {
  const [accessToken, setAccessToken] = useState("");
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  const {
    user,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    handleRedirectCallback,
  } = useAuth0();
  const [accessTokenLoading, setAccessTokenLoading] = useState(true);
  const posthog = usePostHog();

  useEffect(() => {
    async function setAuth0Token() {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_USE_DEV_AUTH0_TENANT
            ? "https://dev.ciro.io/graphql"
            : "https://app.ciro.io/graphql",
          scope: "read:users",
        });

        setAccessToken(accessToken);
        setAccessTokenLoading(false);
      } catch (error) {
        console.error(error);
        setAccessTokenLoading(false);
      }
    }
    setAuth0Token();
  }, [getAccessTokenSilently]);

  const httpLink = createHttpLink({
    uri: document.location.origin.concat("/api/graphql"),
  });

  const authLink = setContext((_, { headers }) => {
    // get the access token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : "",
      },
    };
  });

  // initialize a GraphQL client
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Permissions: {
          merge: true,
        },
      },
    }),
    link: from([errorLink, authLink.concat(httpLink)]),
  }) as ApolloClient<NormalizedCacheObject>;

  const opts = {
    audience: process.env.REACT_APP_USE_DEV_AUTH0_TENANT
      ? "https://dev.ciro.io/graphql"
      : "https://app.ciro.io/graphql",
    scope: "read:leads_table",
  };
  const {
    error: validationError,
    refresh: refreshValidation,
    loading: validationLoading,
  } = useApi(`${apiOrigin}/api/validate`, opts);

  const getTokenAndTryAgain = async () => {
    await getAccessTokenWithPopup(opts);
    refreshValidation();
  };
  useEffect(() => {
    if (user) {
      posthog.identify(user.sub, {
        email: user.email,
      });
      Sentry.setUser({
        id: user.sub,
        email: user.email,
      });
    }
  }, [posthog, user]);

  const redirectIfPossible = useCallback(async () => {
    const handleRedirectCallbackResponse = await handleRedirectCallback();
    if (handleRedirectCallbackResponse.appState?.target) {
      window.location.replace(handleRedirectCallbackResponse.appState.target);
    }
  }, [handleRedirectCallback]);

  if (readyForRedirect) {
    redirectIfPossible();
  }

  useEffect(() => {
    const checkExtension = async () => {
      try {
        await sendPayloadToExtension({
          payload: {
            action: CHECK_EXTENSION_INSTALLED,
          },
        });
        setIsExtensionInstalled(true);
      } catch (error) {
        setIsExtensionInstalled(false);
      }
    };
    
    checkExtension();
  }, []);

  if (accessTokenLoading || validationLoading) {
    return <Loading />;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <AppContext.Provider value={{ accessToken, user, apolloClient, isExtensionInstalled }}>
        <NavigationContainer isLoggedIn={Boolean(accessToken)}>
          <Helmet>
            <script type="text/javascript">{`(()=>{"use strict";var t,e={appId:"ve0hhs4x2d",v:2,q:[],call:function(){this.q.push(arguments)}};window.Atlas=e;var n=document.createElement("script");n.async=!0,n.src="https://app.atlas.so/client-js/atlas.bundle.js";var s=document.getElementsByTagName("script")[0];null===(t=s.parentNode)||void 0===t||t.insertBefore(n,s)})()`}</script>
            <script type="text/javascript">{`window.Atlas.call("start");`}</script>
          </Helmet>
          {validationError && (
            <Auth
              error={validationError}
              getTokenAndTryAgain={getTokenAndTryAgain}
            />
          )}
          {!validationError && (
            <div className={classNames("flex-col", "flex", "justify-center")}>
              <Toaster position="top-right" />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/accounts" element={<Accounts />} />
                <Route
                  path="/businessDetails"
                  element={<BusinessDetails />}
                ></Route>
                <Route path="/collections" element={<Collections />}></Route>
                <Route
                  path="/collections/:collectionId"
                  element={<CollectionSearch />}
                ></Route>
                <Route path="/home" element={<Home />}></Route>
                <Route path="/profile" element={<Profile />}></Route>
                <Route path="/admin" element={<Admin />}></Route>
                <Route path="/lists" element={<Enrichments />}></Route>
                <Route path="/enrich-crm" element={<MobileNumbers />}></Route>
                <Route path={"/autopilot"} element={<Automation />}></Route>
                <Route
                  path={"/autopilot/lists"}
                  element={<Automation />}
                ></Route>
                <Route
                  path="/autopilot/lists/:listId"
                  element={<AutomationList />}
                ></Route>
                <Route
                  path="/autopilot/search-builder/:curatedSearchId?"
                  element={<AutopilotCuratedSearch />}
                ></Route>
                <Route
                  path="/lists/:enrichmentFlowId"
                  element={<EnrichmentFlow />}
                ></Route>
                <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>Page not found.</p>
                    </main>
                  }
                />
                <Route
                  path="/enrich-crm/crm-integration"
                  element={<CrmMobileConnection />}
                ></Route>
                <Route
                  path="/enrich-crm/apollo-integration"
                  element={<ApolloMobileConnection />}
                ></Route>
                <Route
                  path="/enrich-crm/workflow/:workflowId?"
                  element={<MobileNumbersWorkflow />}
                ></Route>
                <Route
                  path="/enrich-crm/workflows"
                  element={<MobileNumbers startOnWorkflows={true} />}
                ></Route>
                <Route
                  path="/profile/data-provider-connection"
                  element={<ProfileDataProviderConnectionSettings />}
                ></Route>
              </Routes>
            </div>
          )}
        </NavigationContainer>
      </AppContext.Provider>
    </ApolloProvider>
  );
};

export default App;
