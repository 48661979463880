import classNames from "classnames";

interface ICiroCardProps {
  children: any;
  size?: "tiny" | "small" | "medium" | "large";
  customClassName?: string;
  customBackgroundClassName?: string;
}

const CiroCard = ({
  children,
  size = "medium",
  customClassName,
  customBackgroundClassName,
}: ICiroCardProps) => {
  return (
    <div
      className={classNames(
        { "bg-white": !customBackgroundClassName },
        { [customBackgroundClassName || ""]: customBackgroundClassName },
        "border-1",
        "border-gray-200",
        "flex-col",
        "flex",
        "rounded-xl",
        "text-left",
        {
          "p-2": size === "tiny",
          "p-4": size === "small",
          "p-8": size === "medium",
          "p-12": size === "large",
        },
        customClassName,
      )}
    >
      {children}
    </div>
  );
};

export default CiroCard;
