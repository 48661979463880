import classNames from "classnames";
import { Maybe } from "graphql/jsutils/Maybe";
import { UseFormRegister, FieldValues } from "react-hook-form";
import CiroErrorMsg from "./forms/CiroErrorMsg";
import { forwardRef, useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

interface ICiroTextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  containerClassName?: string;
  icon?: JSX.Element;
  isPassword?: boolean;
  label?: string;
  largeBorderRadius?: boolean;
  error?: Maybe<string>;
  placeholder?: string;
  showErrorAsBorder?: boolean;
  register?: UseFormRegister<FieldValues> | {};
  value?: string;
  wrapperClassName?: string;
}

const CiroBaseTextInput = forwardRef(
  (
    {
      containerClassName,
      icon,
      isPassword,
      error,
      largeBorderRadius,
      register = {},
      disabled,
      showErrorAsBorder,
      className,
      ...textInputProps
    }: ICiroTextInputProps,
    ref,
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    return (
      <div
        className={classNames(
          {
            "border-gray-300": !error,
            "border-red-500": error && showErrorAsBorder,
          },
          "border-1",
          "flex",
          "items-center",
          "placeholder-gray-500",
          "py-1.5",
          "rounded-md",
          "w-full",
          {
            "bg-white": !disabled,
            "bg-gray-100": disabled,
          },
          containerClassName,
        )}
      >
        <span className={classNames("mx-2")}>{icon}</span>
        <input
          type={isPassword && !isPasswordVisible ? "password" : "text"}
          className={classNames(
            "w-full focus:outline-none ring-transparent",
            className,
          )}
          disabled={disabled}
          ref={Object.keys(register).length ? undefined : ref as any}
          {...register}
          {...textInputProps}
        />
        {isPassword && (
          <span
            className={classNames("cursor-pointer", "pr-2")}
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          >
            {isPasswordVisible ? (
              <EyeIcon className={classNames("h-4")} />
            ) : (
              <EyeSlashIcon className={classNames("h-4")} />
            )}
          </span>
        )}
      </div>
    );
  },
);

const CiroTextInput = forwardRef(
  (
    {
      containerClassName,
      disabled,
      error,
      label,
      largeBorderRadius,
      showErrorAsBorder,
      wrapperClassName = "",
      ...inputProps
    }: ICiroTextInputProps,
    ref,
  ) => {
    return (
      <div className={wrapperClassName}>
        {label && <div className={classNames("pb-2")}>{label}</div>}
        <CiroBaseTextInput
          containerClassName={containerClassName}
          ref={ref as any}
          showErrorAsBorder={showErrorAsBorder}
          error={error}
          disabled={disabled}
          {...inputProps}
        />
        {!showErrorAsBorder && <CiroErrorMsg error={error} />}
      </div>
    );
  },
);

export default CiroTextInput;
