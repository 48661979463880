import { useContext, useState } from "react";
import CuratedSearchPersonaDefault from "./CuratedSearchPersonaDefault";
import CuratedSearchPersonaAiEdit from "./CuratedSearchPersonaAiEdit";
import CuratedSearchCardLoading from "../CuratedSearchCardLoading";
import AutopilotCuratedSearchContext from "../../../contexts/AutopilotCuratedSearchContext";
import { EnumPersonaSource } from "../../../__generated__/graphql";

const CuratedSearchPersonaCard = ({
  initialLoading,
}: {
  initialLoading: boolean;
}) => {
  const { selectedPersona } = useContext(AutopilotCuratedSearchContext);

  const [initialAIPrompt, setInitialAIPrompt] = useState<string>("");

  if (initialLoading) {
    return <CuratedSearchCardLoading />;
  }

  if (initialAIPrompt) {
    return (
      <CuratedSearchPersonaAiEdit
        initialAIPrompt={initialAIPrompt}
        selectedPersona={selectedPersona}
        setInitialAIPrompt={setInitialAIPrompt}
      />
    );
  }

  return (
    <CuratedSearchPersonaDefault
      selectedPersona={selectedPersona}
      setInitialAIPrompt={setInitialAIPrompt}
      sourceIsCiro={selectedPersona?.source === EnumPersonaSource.Ciro}
    />
  );
};

export default CuratedSearchPersonaCard;
