import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import {
  ApolloIntegrationApiKeyForm_ApolloIntegrationFragment,
  CrmChooseIntegration_UserAccountFragment,
  IntegrationEnum,
  IntegrationModal_UserAccountQuery,
  IntegrationModal_UserAccountQueryVariables,
  MergeIntegrationEnum,
} from "../../../__generated__/graphql";
import ChooseIntegration, {
  CrmChooseIntegration_UserAccount,
} from "./ChooseIntegration";
import ApolloIntegrationApiKeyForm, {
  ApolloIntegrationApiKeyForm_ApolloIntegration,
} from "./apollo/ApolloIntegrationApiKeyForm";
import { IntegrationModalPhases } from "../manageIntegrationButton/ManageIntegrationsButton";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import NavigationContext from "../../../contexts/NavigationContext";
import CiroModal from "../../shared/CiroModal";
import Loading from "../../shared/Loading";
import classNames from "classnames";

export const IntegrationModal_UserAccount = gql`
  query IntegrationModal_UserAccount {
    userAccount {
      id
      mergeLinkTokens {
        linkToken
        integration
      }
      org {
        organizationMergeIntegration {
          integration
        }
        apolloIntegration {
          ...ApolloIntegrationApiKeyForm_ApolloIntegration
        }
      }
      ...CrmChooseIntegration_UserAccount
    }
  }
  ${ApolloIntegrationApiKeyForm_ApolloIntegration}
  ${CrmChooseIntegration_UserAccount}
`;

export function IntegrationModal() {
  const { integrationModalPhase, setIntegrationModalPhase, resetAvailableIntegrations } =
  useContext(NavigationContext);
  
  const { data: userAccountData, loading: userAccountLoading } = useQuery<
  IntegrationModal_UserAccountQuery,
  IntegrationModal_UserAccountQueryVariables
  >(IntegrationModal_UserAccount);
  
  const userAccount = userAccountData?.userAccount;
  
  // Need to save the initial prop value in a ref because the prop value will change,
  // and changing the value will reload the mergeLink component, creating an error
  const initialLinkTokens = useRef(userAccount?.mergeLinkTokens);
  const [targetMergeIntegration, setTargetMergeIntegration] =
    useState<MergeIntegrationEnum | null>(null);
  const client = useApolloClient();

  const integrations = [] as IntegrationEnum[];
  if (userAccount?.org?.apolloIntegration) {
    integrations.push(IntegrationEnum.Apollo);
  }
  if (userAccount?.org?.organizationMergeIntegration) {
    integrations.push(
      userAccount?.org?.organizationMergeIntegration
        ?.integration as unknown as IntegrationEnum,
    );
  }

  useEffect(() => {
    if (initialLinkTokens.current == null) {
      initialLinkTokens.current = userAccount?.mergeLinkTokens;
    }
  }, [userAccount]);

  const refreshUserAccount = useCallback(() => {
    client.cache.evict({ fieldName: "userAccount" });
  }, [client.cache]);

  const onSuccess = useCallback(async () => {
    refreshUserAccount();
    setIntegrationModalPhase(IntegrationModalPhases.GET_STARTED);
    resetAvailableIntegrations();
    toast.success("Successfully connected");
  }, [refreshUserAccount, setIntegrationModalPhase, resetAvailableIntegrations]);

  const { open: openMergeModal, isReady } = useMergeLink({
    linkToken: String(
      initialLinkTokens.current?.find(
        (token) => token?.integration === targetMergeIntegration,
      )?.linkToken,
    ),
    onSuccess,
    onExit: () => {
      refreshUserAccount();
      setIntegrationModalPhase(IntegrationModalPhases.GET_STARTED);
      resetAvailableIntegrations();
    },
  });

  const apolloIntegration = userAccount?.org?.apolloIntegration;

  useEffect(() => {
    if (integrationModalPhase === IntegrationModalPhases.MERGE_MODAL) {
      openMergeModal();
    }
  }, [integrationModalPhase, openMergeModal]);

  if (integrationModalPhase === IntegrationModalPhases.GET_STARTED) {
    return null;
  }

  if (!isReady || userAccountLoading) {
    return (
      <CiroModal
        isOpen={true}
        size="SMALL"
        onClose={() => {
          resetAvailableIntegrations();
          setIntegrationModalPhase(IntegrationModalPhases.GET_STARTED);
        }}
      >
        <div
          className={classNames(
            "flex",
            "justify-center",
            "items-center",
          )}
        >
          <Loading size="SMALL" />
        </div>
      </CiroModal>
    );
  }

  return (
    <>
      {integrationModalPhase === IntegrationModalPhases.CHOOSE_INTEGRATION &&
        isReady && (
          <ChooseIntegration
            connectedIntegrations={integrations}
            setCrmIntegrationPhase={setIntegrationModalPhase}
            setTargetMergeIntegration={setTargetMergeIntegration}
            userAccount={
              userAccount as CrmChooseIntegration_UserAccountFragment
            }
            refetchUserAccount={refreshUserAccount}
          />
        )}
      {integrationModalPhase === IntegrationModalPhases.APOLLO_API_KEY &&
        isReady && (
          <ApolloIntegrationApiKeyForm
            apolloIntegration={
              apolloIntegration as ApolloIntegrationApiKeyForm_ApolloIntegrationFragment
            }
            refetchUserAccount={refreshUserAccount}
            onSuccess={() => {
              toast.success("Successfully connected Apollo");
              setIntegrationModalPhase(IntegrationModalPhases.GET_STARTED);
              refreshUserAccount();
              resetAvailableIntegrations();
            }}
          onClose={() => {
              resetAvailableIntegrations();
              setIntegrationModalPhase(IntegrationModalPhases.GET_STARTED);
              refreshUserAccount();
            }}
          />
        )}
    </>
  );
}
