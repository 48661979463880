import CiroButton from "../shared/CiroButton";
import SkeletonLoading from "../shared/SkeletonLoading";
import CuratedSearchCard from "./CuratedSearchCard";

const CuratedSearchCardLoading = ({
  header = (
    <SkeletonLoading
      numSkeletons={1}
      skeletonHeight="1.5rem"
      containerCustomClassName="w-full"
    />
  ),
  editButton = (
    <CiroButton disabled={true} analyticsField="Disabled">
      Edit
    </CiroButton>
  ),
}: {
  header?: any;
  editButton?: any;
}) => {
  return (
    <CuratedSearchCard header={header} editButton={editButton}>
      <SkeletonLoading numSkeletons={5} skeletonHeight="1.5rem" />
    </CuratedSearchCard>
  );
};

export default CuratedSearchCardLoading;
