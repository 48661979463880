import classNames from "classnames";
import {
  PencilIcon,
  ArrowDownTrayIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroTable from "../shared/CiroTable/CiroTable";
import CiroTableHeader from "../shared/CiroTable/CiroTableHeader";
import CiroTableRow from "../shared/CiroTable/CiroTableRow";
import CiroTableCell from "../shared/CiroTable/CiroTableCell";
import { dateToTimeAgo } from "../../utils/formatters";
import { gql } from "@apollo/client";
import { ContactListTable_ContactListFragment } from "../../__generated__/graphql";
import DeleteContactListModal from "./DeleteContactListModal";
import UpsertContactListModal from "./UpsertContactListModal";
import { useState } from "react";
import CiroSpinner from "../shared/CiroSpinner";
import { Link } from "react-router-dom";
import useHandleDownloadContactList from "../../reactHooks/automationList/useHandleDownloadContactList";
interface IContactListTable {
  contactLists: ContactListTable_ContactListFragment[];
  refetch: () => void;
}

export const ContactListTable_ContactList = gql`
  fragment ContactListTable_ContactList on ContactList {
    id
    name
    contactCount
    created_at
  }
`;

const ContactListDownloadButton = ({
  listId,
  listName,
}: {
  listId: number;
  listName: string;
}) => {
  const { handleDownloadContactList, isDownloading } =
    useHandleDownloadContactList();

  return (
    <CiroButton
      analyticsField="download-list"
      analyticsProps={{ listId }}
      style={CiroButtonStyleEnum.UNSTYLED}
      disabled={isDownloading}
      onClick={() =>
        handleDownloadContactList({
          listId,
          listName,
        })
      }
    >
      {isDownloading ? (
        <CiroSpinner loading={true} />
      ) : (
        <ArrowDownTrayIcon className={classNames("h-4", "w-4")} />
      )}
    </CiroButton>
  );
};

const ContactListTable = ({ contactLists, refetch }: IContactListTable) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedList, setSelectedList] =
    useState<ContactListTable_ContactListFragment | null>(null);

  const handleDeleteContactList = (
    list: ContactListTable_ContactListFragment,
  ) => {
    setSelectedList(list);
    setDeleteModalOpen(true);
  };

  const handleEditContactList = (
    list: ContactListTable_ContactListFragment,
  ) => {
    setSelectedList(list);
    setEditModalOpen(true);
  };

  if (contactLists.length === 0) {
    return null;
  }

  const headers = [
    <CiroTableHeader key="name" isFirst={true}>
      Name
    </CiroTableHeader>,
    <CiroTableHeader
      key="contacts"
      className={classNames("hidden", "md:table-cell")}
    >
      Contacts
    </CiroTableHeader>,
    <CiroTableHeader key="created">Created</CiroTableHeader>,
    <CiroTableHeader key="actions" isLast={true}>
      Actions
    </CiroTableHeader>,
  ];

  return (
    <>
      <CiroTable>
        <thead className={classNames("table-header-group")}>
          <CiroTableRow clickable={false}>{headers}</CiroTableRow>
        </thead>
        <tbody className={classNames("table-row-group")}>
          {contactLists?.map((list, i) => {
            const isLastRow = i === contactLists.length - 1;
            return (
              <CiroTableRow key={list.id} clickable={false}>
                <CiroTableCell lastLeft={isLastRow} isLastRow={isLastRow}>
                  <Link
                    className={classNames("hover:underline")}
                    to={`/autopilot/lists/${list.id}`}
                  >
                    {list.name}
                  </Link>
                </CiroTableCell>
                <CiroTableCell
                  isLastRow={isLastRow}
                  className={classNames("hidden", "md:table-cell")}
                >
                  {list.contactCount || "-"}
                </CiroTableCell>
                <CiroTableCell isLastRow={isLastRow}>
                  {dateToTimeAgo(list.created_at)}
                </CiroTableCell>
                <CiroTableCell isLastRow={isLastRow} lastRight={isLastRow}>
                  <div className={classNames("flex", "gap-4", "justify-start")}>
                    <CiroButton
                      analyticsField="edit-list"
                      analyticsProps={{ listId: list.id }}
                      style={CiroButtonStyleEnum.UNSTYLED}
                      onClick={() => handleEditContactList(list)}
                    >
                      <PencilIcon className={classNames("h-4", "w-4")} />
                    </CiroButton>
                    <ContactListDownloadButton
                      listId={list.id}
                      listName={list.name}
                    />
                    <CiroButton
                      analyticsField="delete-list"
                      analyticsProps={{ listId: list.id }}
                      style={CiroButtonStyleEnum.UNSTYLED}
                      onClick={() => handleDeleteContactList(list)}
                    >
                      <TrashIcon className={classNames("h-4", "w-4")} />
                    </CiroButton>
                  </div>
                </CiroTableCell>
              </CiroTableRow>
            );
          })}
        </tbody>
      </CiroTable>

      {selectedList && (
        <>
          <DeleteContactListModal
            isOpen={deleteModalOpen}
            listId={selectedList.id}
            listName={selectedList.name}
            onClose={() => {
              setDeleteModalOpen(false);
              setSelectedList(null);
            }}
            onSuccess={async () => {
              await refetch();
            }}
          />
          <UpsertContactListModal
            isOpen={editModalOpen}
            onClose={() => {
              setEditModalOpen(false);
              setSelectedList(null);
            }}
            onSuccess={async () => {
              await refetch();
            }}
            initialData={{
              id: selectedList.id,
              name: selectedList.name,
            }}
          />
        </>
      )}
    </>
  );
};

export default ContactListTable;
