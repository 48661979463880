import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  CuratedSearchPersonaAiEdit_SuggestedPersonaDetailChangesQuery,
  CuratedSearchPersonaAiEdit_SuggestedPersonaDetailChangesQueryVariables,
  CuratedSearchPersonaAiEdit_UpsertPersonaMutation,
  CuratedSearchPersonaAiEdit_UpsertPersonaMutationVariables,
} from "../../../__generated__/graphql";
import { useCallback, useContext, useEffect, useState } from "react";
import CuratedSearchPersonaContainer from "../CuratedSearchCard";
import CuratedSearchCardLoading from "../CuratedSearchCardLoading";
import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import CiroTextInput from "../../shared/CiroTextInput";
import AutopilotCuratedSearchContext, {
  ICuratedSearchPersona,
} from "../../../contexts/AutopilotCuratedSearchContext";
import CuratedSearchPersonaEditName from "./CuratedSearchPersonaEdit/CuratedSearchPersonaEditName";
import CuratedSearchPersonaEditDescription from "./CuratedSearchPersonaEdit/CuratedSearchPersonaEditDescription";

const CuratedSearchPersonaAiEdit_SuggestedPersonaDetailChanges = gql`
  query CuratedSearchPersonaAiEdit_SuggestedPersonaDetailChanges(
    $input: SuggestedPersonaDetailChangesInput!
  ) {
    suggestedPersonaDetailChanges(input: $input) {
      name
      description
      updateReason
    }
  }
`;

const CuratedSearchPersonaAiEdit_UpsertPersona = gql`
  mutation CuratedSearchPersonaAiEdit_UpsertPersona(
    $input: UpsertPersonaInput!
  ) {
    upsertPersona(input: $input) {
      success
      message
      persona {
        id
        name
        description
      }
    }
  }
`;

const CuratedSearchPersonaAiEdit = ({
  initialAIPrompt,
  selectedPersona,
  setInitialAIPrompt,
}: {
  initialAIPrompt: string;
  selectedPersona: ICuratedSearchPersona;
  setInitialAIPrompt: (prompt: string) => void;
}) => {
  const {
    allPersonas,
    setAllPersonas,
    setMayNeedToRegenerateFilters,
    setSelectedPersona,
  } = useContext(AutopilotCuratedSearchContext);
  const [suggestedPersona, setSuggestedPersona] =
    useState<ICuratedSearchPersona>(selectedPersona);
  const [updateReason, setUpdateReason] = useState("");
  const [upsertPersona, { loading: upsertPersonaLoading }] = useMutation<
    CuratedSearchPersonaAiEdit_UpsertPersonaMutation,
    CuratedSearchPersonaAiEdit_UpsertPersonaMutationVariables
  >(CuratedSearchPersonaAiEdit_UpsertPersona);
  const [newPrompt, setNewPrompt] = useState("");

  const [
    suggestPersonaDetailChanges,
    { loading: suggestedPersonaDetailChangesLoading },
  ] = useLazyQuery<
    CuratedSearchPersonaAiEdit_SuggestedPersonaDetailChangesQuery,
    CuratedSearchPersonaAiEdit_SuggestedPersonaDetailChangesQueryVariables
  >(CuratedSearchPersonaAiEdit_SuggestedPersonaDetailChanges);

  const handleUpdatePersona = () => {
    upsertPersona({
      variables: {
        input: {
          name: suggestedPersona.name,
          description: suggestedPersona.description,
          id: selectedPersona?.id,
        },
      },
      onCompleted: (data) => {
        if (data.upsertPersona.success) {
          setInitialAIPrompt("");
          setAllPersonas([
            ...(allPersonas || []).filter(
              (p) => p.id !== data.upsertPersona.persona?.id,
            ),
            data.upsertPersona.persona as ICuratedSearchPersona,
          ]);
          setSelectedPersona({
            ...suggestedPersona,
            id: data.upsertPersona.persona?.id || undefined,
          });
          setMayNeedToRegenerateFilters(true);
        } else {
          console.error("Error updating persona", data.upsertPersona.message);
        }
      },
      onError: (error) => {
        console.error("Error updating persona", error);
      },
    });
  };

  const handleSuggestPersonaDetailChanges = useCallback(
    async ({ instructions }: { instructions: string }) => {
      await suggestPersonaDetailChanges({
        variables: {
          input: {
            name: suggestedPersona?.name,
            description: suggestedPersona?.description,
            instructions,
          },
        },
        onCompleted: (data) => {
          setNewPrompt("");
          setSuggestedPersona({
            id: selectedPersona?.id || undefined,
            name: data.suggestedPersonaDetailChanges.name || "",
            description: data.suggestedPersonaDetailChanges.description || "",
          });
          setUpdateReason(
            data.suggestedPersonaDetailChanges.updateReason || "",
          );
        },
      });
    },
    [suggestPersonaDetailChanges, suggestedPersona, selectedPersona?.id],
  );

  useEffect(() => {
    handleSuggestPersonaDetailChanges({ instructions: initialAIPrompt });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updateMessageError, setUpdateMessageError] = useState("");

  if (suggestedPersonaDetailChangesLoading) {
    return <CuratedSearchCardLoading header="Persona" editButton={null} />;
  }

  return (
    <CuratedSearchPersonaContainer
      header={"Persona"}
      editButton={
        <div className={classNames("flex", "flex-end", "gap-2")}>
          <CiroButton
            disabled={upsertPersonaLoading}
            onClick={() => {
              setInitialAIPrompt("");
            }}
            analyticsField="Cancel AI edit"
          >
            Revert changes
          </CiroButton>
          <CiroButton
            style={CiroButtonStyleEnum.LOUD}
            disabled={upsertPersonaLoading}
            analyticsField="Save AI edit"
            onClick={handleUpdatePersona}
          >
            Looks good!
          </CiroButton>
        </div>
      }
      footer={
        <div>
          {updateReason && (
            <div className={classNames("text-xs", "text-gray-500", "pb-2")}>
              <span className={classNames("font-bold")}>Update reason:</span>{" "}
              {updateReason}
            </div>
          )}
          <div className={classNames("flex", "justify-between", "gap-2")}>
            <CiroTextInput
              error={updateMessageError}
              placeholder="Continue to refine persona"
              containerClassName={classNames("w-full")}
              wrapperClassName={classNames("w-full")}
              value={newPrompt}
              onChange={(e) => {
                setNewPrompt(e.target.value);
                setUpdateMessageError("");
              }}
            />
            <div>
              <CiroButton
                style={CiroButtonStyleEnum.LOUD}
                analyticsField="Update AI edit"
                disabled={upsertPersonaLoading}
                onClick={() => {
                  if (!newPrompt) {
                    setUpdateMessageError("Please enter a message");
                    return;
                  }
                  handleSuggestPersonaDetailChanges({
                    instructions: newPrompt,
                  });
                }}
              >
                Update
              </CiroButton>
            </div>
          </div>
        </div>
      }
    >
      <div className={classNames("flex", "flex-col")}>
        <div className={classNames("pt-4")}>
          <CuratedSearchPersonaEditName
            persona={suggestedPersona}
            setPersona={setSuggestedPersona}
          />

          <CuratedSearchPersonaEditDescription
            persona={suggestedPersona}
            setPersona={setSuggestedPersona}
          />
        </div>
      </div>
    </CuratedSearchPersonaContainer>
  );
};

export default CuratedSearchPersonaAiEdit;
