import { useState } from "react";
import classNames from "classnames";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import {
  gql,
} from "@apollo/client";
import {
  AutomationListFindEmailsButton_ContactListFragment,
} from "../../../__generated__/graphql";
import CiroTooltipContainer from "../../shared/CiroTooltipContainer";
import CiroButton from "../../shared/CiroButton";
import CiroSpinner from "../../shared/CiroSpinner";
import useContactListFindEmails from "../../../reactHooks/automationList/useContactListFindEmails";
import FindEmailsModal from "./AutomationListFindEmailsModal";


export const AutomationListFindEmailsButton_ContactList = gql`
  fragment AutomationListFindEmailsButton_ContactList on ContactList {
    id
    name
    mostRecentEmailRequestTransaction {
      id
      finished_at
      error
    }
  }
`;

const AutomationListFindEmailsButton = ({
  contactList,
  contactIds,
}: {
  contactList: AutomationListFindEmailsButton_ContactListFragment | null;
  contactIds: number[];
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { 
    isCreatingEmailTransaction, 
    isPolling,
    handleFindEmails 
  } = useContactListFindEmails(contactList?.id ?? null);

  // Track loading state to include both transaction creation and polling
  const isLoading = isCreatingEmailTransaction || isPolling;

  let tooltipMessage = "Find email addresses";
  if (isLoading) {
    tooltipMessage = "Running find emails. This may take a few minutes";
  } else if (contactIds.length === 0) {
    tooltipMessage = "No contacts selected";
  }

  return (
    <>
      <CiroTooltipContainer
        tooltip={tooltipMessage}
        disabled={!isLoading && !contactList?.mostRecentEmailRequestTransaction}
      >
        <CiroButton
          onClick={() => {
            setIsModalOpen(true);
          }}
          disabled={isLoading || contactIds.length === 0}
          analyticsField={"Start find emails for contact list"}
          analyticsProps={{
            contactListId: contactList?.id,
          }}
          customPaddingY="py-3"
        >
          <div className={classNames("flex", "items-center")}>
            {isLoading ? (
              <CiroSpinner loading={true} />
            ) : (
              <div className={classNames("flex", "flex-row", "items-center", "gap-1")}>
                <EnvelopeIcon
                  className={classNames("w-5", "h-5")}
                />
                <div>Find emails</div>
              </div>
            )}
          </div>
        </CiroButton>
      </CiroTooltipContainer>
      <FindEmailsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        contactIds={contactIds}
        onFindEmails={handleFindEmails}
      />
    </>
  );
};

export default AutomationListFindEmailsButton;
