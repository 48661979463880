import { useContext, useEffect, useRef, useState } from "react";
import CiroTextInput from "../../../shared/CiroTextInput";
import AutopilotCuratedSearchContext, {
  ICuratedSearchPersona,
} from "../../../../contexts/AutopilotCuratedSearchContext";
import classNames from "classnames";

const CuratedSearchPersonaEditName = ({
  onSave = () => {},
  persona,
  setPersona,
}: {
  onSave?: () => void;
  persona: ICuratedSearchPersona;
  setPersona: (persona: ICuratedSearchPersona) => void;
}) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const showEditingName = isEditingName || !persona?.name;
  const inputRef = useRef<HTMLInputElement>(null);
  const [initialState, setInitialState] = useState(persona?.name);
  const { setMayNeedToRegenerateFilters } = useContext(
    AutopilotCuratedSearchContext,
  );

  useEffect(() => {
    setError(null);
  }, [persona?.name]);

  if (!showEditingName) {
    return (
      <div
        className={classNames(
          "font-semibold",
          "text-gray-500",
          "border",
          "hover:border-gray-300",
          "border-transparent",
          "rounded",
          "cursor-pointer",
          "py-1.5",
          "px-2",
          "line-clamp-1",
          "text-ellipsis",
        )}
        onClick={() => {
          setIsEditingName(true);
          setTimeout(() => {
            inputRef.current?.focus();
          }, 10);
        }}
      >
        {persona?.name}
      </div>
    );
  }

  return (
    <CiroTextInput
      wrapperClassName={classNames("w-full")}
      placeholder="Sales Leaders"
      onFocus={() => {
        setIsEditingName(true);
      }}
      error={error}
      value={persona?.name}
      onChange={(e) => {
        setError(null);
        if (!persona) return;
        setPersona({
          ...persona,
          name: e.target.value || "",
        });
      }}
      ref={inputRef}
      onBlur={() => {
        if (!persona?.name) {
          setError("Name is required");
          return;
        }
        if (initialState !== persona?.name) {
          onSave();
        }
        setInitialState(persona?.name);
        setIsEditingName(false);
        setMayNeedToRegenerateFilters(true);
      }}
    />
  );
};

export default CuratedSearchPersonaEditName;
