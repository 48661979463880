import { gql, useQuery } from "@apollo/client";
import {
  MobileNumbersWorkflowsTable_WorkflowsQuery,
  MobileNumbersWorkflowsTable_WorkflowsQueryVariables,
} from "../../../__generated__/graphql";
import SkeletonLoading from "../../shared/SkeletonLoading";
import CiroTableHeader, {
  CiroTableHeaderAlignEnum,
} from "../../shared/CiroTable/CiroTableHeader";
import CiroTable from "../../shared/CiroTable/CiroTable";
import classNames from "classnames";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import CiroTableCell, {
  CiroTableCellAlignEnum,
} from "../../shared/CiroTable/CiroTableCell";
import { dateToTimeAgo } from "../../../utils/formatters";
import { PencilIcon, PlayCircleIcon } from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { useNavigate } from "react-router-dom";
import MobileNumbersRunModal from "../MobileNumbersRunModal";
import { useState } from "react";

export const MobileNumbersWorkflowsTable_Workflows = gql`
  query MobileNumbersWorkflowsTable_Workflows {
    organization {
      phoneNumberRequestWorkflows {
        id
        name
        segmentation
        runCount
        lastRunAt
        nextRunTimestamp
      }
    }
  }
`;

interface MobileNumbersWorkflowsTableProps {
  itemsPerPage?: number;
}

const MobileNumbersWorkflowsTable = ({
  itemsPerPage,
}: MobileNumbersWorkflowsTableProps) => {
  const { data: workflowsData, loading: workflowsLoading } = useQuery<
    MobileNumbersWorkflowsTable_WorkflowsQuery,
    MobileNumbersWorkflowsTable_WorkflowsQueryVariables
  >(MobileNumbersWorkflowsTable_Workflows);
  const navigate = useNavigate();

  const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(
    null,
  );
  const [currentPage, setCurrentPage] = useState<number>(1);

  if (workflowsLoading) {
    return <SkeletonLoading numSkeletons={25} skeletonHeight={"3rem"} />;
  }

  const workflows =
    workflowsData?.organization?.phoneNumberRequestWorkflows || [];

  if (workflows.length === 0) {
    return (
      <div className={classNames(
        "text-gray-500",
        "text-sm",
        "text-center",
        "py-8"
      )}>
        Nothing here yet. Try creating a workflow and running it.
      </div>
    );
  }

  let paginatedWorkflows = workflows;
  let totalPages = 1;

  if (itemsPerPage) {
    totalPages = Math.ceil(workflows.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    paginatedWorkflows = workflows.slice(startIndex, startIndex + itemsPerPage);
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const headers = [
    <CiroTableHeader key="name" isFirst={true}>
      Name
    </CiroTableHeader>,
    <CiroTableHeader key="runs">Runs</CiroTableHeader>,
    <CiroTableHeader key="last-run-at">Last run</CiroTableHeader>,
    <CiroTableHeader key="next-run-at">Next run</CiroTableHeader>,
    <CiroTableHeader
      isLast={true}
      align={CiroTableHeaderAlignEnum.RIGHT}
      key="manage"
    >
      Manage
    </CiroTableHeader>,
  ];

  return (
    <div>
      <CiroTable
        pagination={
          itemsPerPage
            ? {
                currentPage,
                totalPages,
                onPageChange: handlePageChange,
              }
            : undefined
        }
      >
        <thead className={classNames("table-header-group")}>
          <CiroTableRow clickable={false}>{headers}</CiroTableRow>
        </thead>
        <tbody className={classNames("table-row-group")}>
          {paginatedWorkflows.map((workflow, idx) => {
            const isLastRow = idx === paginatedWorkflows.length - 1;

            return (
              <CiroTableRow key={workflow.id} clickable={false}>
                <CiroTableCell isLastRow={isLastRow} lastLeft={isLastRow}>
                  {workflow.name}
                </CiroTableCell>
                <CiroTableCell isLastRow={isLastRow}>
                  {workflow.runCount}
                </CiroTableCell>
                <CiroTableCell isLastRow={isLastRow}>
                  {workflow.lastRunAt ? dateToTimeAgo(workflow.lastRunAt) : "-"}
                </CiroTableCell>
                <CiroTableCell isLastRow={isLastRow}>
                  {workflow.nextRunTimestamp
                    ? dateToTimeAgo(workflow.nextRunTimestamp)
                    : "-"}
                </CiroTableCell>
                <CiroTableCell
                  isLastRow={isLastRow}
                  lastRight={isLastRow}
                  align={CiroTableCellAlignEnum.RIGHT}
                >
                  <div
                    className={classNames(
                      "flex",
                      "gap-2",
                      "justify-end",
                    )}
                  >
                    <CiroButton
                      analyticsField="edit-workflow"
                      analyticsProps={{ workflowId: workflow.id }}
                      style={CiroButtonStyleEnum.UNSTYLED}
                      onClick={() => {
                        navigate(`/enrich-crm/workflow/${workflow.id}`);
                      }}
                    >
                      <PencilIcon className={classNames("h-4")} />
                    </CiroButton>
                    <CiroButton
                      analyticsField="run-workflow"
                      analyticsProps={{ workflowId: workflow.id }}
                      style={CiroButtonStyleEnum.UNSTYLED}
                      onClick={() => {
                        setSelectedWorkflowId(Number(workflow.id));
                      }}
                    >
                      <PlayCircleIcon className={classNames("h-4")} />
                    </CiroButton>
                  </div>
                </CiroTableCell>
              </CiroTableRow>
            );
          })}
        </tbody>
        <MobileNumbersRunModal
          isOpen={selectedWorkflowId !== null}
          onClose={() => setSelectedWorkflowId(null)}
          startingWorkflowId={selectedWorkflowId}
        />
      </CiroTable>
    </div>
  );
};

export default MobileNumbersWorkflowsTable;
