import React from "react";
import classNames from "classnames";

interface ICiroTableRowProps {
  children: any;
  clickable?: boolean;
  sticky?: boolean;
}

const CiroTableRow = ({
  children,
  clickable = true,
  sticky = false,
}: ICiroTableRowProps) => {
  return (
    <tr
      className={classNames("table-row", "bg-white", {
        "cursor-pointer": clickable,
        "hover:bg-neutral-200": clickable,
        sticky: sticky,
        "z-10": sticky,
        "top-0": sticky,
        "opacity-100": sticky,
        "border-b-1": sticky,
        "bg-red-500": sticky,
      })}
    >
      {children}
    </tr>
  );
};

export default CiroTableRow;
