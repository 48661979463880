import classNames from "classnames";
import CiroTitle from "../shared/CiroTitle";
import { CHROME_EXTENSION_URL } from "../home/ChromeExtensionCard";
import LinkedInIcon from "../../assets/img/icons/LinkedInIcon";
import ChromeOneColorIcon from "../../assets/img/icons/ChromeOneColorIcon";
import CiroGetStartedButton from "../shared/CiroGetStarted/CiroGetStartedButton";
import { useContext } from "react";
import AppContext from "../../contexts/AppContext";

const AutomationGetStarted = () => {
  const { isExtensionInstalled } = useContext(AppContext);

  return (
    <div className={classNames("w-full", "pb-6")}>
      <CiroTitle title="Get started" size="small" />
      <div className={classNames("flex", "flex-col", "gap-4", "mt-4")}>
        {!isExtensionInstalled && (
          <div
            className={classNames(
              "bg-white",
              "rounded-lg",
              "p-4",
              "flex",
              "items-center",
              "justify-between",
              "shadow-sm",
            )}
          >
            <div>
              <div className={classNames("font-medium", "text-sm")}>
                1. Install Chrome extension
              </div>
              <div className={classNames("text-gray-600", "text-xs")}>
                Add the Ciro extension to Chrome
              </div>
            </div>
            <CiroGetStartedButton
              text="Install Chrome extension"
              href={CHROME_EXTENSION_URL}
              analyticsField="Install Chrome Extension"
              icon={<ChromeOneColorIcon color="white" transform={1.25} />}
            />
          </div>
        )}
        <div
          className={classNames(
            "bg-white",
            "rounded-lg",
            "p-4",
            "flex",
            "items-center",
            "justify-between",
            "shadow-sm",
            "relative",
          )}
        >
          {!isExtensionInstalled && (
            <div className={classNames(
              "absolute",
              "inset-0",
              "bg-gray-200",
              "rounded-lg",
              "opacity-40",
              "cursor-not-allowed",
              "z-10"
            )} />
          )}
          <div>
            <div className={classNames("font-medium", "text-sm")}>
              {!isExtensionInstalled && "2. "} Search for prospects
            </div>
            <div className={classNames("text-gray-600", "text-xs")}>
              Build a LinkedIn Sales Navigator search
            </div>
          </div>
          <CiroGetStartedButton
            text="Launch Search Builder"
            href={"/autopilot/search-builder"}
            analyticsField="Run your first Autopilot"
            icon={
              <LinkedInIcon
                mini={true}
                customScale={0.75}
                customInsideColor="#EA580C"
                customRectColor="white"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AutomationGetStarted;
