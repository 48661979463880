import { gql, useMutation } from "@apollo/client";
import classNames from "classnames";
import {
  AdminViewOrganizationDetails_OrganizationFragment,
  AdminViewOrganizationDetails_UpdateOrganizationMutation,
  AdminViewOrganizationDetails_UpdateOrganizationMutationVariables,
  CreditPlanEnum,
  Maybe,
  OrganizationSubscriptionTierEnum,
} from "../../../__generated__/graphql";
import CiroTextInput from "../../shared/CiroTextInput";
import NumberFormat from "react-number-format";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { MagnifyingGlassPlusIcon } from "@heroicons/react/20/solid";
import { AdminOrganizationDetails_SelectedOrganization, OrganizationDetailsModalStateEnum } from "./AdminOrganizationDetails";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CiroDropDown from "../../shared/CiroDropdown";
import { useEffect } from "react";
import AdminAddApolloKey from "./AdminAddApolloKey";
import toast from "react-hot-toast";
import { AdminOrganizationDropdown_Organizations } from "../AdminOrganizationDropdown";

const AdminViewOrganizationDetailsSchema = yup.object({
  name: yup.string().required('Must have organization name'),
  creditsPlan: yup.object({
    incrementalCredits: yup
      .number()
      .when(["subscriptionTier", "plan"], ([subscriptionTier, plan], field) => {
        return subscriptionTier === OrganizationSubscriptionTierEnum &&
          plan !== CreditPlanEnum.NonRecurring
          ? field.positive()
          : field;
      }),
    premiumDataUnlockMultiplier: yup.number().min(1),
    plan: yup.string().oneOf(Object.values(CreditPlanEnum)),
  }),
  crmIntegrationEnabled: yup.boolean(),
  subscriptionTier: yup
    .string()
    .oneOf(Object.values(OrganizationSubscriptionTierEnum)),
});

export const AdminViewOrganizationDetails_Organization = gql`
  fragment AdminViewOrganizationDetails_Organization on Organization {
    crm_integration_enabled
    id
    name
    premiumDataUnlockMultiplier
    subscription_tier
    credit_plan {
      incremental_credits
      plan
      balance
    }
    userAccounts {
      id
    }
    customPermissions {
      id
    }
  }
`;

const AdminViewOrganizationDetails_UpdateOrganization = gql`
  mutation AdminViewOrganizationDetails_UpdateOrganization(
    $creditPlan: CreditPlanInput
    $crmIntegrationEnabled: Boolean
    $organizationId: Int!
    $premiumDataUnlockMultiplier: Int!
    $subscriptionTier: OrganizationSubscriptionTierEnum
    $name: String
  ) {
    updateOrganization(
      crmIntegrationEnabled: $crmIntegrationEnabled
      creditPlan: $creditPlan
      organizationId: $organizationId
      premiumDataUnlockMultiplier: $premiumDataUnlockMultiplier
      subscriptionTier: $subscriptionTier
      name: $name
    ) {
      success
    }
  }
`;

interface IAdminViewOrganizationDetailsProps {
  organization?: Maybe<AdminViewOrganizationDetails_OrganizationFragment>;
  setModalState: (v: OrganizationDetailsModalStateEnum) => void;
}

export const CREDIT_PLAN_TO_CYCLE_FORMATTING = {
  [CreditPlanEnum.Annual]: "year",
  [CreditPlanEnum.Monthly]: "month",
  [CreditPlanEnum.Quarterly]: "quarter",
};

const AdminViewOrganizationDetails = ({
  organization,
  setModalState,
}: IAdminViewOrganizationDetailsProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(AdminViewOrganizationDetailsSchema),
    defaultValues: {
      name: organization?.name,
      crmIntegrationEnabled: organization?.crm_integration_enabled,
      subscriptionTier: organization?.subscription_tier,
      creditsPlan: {
        incrementalCredits: 500,
        plan: CreditPlanEnum.Monthly,
      },
      premiumDataUnlockMultiplier: organization?.premiumDataUnlockMultiplier,
    },
  });

  const [updateOrganization, { loading: updateOrgLoading }] = useMutation<
    AdminViewOrganizationDetails_UpdateOrganizationMutation,
    AdminViewOrganizationDetails_UpdateOrganizationMutationVariables
  >(AdminViewOrganizationDetails_UpdateOrganization, {
    refetchQueries: [
      AdminOrganizationDropdown_Organizations,
      AdminOrganizationDetails_SelectedOrganization,
    ],
    onCompleted: () => {
      toast.success("Organization updated successfully");
    },
    onError: () => {
      toast.error("Failed to update organization");
    },
  });

  const onSubmit = async (data: any) => {
    const variables = {
      name: data.name,
      organizationId: Number(organization?.id),
      subscriptionTier: data.subscriptionTier,
      premiumDataUnlockMultiplier: Number(data.premiumDataUnlockMultiplier),
      crmIntegrationEnabled: data.crmIntegrationEnabled,
    } as AdminViewOrganizationDetails_UpdateOrganizationMutationVariables;

    if (
      data.subscriptionTier === OrganizationSubscriptionTierEnum.Professional
    ) {
      variables.creditPlan = {
        incrementalCredits: data.creditsPlan.incrementalCredits,
        plan: data.creditsPlan.plan,
      };
    }

    updateOrganization({
      variables,
    });
  };

  const [creditsCycle, crmIntegrationEnabled, subscriptionTier] = useWatch({
    control,
    name: ["creditsPlan.plan", "crmIntegrationEnabled", "subscriptionTier"],
  });

  useEffect(() => {
    if (organization) {
      resetForm({
        name: organization.name,
        creditsPlan: {
          incrementalCredits:
            organization.credit_plan?.incremental_credits || 500,
          plan: organization.credit_plan?.plan || CreditPlanEnum.Monthly,
        },
        crmIntegrationEnabled: organization.crm_integration_enabled,
        subscriptionTier: organization.subscription_tier,
        premiumDataUnlockMultiplier: organization.premiumDataUnlockMultiplier,
      });
    }
  }, [organization, resetForm]);

  if (!organization) {
    return null;
  }

  return (
    <div className={classNames("overflow-auto", "max-h-[80vh]")}>
      <div className={classNames("flex", "justify-between")}>
        <div className={classNames("font-medium", "text-lg")}>
          {organization.name}
        </div>
      </div>
      <div
        className={classNames(
          "pt-4",
          "grid",
          "grid-cols-1",
          "gap-4",
          "md:grid-cols-2",
        )}
      >
        <CiroTextInput
          label="Organization Name"
          placeholder="Name"
          register={register("name")}
        />
        <CiroDropDown
          placeholder="Subscription Tier"
          label="Subscription Tier"
          value={subscriptionTier}
          options={Object.values(OrganizationSubscriptionTierEnum).map(
            (subTier) => {
              return {
                label: subTier,
                value: subTier,
              };
            },
          )}
          error={errors.subscriptionTier?.message}
          onChange={(v) => {
            setValue("subscriptionTier", v);
          }}
        />
        <CiroDropDown
          placeholder="Allow organization to link CRM"
          label="CRM Integration Enabled"
          value={crmIntegrationEnabled}
          options={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ]}
          error={errors.crmIntegrationEnabled?.message}
          onChange={(v) => {
            setValue("crmIntegrationEnabled", v);
          }}
        />
        <CiroTextInput
          label="Premium Data Credits Multiplier"
          placeholder="Choose a whole number"
          type="number"
          defaultValue={50}
          register={register("premiumDataUnlockMultiplier")}
        />
        <div
          className={classNames([
            "font-medium",
            "pt-4",
            "flex",
            "items-center",
          ])}
        >
          Users
          <CiroButton
            analyticsField="View Custom Filters"
            style={CiroButtonStyleEnum.UNSTYLED}
            onClick={() => {
              setModalState(OrganizationDetailsModalStateEnum.USERS);
            }}
          >
            <MagnifyingGlassPlusIcon
              className={classNames(["ml-2", "h-5 w-5"])}
              aria-hidden="true"
            />
          </CiroButton>
        </div>
        <div className={classNames(["pt-4", "font-medium"])}>
          {organization?.userAccounts.length}
        </div>
        <div
          className={classNames([
            "font-medium",
            "pt-4",
            "flex",
            "items-center",
          ])}
        >
          Custom Permissions
          <CiroButton
            analyticsField="View Custom Filters"
            style={CiroButtonStyleEnum.UNSTYLED}
            onClick={() => {
              setModalState(
                OrganizationDetailsModalStateEnum.CUSTOM_PERMISSIONS,
              );
            }}
          >
            <MagnifyingGlassPlusIcon
              className={classNames(["ml-2", "h-5 w-5"])}
              aria-hidden="true"
            />
          </CiroButton>
        </div>
        <div className={classNames(["pt-4", "font-medium"])}>
          {organization?.customPermissions?.length || 0}
        </div>
        <>
          <div
            className={classNames([
              "font-medium",
              "pt-4",
              "flex",
              "items-center",
            ])}
          >
            Credits Plan
            <CiroButton
              analyticsField="View credits plan details"
              style={CiroButtonStyleEnum.UNSTYLED}
              onClick={() => {
                setModalState(
                  OrganizationDetailsModalStateEnum.CREDIT_PLAN_DETAILS,
                );
              }}
            >
              <MagnifyingGlassPlusIcon
                className={classNames(["ml-2", "h-5 w-5"])}
                aria-hidden="true"
              />
            </CiroButton>
          </div>
          <div
            className={classNames("font-medium", "md:pt-4", {
              "text-rose-500": (organization?.credit_plan?.balance || 0) <= 0,
            })}
          >
            {Boolean(organization?.credit_plan?.balance) && (
              <span>
                <NumberFormat
                  value={organization?.credit_plan?.balance}
                  thousandSeparator={true}
                  displayType="text"
                />
                &nbsp;credits remaining
              </span>
            )}
          </div>
          <CiroDropDown
            placeholder="Select New Credit Frequency"
            label="New Credit Cycle"
            value={creditsCycle}
            options={Object.values(CreditPlanEnum).map((creditPlan) => {
              return {
                label: creditPlan,
                value: creditPlan,
              };
            })}
            error={errors.subscriptionTier?.message}
            onChange={(v) => {
              setValue("creditsPlan.plan", v);
            }}
          />
          {creditsCycle !== CreditPlanEnum.NonRecurring && (
            <CiroTextInput
              label={`Number of credits per ${CREDIT_PLAN_TO_CYCLE_FORMATTING[creditsCycle]}`}
              register={register("creditsPlan.incrementalCredits")}
              error={errors.creditsPlan?.incrementalCredits?.message}
            />
          )}
        </>
      </div>
      <AdminAddApolloKey orgId={organization.id} />
      <div className={classNames("pt-4", "flex", "justify-end")}>
        <CiroButton
          analyticsField="Edit Organization"
          onClick={handleSubmit(onSubmit)}
          disabled={updateOrgLoading}
        >
          Submit
        </CiroButton>
      </div>
    </div>
  );
};

export default AdminViewOrganizationDetails;
