import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import classNames from "classnames";
import { useCallback, useContext, useState } from "react";
import fileDownload from "js-file-download";
import toast from "react-hot-toast";
import AppContext from "../../contexts/AppContext";
import CiroSpinner from "../shared/CiroSpinner";

const MobileNumbersDownloadButton = ({
  ids,
  workflowName,
}: {
  ids: number[];
  workflowName?: string;
}) => {
  const [isExporting, setIsExporting] = useState(false);
  const { accessToken } = useContext(AppContext);

  const downloadPhoneNumbersRequestTransaction = useCallback(async () => {
    try {
      setIsExporting(true);
      const response = await fetch(
        "/export-phone-numbers-request-transaction",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phoneNumberTransactionIds: ids,
            csvTitle: `Ciro Phone Numbers - ${workflowName || "Phone Numbers"}`,
          }),
        },
      );
      const blob = await response.blob();
      fileDownload(
        blob,
        `Ciro Phone Numbers - ${workflowName || "Phone Numbers"}.csv`,
      );
      toast.success("CSV downloaded successfully");
    } catch (error) {
      console.error("Error during CSV download:", error);
      toast.error("Error during CSV download");
    } finally {
      setIsExporting(false);
    }
  }, [ids, accessToken, workflowName]);

  return (
    <CiroButton
      analyticsField="Download Phone Numbers Request Transaction"
      style={CiroButtonStyleEnum.UNSTYLED}
      onClick={downloadPhoneNumbersRequestTransaction}
      disabled={isExporting}
    >
      {isExporting && <CiroSpinner loading={isExporting} />}
      {!isExporting && (
        <ArrowDownTrayIcon className={classNames("w-4", "h-4")} />
      )}
    </CiroButton>
  );
};

export default MobileNumbersDownloadButton;
