import classNames from "classnames";
import CiroCreateableTextInput from "../../../shared/CiroCreateableTextInput";
import CiroFilterModalDropdownSection from "../../../shared/CiroFilter/CiroFilterModalDropdownSection";

const CuratedSearchFilterJobTitleSection = ({
  jobTitleFilter,
  setJobTitleFilter,
  defaultOpen = false,
}: {
  jobTitleFilter: { label: string }[];
  setJobTitleFilter: (v: { label: string }[]) => void;
  defaultOpen?: boolean;
}) => {
  return (
    <CiroFilterModalDropdownSection
      defaultOpen={defaultOpen}
      numFiltersApplied={jobTitleFilter.length}
      previews={jobTitleFilter.map((j) => j.label)}
      sectionTitle={"Job title"}
      filterOptions={[
        {
          label: "Job title includes any of:",
          filter: (
            <CiroCreateableTextInput
              containerWidthClass={classNames("w-full")}
              value={jobTitleFilter.map((j) => j.label)}
              setValue={(v) => setJobTitleFilter(v.map((j) => ({ label: j })))}
            />
          ),
        },
      ]}
    />
  );
};

export default CuratedSearchFilterJobTitleSection;
