import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { useContext, useState } from "react";
import { CiroDropdownButton } from "../../shared/CiroDropdownButton";
import CiroTextArea from "../../shared/CiroTextArea";
import CuratedSearchCard from "../CuratedSearchCard";
import AutopilotCuratedSearchContext, {
  ICuratedSearchPersona,
} from "../../../contexts/AutopilotCuratedSearchContext";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import CuratedSearchPersonaEditName from "./CuratedSearchPersonaEdit/CuratedSearchPersonaEditName";
import CuratedSearchPersonaEditDescription from "./CuratedSearchPersonaEdit/CuratedSearchPersonaEditDescription";
import {
  CuratedSearchPersonaDefault_UpsertPersonaMutation,
  CuratedSearchPersonaDefault_UpsertPersonaMutationVariables,
} from "../../../__generated__/graphql";
import { SparklesIcon } from "@heroicons/react/20/solid";
import CiroPill from "../../shared/CiroPill";

export const CuratedSearchPersonaDefault_Personas = gql`
  fragment CuratedSearchPersonaDefault_Personas on Query {
    personas {
      id
      name
      description
    }
  }
`;

const CuratedSearchPersonaDefault_UpsertPersona = gql`
  mutation CuratedSearchPersonaDefault_UpsertPersona(
    $input: UpsertPersonaInput!
  ) {
    upsertPersona(input: $input) {
      success
      message
      persona {
        id
        name
        description
      }
    }
  }
`;

const CuratedSearchPersonaDefault = ({
  selectedPersona,
  setInitialAIPrompt,
  sourceIsCiro,
}: {
  selectedPersona: ICuratedSearchPersona;
  setInitialAIPrompt: (initialAIPrompt: string) => void;
  sourceIsCiro: boolean;
}) => {
  const {
    generateFilters,
    setMayNeedToRegenerateFilters,
    setSelectedPersona,
    setAllPersonas,
    allPersonas,
    setShowFiltersCard,
    showFiltersCard,
  } = useContext(AutopilotCuratedSearchContext);
  const [chatGptEditText, setChatGptEditText] = useState("");
  const [upsertPersona] = useMutation<
    CuratedSearchPersonaDefault_UpsertPersonaMutation,
    CuratedSearchPersonaDefault_UpsertPersonaMutationVariables
  >(CuratedSearchPersonaDefault_UpsertPersona);

  const client = useApolloClient();

  const onSave = () => {
    if (!selectedPersona?.name || !selectedPersona?.description) {
      return;
    }

    upsertPersona({
      variables: {
        input: {
          name: selectedPersona.name,
          description: selectedPersona.description,
          id: selectedPersona?.id,
        },
      },
      onCompleted: (data) => {
        if (data.upsertPersona.success) {
          client.cache.modify({
            id: client.cache.identify({
              __typename: "Persona",
              id: data.upsertPersona.persona?.id,
            }),
            fields: {
              name: () => data.upsertPersona.persona?.name,
              description: () => data.upsertPersona.persona?.description,
            },
          });
          const personaId = data.upsertPersona.persona?.id;
          if (!selectedPersona.id) {
            setSelectedPersona({
              ...selectedPersona,
              id: personaId,
            });
          }
          setAllPersonas([
            data.upsertPersona.persona!,
            ...(allPersonas || []).filter((p) => p.id !== personaId),
          ]);
        }
      },
    });
  };

  return (
    <CuratedSearchCard
      header={
        <div
          className={classNames("flex", "flex-row", "gap-2", "items-center")}
        >
          <CuratedSearchPersonaEditName
            onSave={onSave}
            persona={
              selectedPersona || {
                id: undefined,
                name: "",
                description: "",
              }
            }
            setPersona={setSelectedPersona}
          />
          {sourceIsCiro && (
            <CiroPill background="bg-orange-100" fontColor="text-orange-500">
              <SparklesIcon
                className={classNames("w-4", "h-4", "text-orange-500", "mr-1")}
              />
              Generated by Ciro AI
            </CiroPill>
          )}
        </div>
      }
      editButton={null}
      footer={
        <div className={classNames("flex", "justify-between", "gap-2")}>
          <CiroDropdownButton
            verticalMenuPosition="top"
            menuPosition="right"
            disabled={!selectedPersona?.id}
            hideChevron={true}
            label={
              <div className={classNames("flex", "gap-2", "pr-2")}>
                <SparklesIcon
                  className={classNames("w-4", "h-4", "text-orange-500")}
                />
                <span>Edit</span>
              </div>
            }
            isPopover={true}
          >
            <div
              className={classNames("p-4", "w-96", "flex", "flex-col", "gap-4")}
            >
              <CiroTextArea
                label="Please tell us how we can improve this persona in your own words"
                onChange={(e) => {
                  setChatGptEditText(e.target.value);
                }}
                value={chatGptEditText}
              />
              <div className={classNames("flex", "justify-end", "gap-2")}>
                <CiroButton
                  analyticsField="update persona with AI"
                  style={CiroButtonStyleEnum.LOUD}
                  onClick={() => {
                    setInitialAIPrompt(chatGptEditText);
                  }}
                  disabled={!chatGptEditText.trim()}
                >
                  Update
                </CiroButton>
              </div>
            </div>
          </CiroDropdownButton>
          <CiroButton
            analyticsField={"Apply persona updates to filters"}
            style={CiroButtonStyleEnum.LOUD}
            disabled={Boolean(!selectedPersona?.id)}
            onClick={() => {
              setMayNeedToRegenerateFilters(false);
              setShowFiltersCard(true);
              generateFilters({
                variables: {
                  input: {
                    name: selectedPersona?.name || "",
                    description: selectedPersona?.description || "",
                  },
                },
              });
            }}
          >
            {Boolean(!selectedPersona?.id || !showFiltersCard)
              ? "Generate filters"
              : "Regenerate filters"}
          </CiroButton>
        </div>
      }
    >
      <div
        className={classNames("flex", "flex-col", {
          "gap-4": !selectedPersona?.id,
        })}
      >
        <CuratedSearchPersonaEditDescription
          onSave={onSave}
          persona={selectedPersona}
          setPersona={setSelectedPersona}
        />
      </div>
    </CuratedSearchCard>
  );
};

export default CuratedSearchPersonaDefault;
