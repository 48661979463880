import classNames from "classnames";
import CiroCard from "../shared/CiroCard";

const CuratedSearchCard = ({
  header,
  editButton,
  children,
  footer,
  padBody = true,
}: {
  header: any;
  editButton: any;
  children: any;
  footer?: any;
  padBody?: boolean;
}) => {
  return (
    <CiroCard size="small">
      <div className={classNames("flex", "flex-col", "gap-4")}>
        <div
          className={classNames(
            "flex",
            "flex-row",
            "justify-between",
            "items-center",
            "gap-4",
            "h-6",
          )}
        >
          <div className={classNames("font-semibold", "w-full")}>{header}</div>
          {editButton && (
            <div
              className={classNames(
                "flex",
                "flex-row",
                "gap-2",
                "flex-shrink-0",
              )}
            >
              {editButton}
            </div>
          )}
        </div>
        <div
          className={classNames("border-t", "border-gray-200", "-mx-4", {
            "px-4": padBody,
          })}
        >
          <div className={classNames("py-4", "max-h-[60vh]", "overflow-auto")}>
            {children}
          </div>
        </div>
      </div>
      {footer && (
        <div
          className={classNames("border-t", "border-gray-200", "-mx-4", "px-4")}
        >
          <div className={classNames("pt-4")}>{footer}</div>
        </div>
      )}
    </CiroCard>
  );
};

export default CuratedSearchCard;
