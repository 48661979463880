import { useContext, useState } from "react";
import PersonasContext from "../../../contexts/PersonasContext";
import CiroDropDown from "../../shared/CiroDropdown";
import classNames from "classnames";

interface IContactListPersonaDropdownProps {
  personaId: number | null;
  onChange: (personaId: number | null) => void;
  borderless?: boolean;
}

const ContactListPersonaDropdown = ({
  personaId,
  onChange,
  borderless = false,
}: IContactListPersonaDropdownProps) => {
  const [currentPersonaId, setCurrentPersonaId] = useState(personaId);
  const personas = useContext(PersonasContext);

  return (
    <div
      className={classNames({
        "-ml-2": borderless,
      })}
    >
      <CiroDropDown
        borderless={borderless}
        className="w-32"
        isClearable={false}
        isMulti={false}
        value={currentPersonaId}
        onChange={(value) => {
          setCurrentPersonaId(value);
          onChange(value);
        }}
        options={[
          ...personas.map((persona) => ({
            label: persona.name,
            value: persona.id,
          })),
          {
            label: "No persona assigned",
            value: null,
          },
        ]}
        menuPortalTarget={document.body}
      />
    </div>
  );
};

export default ContactListPersonaDropdown;
