import classNames from "classnames";
import pluralize from "pluralize";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import CiroModal from "../../shared/CiroModal";
import NumberFormat from "react-number-format";
import CiroSpinner from "../../shared/CiroSpinner";
import { gql, useQuery } from "@apollo/client";
import {
  AutomationListFindNumbersModal_CurrentBalanceQuery,
  AutomationListFindNumbersModal_CurrentBalanceQueryVariables,
  AutomationListFindNumbersModal_OrgContactsQuery,
  AutomationListFindNumbersModal_OrgContactsQueryVariables,
} from "../../../__generated__/graphql";
import { useState } from "react";
import CiroSwitch from "../../shared/CiroSwitch";
import { COST_PER_PHONE_NUMBER } from "../../../utils/consts";

const AutomationListFindNumbersModal_CurrentBalance = gql`
  query AutomationListFindNumbersModal_CurrentBalance {
    userAccount {
      org {
        credit_plan {
          balance
        }
      }
    }
  }
`;

const AutomationListFindNumbersModal_OrgContacts = gql`
  query AutomationListFindNumbersModal_OrgContacts($ids: [Int!]!) {
    orgContactsFromIds(ids: $ids) {
      id
      orgPhoneNumbers {
        id
      }
    }
  }
`;

interface IFindNumbersModal {
  contactIds: number[];
  isOpen: boolean;
  onClose: () => void;
  onFindNumbers: (ids: number[]) => void;
}

const FindNumbersModal = ({
  contactIds,
  isOpen,
  onClose,
  onFindNumbers,
}: IFindNumbersModal) => {
  const [skipContactsWithExistingNumbers, setSkipContactsWithExistingNumbers] = useState(true);
  
  const { data: balanceData, loading: balanceLoading } = useQuery<
    AutomationListFindNumbersModal_CurrentBalanceQuery,
    AutomationListFindNumbersModal_CurrentBalanceQueryVariables
  >(AutomationListFindNumbersModal_CurrentBalance, {
    fetchPolicy: "network-only",
  });

  const { data: orgContactsData, loading: orgContactsLoading } = useQuery<
    AutomationListFindNumbersModal_OrgContactsQuery,
    AutomationListFindNumbersModal_OrgContactsQueryVariables
  >(AutomationListFindNumbersModal_OrgContacts, {
    fetchPolicy: "network-only",
    variables: { ids: contactIds },
    skip: !contactIds.length,
  });

  const currentBalance =
    balanceData?.userAccount?.org?.credit_plan?.balance || 0;

  const orgContacts = orgContactsData?.orgContactsFromIds || [];
  const isLoading = balanceLoading || orgContactsLoading;

  const contactsWithoutNumbers = orgContacts.filter(
    contact => !contact.orgPhoneNumbers?.length
  );
  
  const effectiveContactIds = skipContactsWithExistingNumbers 
    ? contactsWithoutNumbers.filter(contact => Boolean(contact.id)).map(contact => contact.id as number)
    : contactIds as number[];

  const effectiveCreditsNeeded = skipContactsWithExistingNumbers
    ? contactsWithoutNumbers.length * COST_PER_PHONE_NUMBER
    : orgContacts.length * COST_PER_PHONE_NUMBER;

  return (
    <CiroModal isOpen={isOpen} onClose={onClose}>
      {isLoading ? (
        <div className={classNames("flex", "justify-center", "py-10")}>
          <CiroSpinner loading={true} />
        </div>
      ) : (
        <div>
          <div className={classNames("text-lg", "font-medium", "pb-4")}>
            Find numbers for {effectiveContactIds.length}{" "}
            {pluralize("contact", effectiveContactIds.length)}
          </div>
          <div className={classNames("pb-4")}>
            {orgContacts.some(contact => contact.orgPhoneNumbers?.length > 0) && (
              <div className={classNames("flex", "items-center", "gap-2", "mb-4")}>
                <CiroSwitch
                  checked={skipContactsWithExistingNumbers}
                  onChange={(checked) => setSkipContactsWithExistingNumbers(checked)}
                />
                <span className="text-sm">Skip contacts that already have phone numbers</span>
              </div>
            )}
            <div className={classNames("font-medium")}>
              Total credits needed:{" "}
              <NumberFormat
                value={effectiveCreditsNeeded}
                thousandSeparator=","
                displayType="text"
              />
            </div>
            {effectiveCreditsNeeded > currentBalance && (
              <div className={classNames("text-xs", "text-red-500", "mt-2")}>
                Not enough credits to find phone numbers
              </div>
            )}
          </div>
          <div className={classNames("flex", "justify-end", "gap-2")}>
            <CiroButton
              analyticsField={"Cancel find numbers"}
              onClick={onClose}
              style={CiroButtonStyleEnum.INVERTED_LOUD}
            >
              Cancel
            </CiroButton>
            <CiroButton
              analyticsField={"Run find numbers for contact list"}
              disabled={effectiveCreditsNeeded > currentBalance || effectiveContactIds.length === 0}
              onClick={() => {
                onClose();
                onFindNumbers(effectiveContactIds);
              }}
              style={CiroButtonStyleEnum.LOUD}
            >
              Find numbers
            </CiroButton>
          </div>
        </div>
      )}
    </CiroModal>
  );
};

export default FindNumbersModal;
