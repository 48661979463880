import { createContext } from "react";
import {
  AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersonaQuery,
  AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersonaQueryVariables,
  EnumPersonaSource,
} from "../__generated__/graphql";
import { LazyQueryExecFunction } from "@apollo/client";

export interface ICuratedSearchPersona {
  id: number | undefined;
  name: string;
  description: string;
  source?: EnumPersonaSource | null;
}

const AutopilotCuratedSearchContext = createContext({
  filtersLoading: false,
  generateFilters: (
    variables: AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersonaQueryVariables,
  ) => {},
  allPersonas: null,
  setAllPersonas: (personas: ICuratedSearchPersona[]) => {
    return;
  },
  selectedPersona: {
    id: undefined,
    name: "",
    description: "",
    source: null,
  },
  setSelectedPersona: (persona: ICuratedSearchPersona) => {
    return;
  },
  mayNeedToRegenerateFilters: false,
  setMayNeedToRegenerateFilters: (value: boolean) => {
    return;
  },
  setShowFiltersCard: (value: boolean) => {
    return;
  },
  showFiltersCard: false,
} as {
  filtersLoading: boolean;
  generateFilters: LazyQueryExecFunction<
    AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersonaQuery,
    AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersonaQueryVariables
  >;
  allPersonas: ICuratedSearchPersona[] | null;
  setAllPersonas: (personas: ICuratedSearchPersona[]) => void;
  selectedPersona: ICuratedSearchPersona;
  setSelectedPersona: (persona: ICuratedSearchPersona | null) => void;
  mayNeedToRegenerateFilters: boolean;
  setMayNeedToRegenerateFilters: (value: boolean) => void;
  setShowFiltersCard: (value: boolean) => void;
  showFiltersCard: boolean;
});

export default AutopilotCuratedSearchContext;
