import { LinkedinSalesNavigatorFilters } from "../../__generated__/graphql";

const hasFiltersApplied = (filters: LinkedinSalesNavigatorFilters | null) => {
  if (!filters) {
    return false;
  }

  return (
    (filters.jobTitles?.length ?? 0) > 0 ||
    (filters.companyHeadCounts?.length ?? 0) > 0 ||
    (filters.companyNames?.length ?? 0) > 0 ||
    (filters.industries?.length ?? 0) > 0 ||
    (filters.geographies?.length ?? 0) > 0 ||
    (filters.companyHQLocations?.length ?? 0) > 0
  );
};

export { hasFiltersApplied };
