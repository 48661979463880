import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

interface ICiroTooltipContainerProps {
  children: any;
  disabled?: boolean;
  visible?: boolean;
  tooltip: any;
  className?: string;
  placement?: "left" | "top" | "bottom" | "left-start"; // https://atomiks.github.io/tippyjs/v6/all-props/#placement
  customTheme?: string;
  allowHTML?: boolean;
}

const CiroTooltipContainer = ({
  children,
  disabled,
  visible,
  tooltip,
  className,
  placement = "top",
  customTheme = "",
  allowHTML = true,
}: ICiroTooltipContainerProps) => {
  if (!tooltip) {
    return children;
  }
  return (
    <Tippy
      theme={customTheme}
      content={tooltip}
      disabled={disabled}
      visible={visible}
      placement={placement}
      allowHTML={allowHTML}
      interactive={true}
    >
      <span className={className}>{children}</span>
    </Tippy>
  );
};

export default CiroTooltipContainer;
