import { useEffect, useState } from "react";
import LargeScreenNavigation from "./LargeScreenNavigation";
import SmallScreenNavigation from "./SmallScreenNavigation";
import classNames from "classnames";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  IntegrationEnum,
  NavigationContainer_PermissionsQuery,
  NavigationContainer_PermissionsQueryVariables,
  Navigation_StopImpersonatingMutation,
  Navigation_StopImpersonatingMutationVariables,
} from "../../__generated__/graphql";
import { FULL_SCREEN_CONTAINER_CLASSES } from "../shared/CiroContainer";
import NavigationContext from "../../contexts/NavigationContext";
import { usePostHog } from "posthog-js/react";
import { IntegrationModal } from "../manageIntegration/integrationModal/IntegrationModal";
import { IntegrationModalPhases } from "../manageIntegration/manageIntegrationButton/ManageIntegrationsButton";

interface INavigationContainerProps {
  children: any;
  isLoggedIn: boolean;
}

export interface ISharedNavigationProps {
  isLoggedIn: boolean;
  loading: boolean;
  navData?: NavigationContainer_PermissionsQuery;
  stopImpersonating: () => void;
}

// Fragments ignored to ensure data used in both containers is identical,
// one navbar is not changed without accounting for the other.
const NavigationContainer_Permissions = gql`
  query NavigationContainer_Permissions {
    permissions {
      hasAdminPermissions
      canViewAccountSearch
      canViewCiroNumbers
      canViewCollections
      canViewEnrichments
    }
    userAccount {
      id
      impersonating {
        email
      }
      org {
        credit_plan {
          balance
        }
        subscription_tier
      }
    }
  }
`;

const Navigation_StopImpersonating = gql`
  mutation Navigation_StopImpersonating {
    stopImpersonating {
      success
    }
  }
`;

declare global {
  interface Window {
    Atlas: any;
  }
}

const NavigationContainer = ({
  children,
  isLoggedIn,
}: INavigationContainerProps) => {
  const { user } = useAuth0();
  const posthog = usePostHog();
  const [integrationModalPhase, setIntegrationModalPhase] = useState(
    IntegrationModalPhases.GET_STARTED,
  );
  const [availableIntegrations, setAvailableIntegrations] = useState<IntegrationEnum[]>(
    Object.values(IntegrationEnum)
  );
  const resetAvailableIntegrations = () => {
    setAvailableIntegrations(Object.values(IntegrationEnum));
  };
  const {
    data,
    loading,
    refetch: refreshNavData,
  } = useQuery<
    NavigationContainer_PermissionsQuery,
    NavigationContainer_PermissionsQueryVariables
  >(NavigationContainer_Permissions, {
    skip: !isLoggedIn,
  });

  const [stopImpersonating, { data: stopImpersonatingData }] = useMutation<
    Navigation_StopImpersonatingMutation,
    Navigation_StopImpersonatingMutationVariables
  >(Navigation_StopImpersonating);

  useEffect(() => {
    if (
      stopImpersonatingData &&
      stopImpersonatingData.stopImpersonating.success
    ) {
      window.location.reload();
    }
    if (data) {
      posthog.identify(user?.sub, {
        subscriptionTier: data?.userAccount?.org?.subscription_tier,
      });
      window.Atlas?.call("identify", {
        userId: user?.sub,
        name: user?.name,
        email: user?.email,
      });
    }
  });

  return (
    <NavigationContext.Provider
      value={{
        refreshNavData,
        integrationModalPhase,
        setIntegrationModalPhase,
        availableIntegrations,
        setAvailableIntegrations,
        resetAvailableIntegrations,
      }}
    >
      <div>
        <SmallScreenNavigation
          isLoggedIn={isLoggedIn}
          stopImpersonating={stopImpersonating}
          loading={loading}
          navData={data}
        />
        <div className={classNames("flex", "relative")}>
          <LargeScreenNavigation
            isLoggedIn={isLoggedIn}
            stopImpersonating={stopImpersonating}
            loading={loading}
            navData={data}
          />
          <div
            className={classNames(
              "text-center",
              "w-screen",
              FULL_SCREEN_CONTAINER_CLASSES,
              {
                "lg:ml-72": isLoggedIn,
                "lg:w-screen-minus-72": isLoggedIn,
              },
            )}
          >
            <div className={classNames("min-h-full")}>{children}</div>
          </div>
        </div>
      </div>
      {isLoggedIn &&
        user &&
        integrationModalPhase !== IntegrationModalPhases.GET_STARTED && (
          <IntegrationModal />
        )}
    </NavigationContext.Provider>
  );
};

export default NavigationContainer;
