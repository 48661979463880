import { useState } from "react";
import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import CopyIcon from "../../../../assets/img/icons/CopyIcon";
import CheckIcon from "../../../../assets/img/icons/CheckIcon";

interface ICopyButton {
  analyticsField: string;
  textToCopy: string;
}

const CopyButton = ({ analyticsField, textToCopy }: ICopyButton) => {
  const [copied, setCopied] = useState(false);

  return (
    <CiroButton
      analyticsField={analyticsField}
      analyticsProps={{ text_copied: textToCopy }}
      style={CiroButtonStyleEnum.UNSTYLED}
      customClassName={classNames("hover:opacity-70", "scale-75", "p-0")}
      disabled={copied}
      onClick={() => {
        navigator.clipboard.writeText(textToCopy);
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      }}
    >
      {copied ? <CheckIcon /> : <CopyIcon />}
    </CiroButton>
  );
};

export default CopyButton;
