import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useState } from "react";
import logo from "./assets/ciro_logo_transparent.png";

import { analytics } from "./utils/vendors";
import AppContext from "./contexts/AppContext";
import classNames from "classnames";
import CiroButton, {
  CiroButtonStyleEnum,
} from "./components/shared/CiroButton";
import CiroContainer from "./components/shared/CiroContainer";

interface IAuthProps {
  error: any;
  getTokenAndTryAgain: () => void;
}

const Auth = ({ error, getTokenAndTryAgain }: IAuthProps) => {
  const { user } = useContext(AppContext);
  const { loginWithRedirect, logout } = useAuth0();
  const [verificationEmailError, setVerificationEmailError] =
    useState<boolean>(false);

  const login = () =>
    loginWithRedirect({
      appState: {
        target: window.location.href,
      },
    });
  const signUp = () =>
    loginWithRedirect({
      screen_hint: "signup",
    });
  const sendNewVerificationEmail = async (auth0_id: string) => {
    try {
      const response = await fetch("/auth0/verify-email", {
        method: "POST",
        body: JSON.stringify({ auth0_id: auth0_id }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        analytics.track("Sent new verification email");
      } else {
        throw new Error("Failed to send new verification email");
      }
    } catch (error) {
      console.error("Error during new verification email:", error);
      setVerificationEmailError(true);
    }
  };

  if (error.error === "login_required") {
    analytics.track("Viewed login page");
    return (
      <div
        className={classNames(
          "h-screen",
          "flex",
          "flex-col",
          "flex-wrap",
          "justify-center",
          "content-center",
        )}
      >
        <div className={classNames("text-slate-600", "hero my-5")}>
          <img
            className="inline mb-8"
            src={logo}
            alt="Ciro logo"
            width="180"
          />
          <p className={classNames("text-xl", "pb-4")}>
            AI-powered search & enrichment for B2B sales
          </p>
          <div
            className={classNames(
              "flex",
              "font-medium",
              "justify-center",
              "pb-4",
            )}
          >
            <CiroButton
              customClassName="test-login-button"
              style={CiroButtonStyleEnum.LOUD}
              onClick={login}
              analyticsField="Log in"
            >
              Log In
            </CiroButton>
          </div>
          Need an account?{" "}
          <span
            className={classNames(
              "text-orange-500",
              "hover:text-orange-600",
              "font-medium",
              "hover:underline",
              "visited:text-purple-600",
              "cursor-pointer",
            )}
            onClick={signUp}
          >
            Sign up with work email
          </span>
          .
        </div>
      </div>
    );
  }
  if (error.error === "consent_required") {
    return (
      <div className="m-12">
        <CiroButton
          onClick={getTokenAndTryAgain}
          analyticsField="Provide consent for access"
        >
          Please provide your consent to access Ciro.
        </CiroButton>
      </div>
    );
  }
  analytics.track("User denied access", {
    user: user?.email,
  });
  if (error.message.includes("User is unverified")) {
    return (
      <div
        className={classNames(
          "m-12",
          "items-center",
          "w-2/3",
        )}
      >
        <img
          className="inline mb-8"
          src={logo}
          alt="Ciro logo"
          width="180"
        />
        <div className={classNames("items-center", "mr-30")}>
          <p
            className={classNames(
              "text-xl",
              "font-light",
              "mt-8",
            )}
          >
            A verification email has been sent to you. If you do not see one,
            please check your spam folder
          </p>
          <div
            className={classNames(
              "flex",
              "flex-col",
              "pt-4",
              "items-center",
            )}
          >
            <CiroButton
              onClick={() => {
                setVerificationEmailError(false);
                sendNewVerificationEmail(error.message.split(" ")[3]);
              }}
              analyticsField="Send new verification email"
            >
              Resend verification email
            </CiroButton>
            {verificationEmailError && (
              <p className="text-red-500">
                Error sending new verification email.
              </p>
            )}
          </div>
          <p
            className={classNames(
              "text-m",
              "font-light",
              "pt-4",
            )}
          >
            If you believe this is in error, please contact{" "}
            <a href="mailto:help@ciro.io" className="text-orange-600">
              help@ciro.io
            </a>
            .
          </p>
          <div
            className={classNames(
              "flex",
              "flex-col",
              "items-center",
              "pt-4",
            )}
          >
            <CiroButton
              onClick={() => logout({ returnTo: window.location.origin })}
              analyticsField="Log out and try again"
            >
              Log out and try again
            </CiroButton>
          </div>
        </div>
      </div>
    );
  }
  const errorMessage =
    error.message === "no_signups_from_free_domain"
      ? "To prevent abuse, free emails such as, but not limited to, Hotmail, Gmail, Yahoo and AOL are not allowed."
      : "You don't have access to this application.";
  return (
    <CiroContainer>
      <div className="flex col justify-center">
        <div className="text-center hero mt-5 col items-center">
          <p className="text-xl font-light mt-8">
            {errorMessage}
          </p>
          <p className="text-xl font-light">
            If you believe this is in error, please contact help@ciro.io.
          </p>
          <p
            className={classNames(
              "text-xl",
              "font-light",
              "flex",
              "flex-col",
              "items-center",
              "pt-4",
            )}
          >
            <CiroButton
              onClick={() => logout({ returnTo: window.location.origin })}
              analyticsField="Log out and try again"
            >
              Log out and try again
            </CiroButton>
          </p>
        </div>
      </div>
    </CiroContainer>
  );
};

export default Auth;
