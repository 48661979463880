import { useCallback, useState } from "react";
import classNames from "classnames";
import { PhoneIcon } from "@heroicons/react/24/outline";
import {
  gql,
} from "@apollo/client";
import {
  AutomationListFindNumbersButton_ContactListFragment,
} from "../../../__generated__/graphql";
import CiroTooltipContainer from "../../shared/CiroTooltipContainer";
import CiroButton from "../../shared/CiroButton";
import CiroSpinner from "../../shared/CiroSpinner";
import FindNumbersModal from "./AutomationListFindNumbersModal";
import useContactListFindPhoneNumbers from "../../../reactHooks/automationList/useContactListFindPhoneNumbers";

export const AutomationListFindNumbersButton_ContactList = gql`
  fragment AutomationListFindNumbersButton_ContactList on ContactList {
    id
    name
    mostRecentPhoneNumberRequestTransaction {
      id
      finished_at
      error
    }
  }
`;

const AutomationListFindNumbersButton = ({
  contactList,
  contactIds,
}: {
  contactList: AutomationListFindNumbersButton_ContactListFragment | null;
  contactIds: number[];
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { 
    isCreatingPhoneTransaction, 
    isPolling,
    handleFindPhoneNumbers 
  } = useContactListFindPhoneNumbers(contactList?.id ?? null);

  const isLoading = isCreatingPhoneTransaction || isPolling;

  const handleFindNumbers = useCallback(
    async (ids: number[]) => {
      await handleFindPhoneNumbers(ids);
    },
    [handleFindPhoneNumbers]
  );

  let tooltipMessage = "Find phone numbers";
  if (isLoading) {
    tooltipMessage = "Running find phone numbers. This may take a few minutes";
  } else if (contactIds.length === 0) {
    tooltipMessage = "No contacts selected";
  }

  return (
    <>
      <CiroTooltipContainer
        tooltip={tooltipMessage}
        disabled={
          !isLoading && !contactList?.mostRecentPhoneNumberRequestTransaction
        }
      >
        <CiroButton
          onClick={() => {
            setIsModalOpen(true);
          }}
          disabled={isLoading || contactIds.length === 0}
          analyticsField={"Start find numbers for contact list"}
          analyticsProps={{
            contactListId: contactList?.id,
          }}
          customPaddingY="py-3"
        >
          <div className={classNames("flex", "items-center")}>
            {isLoading ? (
              <CiroSpinner loading={true} />
            ) : (
              <div className={classNames("flex", "flex-row", "items-center", "gap-1")}>
                <PhoneIcon className={classNames("w-5", "h-5")} />
                <div>Find numbers</div>
              </div>
            )}
          </div>
        </CiroButton>
      </CiroTooltipContainer>
      <FindNumbersModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        contactIds={contactIds}
        onFindNumbers={handleFindNumbers}
      />
    </>
  );
};

export default AutomationListFindNumbersButton;
