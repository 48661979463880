import classNames from "classnames";
import { ReactNode } from "react";
import CiroButton from "../shared/CiroButton";
import CiroTitle from "../shared/CiroTitle";

interface IAutomationHomeTableSection {
  buttonHandler: () => void;
  objectType: "list" | "persona" | "search";
  table?: ReactNode;
  title: string;
  noObjectsMessage: string;
}

const AutomationHomeTableSection = ({
  buttonHandler,
  objectType,
  table,
  title,
  noObjectsMessage,
}: IAutomationHomeTableSection) => {
  return (
    <div className={classNames("mb-4")}>
      <div
        className={classNames(
          "flex",
          "justify-between",
          "items-center",
          "w-full",
          "items-start",
          "pb-4",
        )}
      >
        <CiroTitle title={title} size="small" />
        <CiroButton
          analyticsField={`Create new ${objectType}`}
          onClick={buttonHandler}
        >
          + New {objectType}
        </CiroButton>
      </div>
      <div
        className={classNames(
          "flex",
          "justify-between",
          "items-center",
          "flex-col",
          "w-full",
          "mb-4",
        )}
      >
        {table || (
          <div
            className={classNames(
              "text-start",
              "text-gray-500",
              "text-sm",
              "w-full",
            )}
          >
            {noObjectsMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default AutomationHomeTableSection;
