import React, { useContext } from "react";
import { AutomationListStateEnum } from "../../../routes/automationList/AutomationList";
import {
  CrmObjectType,
  InitiatePushToCrmButton_PushToCrmTransactionFragment,
  MergeIntegrationEnum,
} from "../../../__generated__/graphql";
import {
  CiroDropdownButton,
  CiroDropdownButtonItem,
} from "../../shared/CiroDropdownButton";
import IntegrationTypeContext from "../../../contexts/IntegrationTypeContext";
import { gql } from "@apollo/client";
import CiroButton from "../../shared/CiroButton";
import CiroTooltipContainer from "../../shared/CiroTooltipContainer";
import SalesforceIcon from "../../../assets/img/icons/SalesforceIcon";
import HubspotIcon from "../../../assets/img/icons/HubspotIcon";
import classNames from "classnames";

export const InitiatePushToCrmButton_PushToCrmTransaction = gql`
  fragment InitiatePushToCrmButton_PushToCrmTransaction on PushToCrmTransaction {
    id
    created_at
    finished_at
    error
  }
`;

const getTooltipMessage = ({
  hasNoContacts,
  isCurrentlyRunning,
  tooManyContacts,
  blockHubSpot,
}: {
  hasNoContacts: boolean;
  isCurrentlyRunning: boolean;
  tooManyContacts: boolean;
  blockHubSpot: boolean;
}) => {
  if (tooManyContacts) {
    return "You can only push up to 100 contacts to HubSpot at a time";
  }
  if (hasNoContacts) {
    return "You must select at least one contact to push to HubSpot";
  }
  if (isCurrentlyRunning) {
    return "You are already pushing to HubSpot";
  }
  if (blockHubSpot) {
    return "All selected contacts must have emails to export to HubSpot";
  }
  return undefined;
};

const InitiatePushToCrmButton = ({
  allSelectedContactsHaveEmails,
  contactIds,
  setSelectedCrmObject,
  setViewState,
  pushToCrmTransaction,
}: {
  allSelectedContactsHaveEmails?: boolean;
  contactIds: number[];
  setSelectedCrmObject: (crmObject: CrmObjectType) => void;
  setViewState: (viewState: AutomationListStateEnum) => void;
  pushToCrmTransaction: InitiatePushToCrmButton_PushToCrmTransactionFragment | null;
}) => {
  const { integrationType } = useContext(IntegrationTypeContext);
  const isCurrentlyRunning = Boolean(
    pushToCrmTransaction && pushToCrmTransaction.finished_at == null,
  );
  const hasNoContacts = contactIds.length === 0;
  const tooManyContacts = contactIds.length > 100;

  const blockHubSpot =
    !allSelectedContactsHaveEmails &&
    integrationType === MergeIntegrationEnum.HubSpot;

  const blockPushToCrm =
    hasNoContacts || isCurrentlyRunning || tooManyContacts || blockHubSpot;
  const tooltipMessage = getTooltipMessage({
    hasNoContacts,
    isCurrentlyRunning,
    tooManyContacts,
    blockHubSpot,
  });

  if (integrationType === MergeIntegrationEnum.HubSpot) {
    return (
      <CiroTooltipContainer disabled={!blockPushToCrm} tooltip={tooltipMessage}>
        <CiroButton
          disabled={blockPushToCrm}
          analyticsField="Push to HubSpot"
          onClick={() => {
            setSelectedCrmObject(CrmObjectType.Contact);
            setViewState(AutomationListStateEnum.ReviewDuplicates);
          }}
          customPaddingY={classNames("py-3")}
        >
          <div className={classNames("flex", "items-center")}>
            <HubspotIcon />
            <span className={classNames("ml-2")}>Push</span>
          </div>
        </CiroButton>
      </CiroTooltipContainer>
    );
  }

  if (integrationType === MergeIntegrationEnum.Salesforce) {
    return (
      <CiroTooltipContainer disabled={!blockPushToCrm} tooltip={tooltipMessage}>
        <CiroDropdownButton
          label={
            <div className={classNames("flex", "items-center")}>
              <SalesforceIcon />
              <span className={classNames("ml-2")}>Push</span>
            </div>
          }
          hideChevronOnDisabled={false}
          disabled={blockPushToCrm}
          customPadding={classNames("pl-4", "pr-2", "py-3")}
        >
          <CiroDropdownButtonItem
            analyticsField="Push to CRM Contacts"
            disabled={blockPushToCrm}
            onClick={() => {
              setSelectedCrmObject(CrmObjectType.Contact);
              setViewState(AutomationListStateEnum.ReviewDuplicates);
            }}
          >
            Create contacts
          </CiroDropdownButtonItem>
          <CiroDropdownButtonItem
            analyticsField="Push to CRM Leads"
            disabled={blockPushToCrm}
            onClick={() => {
              setSelectedCrmObject(CrmObjectType.Lead);
              setViewState(AutomationListStateEnum.ReviewDuplicates);
            }}
          >
            Create leads
          </CiroDropdownButtonItem>
        </CiroDropdownButton>
      </CiroTooltipContainer>
    );
  }

  return (
    <CiroButton disabled analyticsField={"Push to CRM disabled"}>
      Push to CRM
    </CiroButton>
  );
};

export default InitiatePushToCrmButton;
