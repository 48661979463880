import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/shared/Loading";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  ProfileCompanyCreditsCard_UserAccountFragmentDoc,
  ProfileCreditsSummary_OrganizationFragmentDoc,
  ProfileIntegrationDetails_UserAccountFragment,
  Profile_RequestChangePasswordEmailMutation,
  Profile_RequestChangePasswordEmailMutationVariables,
  Profile_UserAccountQuery,
  Profile_UserAccountQueryVariables,
} from "../../__generated__/graphql";
import CiroContainer from "../../components/shared/CiroContainer";
import classNames from "classnames";
import CiroCard from "../../components/shared/CiroCard";
import CiroButton from "../../components/shared/CiroButton";
import ProfileCompanyCreditsCard, {
  ProfileCompanyCreditsCard_UserAccount,
} from "../../components/profile/ProfileCompanyCreditsCard";
import { useFragment } from "../../__generated__";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import ProfileCreditsSummary, {
  ProfileCreditsSummary_Organization,
} from "../../components/profile/ProfileCreditsSummary";
import { startOfMonth, subMonths } from "date-fns";
import {
  ProfileIntegrationDetails,
  ProfileIntegrationDetails_UserAccount,
} from "../../components/manageIntegration/ProfileIntegrationDetails";
import DataProviderConnectionCard, {
  DataProviderConnectionCard_Organization,
} from "../../components/profile/DataProviderConnectionCard";
import UserPhotoIcon from "../../components/shared/UserPhotoIcon";

const Profile_requestChangePasswordEmail = gql`
  mutation Profile_requestChangePasswordEmail {
    requestChangePasswordEmail {
      success
    }
  }
`;

export const Profile_UserAccount = gql`
  query Profile_UserAccount($creditTransactionsAfter: Date) {
    userAccount {
      id
      hasEmailPasswordLogin
      hasCRMConnection
      impersonating {
        email
      }
      org {
        ...ProfileCreditsSummary_Organization
        ...DataProviderConnectionCard_Organization
      }
      ...ProfileCompanyCreditsCard_UserAccount
      ...ProfileIntegrationDetails_UserAccount
    }
    permissions {
      canAddDataSource
    }
  }
  ${DataProviderConnectionCard_Organization}
  ${ProfileCompanyCreditsCard_UserAccount}
  ${ProfileCreditsSummary_Organization}
  ${ProfileIntegrationDetails_UserAccount}
`;

const Profile = () => {
  const { user } = useAuth0();

  const [
    requestChangePasswordEmail,
    { data: requestPasswordChangeData, loading: requestPasswordChangeLoading },
  ] = useMutation<
    Profile_RequestChangePasswordEmailMutation,
    Profile_RequestChangePasswordEmailMutationVariables
  >(Profile_requestChangePasswordEmail);

  const {
    data: userAccountData,
    loading: userAccountLoading,
    refetch: userAccountRefetch,
  } = useQuery<Profile_UserAccountQuery, Profile_UserAccountQueryVariables>(
    Profile_UserAccount,
    {
      variables: {
        creditTransactionsAfter: startOfMonth(subMonths(new Date(), 3)),
      },
    },
  );

  const profileCompanyCreditsCardUserAccount = useFragment(
    ProfileCompanyCreditsCard_UserAccountFragmentDoc,
    userAccountData?.userAccount,
  );

  const profileCreditsSummaryOrg = useFragment(
    ProfileCreditsSummary_OrganizationFragmentDoc,
    userAccountData?.userAccount?.org,
  );

  // Has an email password login and has not already requested recently
  const canRequestChangePassword =
    userAccountData?.userAccount?.hasEmailPasswordLogin &&
    !requestPasswordChangeData?.requestChangePasswordEmail.success;

  return (
    <>
      <CiroContainer
        className={classNames([
          "flex",
          "items-center",
          "bg-zinc-100",
        ])}
      >
        <div
          className={classNames([
            "pt-24",
            "flex",
            "justify-center",
            "w-full",
            "mb-4",
          ])}
        >
          <div className={classNames(["w-fit", "pb-4"])}>
            <CiroCard
              customClassName={classNames({
                "flex": userAccountLoading,
                "flex-row": userAccountLoading,
                "justify-center": userAccountLoading,
                "items-center": userAccountLoading,
              })}
            >
              {userAccountLoading ? (
                <div className={classNames("w-96")}>
                  <SkeletonLoading numSkeletons={2} skeletonHeight={"16rem"} />
                </div>
              ) : (
                <div>
                  <div
                    className={classNames([
                      "flex",
                      "items-start",
                      "mb-4",
                    ])}
                  >
                    <div className={classNames("pb-2")}>
                      <UserPhotoIcon />
                    </div>
                    <div className={classNames("px-6")}>
                      <div
                        className={classNames(
                          "font-semibold",
                          "text-2xl",
                        )}
                      >
                        {user?.name}
                      </div>
                      <div className={classNames("mt-2")}>
                        {user?.email}
                      </div>
                      <ProfileIntegrationDetails
                        userAccount={
                          userAccountData?.userAccount as ProfileIntegrationDetails_UserAccountFragment
                        }
                        refetchUserAccount={userAccountRefetch}
                      />
                    </div>
                    {userAccountData?.userAccount?.impersonating && (
                      <div
                        className={classNames(
                          "mt-4",
                          "text-center",
                        )}
                      >
                        Impersonating{" "}
                        {userAccountData?.userAccount?.impersonating!.email}
                      </div>
                    )}
                    <div
                      className={classNames([
                        "flex-col",
                        "flex",
                        "items-center",
                      ])}
                    >
                      {canRequestChangePassword && (
                        <CiroButton
                          onClick={() => requestChangePasswordEmail()}
                          disabled={requestPasswordChangeLoading}
                          analyticsField="Reset Password"
                        >
                          Reset password
                        </CiroButton>
                      )}
                      {requestPasswordChangeData?.requestChangePasswordEmail
                        .success && (
                        <CiroButton
                          disabled={true}
                          analyticsField="Request Password (SHOULD NEVER SEE IN SEGMENT)"
                        >
                          Password request sent to your email
                        </CiroButton>
                      )}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      "border-t-1",
                      "-mx-8",
                      "-mb-12",
                      "p-4",
                    )}
                  >
                    <ProfileCompanyCreditsCard
                      userAccount={profileCompanyCreditsCardUserAccount}
                    />
                  </div>
                </div>
              )}
            </CiroCard>
            {userAccountData?.permissions?.canAddDataSource && (
              <div className={classNames("my-6")}>
                <DataProviderConnectionCard
                  organization={userAccountData?.userAccount?.org}
                />
              </div>
            )}
            <div className={classNames("pt-8")}>
              <ProfileCreditsSummary org={profileCreditsSummaryOrg} />
            </div>
          </div>
        </div>
      </CiroContainer>
      <footer
        className={classNames(
          "bg-gray-100",
          "bottom-0",
          "content-center",
          "flex-col",
          "flex",
          "w-full",
          "lg:hidden",
        )}
      >
        <div className="flex justify-evenly py-4 border-t-2 border-t-stone-200">
          <a
            className="no-underline my-1 text-stone-300 text-xs hover:text-neutral-600"
            href="https://www.ciro.io/terms-of-service"
          >
            Terms of service
          </a>
          <a
            className="no-underline my-1 text-stone-300 text-xs hover:text-neutral-600"
            href="https://www.ciro.io/privacy-policy"
          >
            Privacy policy
          </a>
        </div>
      </footer>
    </>
  );
};

export default withAuthenticationRequired(Profile, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <Loading />,
});
