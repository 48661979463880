import classNames from "classnames";
import CiroModal from "./CiroModal";
import CiroButton, { CiroButtonStyleEnum } from "./CiroButton";

interface ICiroConfirmationModalProps {
  isOpen: boolean;
  text: string;
  onClose: () => void;
  onConfirm: () => void;
  analyticsField: string;
  confirmText?: string;
  cancelText?: string;
  confirmStyle?: CiroButtonStyleEnum;
  cancelStyle?: CiroButtonStyleEnum;
}

const CiroConfirmationModal = ({
  isOpen,
  text,
  onClose,
  onConfirm,
  analyticsField,
  confirmText = "Confirm",
  cancelText = "Cancel",
  confirmStyle,
  cancelStyle,
}: ICiroConfirmationModalProps) => {
  return (
    <CiroModal isOpen={isOpen} onClose={onClose}>
      <div>
        <div>{text}</div>
        <div className={classNames("flex", "justify-end", "gap-2", "pt-4")}>
          <CiroButton
            onClick={onClose}
            analyticsField={"Cancel " + analyticsField}
            style={cancelStyle}
          >
            {cancelText}
          </CiroButton>
          <CiroButton
            style={confirmStyle || CiroButtonStyleEnum.DELETE}
            onClick={onConfirm}
            analyticsField={"Confirm " + analyticsField}
          >
            {confirmText}
          </CiroButton>
        </div>
      </div>
    </CiroModal>
  );
};

export default CiroConfirmationModal;
