import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { User } from "auth0";
import { createContext } from "react";

const AppContext = createContext({
  accessToken: null,
  user: null,
  apolloClient: {} as ApolloClient<NormalizedCacheObject>,
  isExtensionInstalled: false,
} as {
  accessToken: null | string;
  user: null | User | undefined;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  isExtensionInstalled: boolean;
});

export default AppContext;
