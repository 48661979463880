import { createContext } from "react";
import { IntegrationModalPhases } from "../components/manageIntegration/manageIntegrationButton/ManageIntegrationsButton";
import { IntegrationEnum } from "../__generated__/graphql";

const NavigationContext = createContext({
  refreshNavData: async () => {
    return;
  },
  integrationModalPhase: IntegrationModalPhases.GET_STARTED,
  setIntegrationModalPhase: (phase: IntegrationModalPhases) => {
    return;
  },
  availableIntegrations: [] as IntegrationEnum[],
  setAvailableIntegrations: (integrations: IntegrationEnum[]) => {
    return;
  },
  resetAvailableIntegrations: () => {
    return;
  },
} as {
  refreshNavData: () => Promise<any>;
  integrationModalPhase: IntegrationModalPhases;
  setIntegrationModalPhase: (phase: IntegrationModalPhases) => void;
  availableIntegrations: IntegrationEnum[];
  setAvailableIntegrations: (integrations: IntegrationEnum[]) => void;
  resetAvailableIntegrations: () => void;
});

export default NavigationContext;
