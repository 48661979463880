import Markdown from "react-markdown";
import CiroLink from "./CiroLink";
import classNames from "classnames";
import remarkGfm from "remark-gfm";

interface ICiroMarkdownOptions {
  addPSpacing?: boolean;
}

const CiroMarkdown = ({
  markdown,
  options,
}: {
  markdown: string;
  options?: ICiroMarkdownOptions;
}) => {
  return (
    <Markdown
      components={{
        a: ({ href, children }) => {
          return children ? <CiroLink href={href}>{children}</CiroLink> : null;
        },
        p: ({ children }) => {
          const sections =
            typeof children === "string" ? children.split("\n") : null;
          return children ? (
            <div
              className={classNames({
                "pt-3": options?.addPSpacing,
              })}
            >
              {sections ? (
                sections.map((section, index) => (
                  <div className={"text-sm"} key={index}>
                    {section}
                  </div>
                ))
              ) : (
                <div className={"text-sm"}>{children}</div>
              )}
            </div>
          ) : null;
        },
        li: ({ children }) => {
          return children ? (
            <li className={classNames("list-disc", "ml-4")}>{children}</li>
          ) : null;
        },
      }}
      remarkPlugins={[remarkGfm]}
    >
      {markdown}
    </Markdown>
  );
};

export default CiroMarkdown;
