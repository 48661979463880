import { useState } from "react";
import classNames from "classnames";
import DropdownIcon from "../../assets/img/icons/DropdownIcon";
import logo from "../../assets/ciro_logo_transparent.png";
import { Link } from "react-router-dom";
import CiroPopoutDropdown from "../shared/CiroPopoutDropdown";
import NavLink from "./NavLink";
import { ISharedNavigationProps } from "./NavigationContainer";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutIcon from "../../assets/img/icons/LogoutIcon";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import NavigationLinksSection from "./NavigationLinksSection";

const TopNavigationDisplay = ({
  navIsOpen,
  setNavIsOpen,
}: {
  navIsOpen: boolean;
  setNavIsOpen: (v: boolean) => void;
}) => {
  return (
    <div
      className={classNames(
        "border-b-2",
        "flex",
        "bg-white",
        "h-16",
        "relative",
        "justify-between",
        "px-9",
        "py-4",
        "w-screen",
        "lg:hidden",
      )}
    >
      <Link to="/">
        <img src={logo} width="80" height="70" alt="Ciro logo" />
      </Link>
      <CiroButton
        onClick={() => setNavIsOpen(!navIsOpen)}
        style={CiroButtonStyleEnum.UNSTYLED}
        analyticsField="Top Navigation Toggler"
      >
        <DropdownIcon />
      </CiroButton>
    </div>
  );
};

const SmallScreenNavigation = ({
  isLoggedIn,
  loading,
  navData,
  stopImpersonating,
}: ISharedNavigationProps) => {
  const { logout } = useAuth0();
  const [navIsOpen, setNavIsOpen] = useState(false);

  const impersonating = navData?.userAccount?.impersonating;

  if (!isLoggedIn) {
    return null;
  }

  if (loading) {
    return <div className={classNames("h-16", "lg:hidden")} />;
  }

  return (
    <CiroPopoutDropdown
      customWidth="w-full"
      parent={
        <TopNavigationDisplay
          setNavIsOpen={setNavIsOpen}
          navIsOpen={navIsOpen}
        />
      }
      isOpen={navIsOpen}
      setIsOpen={setNavIsOpen}
    >
      <div className={classNames("flex", "flex-col")}>
        <NavigationLinksSection
          fullWidth={true}
          navData={navData}
          stopImpersonating={stopImpersonating}
        />
        {!impersonating && (
          <NavLink
            fullWidth
            onClick={() => {
              logout({ returnTo: window.location.origin });
              sessionStorage?.localStorage?.clear();
            }}
            title="Log out"
            icon={<LogoutIcon />}
          />
        )}
      </div>
    </CiroPopoutDropdown>
  );
};

export default SmallScreenNavigation;
