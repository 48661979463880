const LinkedinCompanyHeadCounts = [
  { id: "B", label: "1-10" },
  { id: "A", label: "Self-employed" },
  { id: "C", label: "11-50" },
  { id: "D", label: "51-200" },
  { id: "E", label: "201-500" },
  { id: "F", label: "501-1,000" },
  { id: "G", label: "1,001-5,000" },
  { id: "H", label: "5,001-10,000" },
  { id: "I", label: "10,001+" },
];

export default LinkedinCompanyHeadCounts;
