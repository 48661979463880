import CiroFilterModalDropdownSection from "../../../shared/CiroFilter/CiroFilterModalDropdownSection";
import LinkedInGeographies from "../../../../constants/LinkedinFilters/LinkedinGeographies";
import CiroDropDown from "../../../shared/CiroDropdown";

const CuratedSearchFilterCompanyHqSection = ({
  currentCompanyHqFilter,
  setCurrentCompanyHqFilter,
  defaultOpen = false,
}: {
  currentCompanyHqFilter: { label: string; id: number }[];
  setCurrentCompanyHqFilter: (v: { label: string; id: number }[]) => void;
  defaultOpen?: boolean;
}) => {
  return (
    <CiroFilterModalDropdownSection
      defaultOpen={defaultOpen}
      numFiltersApplied={currentCompanyHqFilter.length}
      previews={currentCompanyHqFilter.map((c) => c.label)}
      sectionTitle={"Company HQ location"}
      filterOptions={[
        {
          label: "Company HQ location includes any of:",
          filter: (
            <CiroDropDown
              isMulti={true}
              value={currentCompanyHqFilter.map((c) => c.id)}
              onChange={(newValue) => {
                const selectedCompanyHqs = LinkedInGeographies.filter(
                  (companyHq) => newValue.includes(companyHq.id),
                );
                setCurrentCompanyHqFilter(selectedCompanyHqs);
              }}
              options={LinkedInGeographies.map((geography) => {
                return {
                  value: geography.id,
                  label: geography.label,
                };
              })}
            />
          ),
        },
      ]}
    />
  );
};

export default CuratedSearchFilterCompanyHqSection;
