import classNames from "classnames";
import CuratedSearchPersonaCard from "../../components/autopilotCuratedSearch/CuratedSearchPersona/CuratedSearchPersonaCard";
import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import CuratedSearchFiltersCard from "../../components/autopilotCuratedSearch/CuratedSearchFilters/CuratedSearchFiltersCard";
import {
  AutopilotCuratedSearch_AutopilotCuratedSearchQuery,
  AutopilotCuratedSearch_AutopilotCuratedSearchQueryVariables,
  AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersonaQuery,
  AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersonaQueryVariables,
  AutopilotCuratedSearch_UpsertAutopilotCuratedSearchMutation,
  AutopilotCuratedSearch_UpsertAutopilotCuratedSearchMutationVariables,
  LinkedinSalesNavigatorFilters,
} from "../../__generated__/graphql";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import AutopilotCuratedSearchContext, {
  ICuratedSearchPersona,
} from "../../contexts/AutopilotCuratedSearchContext";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import { hasFiltersApplied } from "../../components/autopilotCuratedSearch/curatedSearchUtils";
import CiroConfirmationModal from "../../components/shared/CiroConfirmationModal";
import { CiroButtonStyleEnum } from "../../components/shared/CiroButton";
import CiroTooltipContainer from "../../components/shared/CiroTooltipContainer";
import sendPayloadToExtension from "../../utils/sendPayloadToExtension";
import { LAUNCH_AUTOPILOT } from "../../utils/sendPayloadToExtension";
import { CursorArrowRippleIcon } from "@heroicons/react/20/solid";
import CuratedSearchPersonaDropdown from "../../components/autopilotCuratedSearch/CuratedSearchPersona/CuratedSearchPersonaDropdown";
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/outline";

const AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersona = gql`
  query AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersona(
    $input: LinkedinSalesNavigatorFiltersForPersonaInput!
  ) {
    linkedinSalesNavigatorFiltersForPersona(input: $input) {
      filters {
        jobTitles {
          label
        }
        industries {
          id
          label
        }
        geographies {
          id
          label
        }
        companyHQLocations {
          id
          label
        }
        companyHeadCounts {
          id
          label
        }
        companyNames {
          label
        }
        keywords
      }
      additionalFiltering
    }
  }
`;

const AutopilotCuratedSearch_UpsertAutopilotCuratedSearch = gql`
  mutation AutopilotCuratedSearch_UpsertAutopilotCuratedSearch(
    $input: UpsertAutopilotCuratedSearchInput!
  ) {
    upsertAutopilotCuratedSearch(input: $input) {
      success
      autopilotCuratedSearch {
        id
        linkedinSalesNavigatorCuratedSearch {
          url
        }
      }
    }
  }
`;

const AutopilotCuratedSearch_AutopilotCuratedSearch = gql`
  query AutopilotCuratedSearch_AutopilotCuratedSearch(
    $id: Int
    $skip: Boolean!
  ) {
    personas {
      id
      name
      description
      source
    }
    autopilotCuratedSearch(id: $id) @skip(if: $skip) {
      id
      personas {
        id
        name
        description
        source
      }
      linkedinSalesNavigatorCuratedSearch {
        filters {
          jobTitles {
            label
          }
          industries {
            id
            label
          }
          geographies {
            id
            label
          }
          companyHQLocations {
            id
            label
          }
          companyHeadCounts {
            id
            label
          }
          companyNames {
            label
          }
          keywords
        }
      }
    }
  }
`;

const newPersona: ICuratedSearchPersona = {
  id: undefined,
  name: "",
  description: "",
  source: null,
};

const AutopilotCuratedSearch = ({
  onboarding = false,
}: {
  onboarding?: boolean;
}) => {
  const { curatedSearchId } = useParams();

  const [showFiltersCard, setShowFiltersCard] = useState(
    Boolean(curatedSearchId),
  );

  const [searchParams] = useSearchParams();
  const newPersonaIntent = searchParams.get("newPersona") === "true";
  const initialPersonaId = searchParams.get("personaId");

  const {
    data: autopilotCuratedSearchData,
    loading: autopilotCuratedSearchLoading,
  } = useQuery<
    AutopilotCuratedSearch_AutopilotCuratedSearchQuery,
    AutopilotCuratedSearch_AutopilotCuratedSearchQueryVariables
  >(AutopilotCuratedSearch_AutopilotCuratedSearch, {
    variables: {
      id: curatedSearchId ? parseInt(curatedSearchId) : undefined,
      skip: !curatedSearchId,
    },
  });

  const [
    generateFilters,
    { loading: filtersLoading, data: generatedFiltersData },
  ] = useLazyQuery<
    AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersonaQuery,
    AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersonaQueryVariables
  >(AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersona, {
    fetchPolicy: "network-only",
  });

  const [currentFilters, setCurrentFilters] =
    useState<LinkedinSalesNavigatorFilters | null>(null);
  const [additionalFiltering, setAdditionalFiltering] = useState<string[]>([]);
  useEffect(() => {
    if (generatedFiltersData?.linkedinSalesNavigatorFiltersForPersona) {
      setCurrentFilters(
        generatedFiltersData.linkedinSalesNavigatorFiltersForPersona.filters ??
          null,
      );
      setAdditionalFiltering(
        generatedFiltersData.linkedinSalesNavigatorFiltersForPersona
          .additionalFiltering ?? [],
      );
    }
    if (
      autopilotCuratedSearchData?.autopilotCuratedSearch
        ?.linkedinSalesNavigatorCuratedSearch?.filters
    ) {
      setCurrentFilters(
        autopilotCuratedSearchData.autopilotCuratedSearch
          .linkedinSalesNavigatorCuratedSearch.filters,
      );
    }
  }, [autopilotCuratedSearchData, generatedFiltersData]);

  const [
    upsertAutopilotCuratedSearch,
    { loading: upsertAutopilotCuratedSearchLoading },
  ] = useMutation<
    AutopilotCuratedSearch_UpsertAutopilotCuratedSearchMutation,
    AutopilotCuratedSearch_UpsertAutopilotCuratedSearchMutationVariables
  >(AutopilotCuratedSearch_UpsertAutopilotCuratedSearch);

  const [allPersonas, setAllPersonas] = useState<ICuratedSearchPersona[]>([]);
  useEffect(() => {
    setAllPersonas(autopilotCuratedSearchData?.personas || []);
  }, [autopilotCuratedSearchData]);

  const [selectedPersona, setSelectedPersona] =
    useState<ICuratedSearchPersona>(newPersona);
  const [loadedInitialState, setLoadedInitialState] = useState(false);
  useEffect(() => {
    if (loadedInitialState) {
      return;
    }

    if (initialPersonaId && autopilotCuratedSearchData) {
      const initialPersona = autopilotCuratedSearchData?.personas.find(
        (p) => p.id === parseInt(initialPersonaId),
      );
      setSelectedPersona(initialPersona || newPersona);
      setLoadedInitialState(true);
      return;
    }

    const newSelectedPersona =
      autopilotCuratedSearchData?.autopilotCuratedSearch?.personas[0] ||
      autopilotCuratedSearchData?.personas[0] ||
      null;

    if (newPersonaIntent) {
      setSelectedPersona(newPersona);
    } else {
      setSelectedPersona(newSelectedPersona || newPersona);
    }
    setLoadedInitialState(Boolean(newSelectedPersona));
  }, [
    autopilotCuratedSearchData,
    loadedInitialState,
    newPersonaIntent,
    initialPersonaId,
  ]);

  const [launchConfirmationMessage, setLaunchConfirmationMessage] = useState<
    string | null
  >(null);

  const [mayNeedToRegenerateFilters, setMayNeedToRegenerateFilters] =
    useState(false);

  const onSaveFilters = async () => {
    if (!selectedPersona?.id) {
      toast.error("No persona selected");
      return;
    }
    if (!currentFilters || !hasFiltersApplied(currentFilters)) {
      toast.error("No filters applied");
      return;
    }
    toast.success("Launching Sales Navigator...");
    await upsertAutopilotCuratedSearch({
      variables: {
        input: {
          id: curatedSearchId ? parseInt(curatedSearchId) : undefined,
          personaIds: [selectedPersona?.id],
          linkedinSalesNavigatorFilters: {
            keywords: currentFilters?.keywords,
            jobTitles: currentFilters?.jobTitles?.map((j) => ({
              label: j.label,
            })),
            industries: currentFilters?.industries?.map((i) => ({
              id: i.id,
              label: i.label,
            })),
            geographies: currentFilters?.geographies?.map((g) => ({
              id: g.id,
              label: g.label,
            })),
            companyHQLocations: currentFilters?.companyHQLocations?.map(
              (c) => ({
                id: c.id,
                label: c.label,
              }),
            ),
            companyHeadCounts: currentFilters?.companyHeadCounts?.map((c) => ({
              id: c.id,
              label: c.label,
            })),
            companyNames: currentFilters?.companyNames?.map((c) => ({
              label: c.label,
            })),
          },
        },
      },
      onCompleted: async (data) => {
        setMayNeedToRegenerateFilters(false);
        const redirectUrl =
          data.upsertAutopilotCuratedSearch.autopilotCuratedSearch
            ?.linkedinSalesNavigatorCuratedSearch?.url;
        if (redirectUrl) {
          try {
            await sendPayloadToExtension({
              payload: {
                action: LAUNCH_AUTOPILOT,
                url: redirectUrl,
                personaIds: [selectedPersona?.id].filter(Boolean),
              },
            });
          } catch (error) {
            window.open(redirectUrl, "_blank", "noreferrer noopener");
          }
        }
      },
    });
  };

  const filtersCardHiddenMessage =
    !showFiltersCard || !selectedPersona?.id
      ? "Please fine tune your persona first"
      : upsertAutopilotCuratedSearchLoading
        ? "Saving your search"
        : "";

  return (
    <AutopilotCuratedSearchContext.Provider
      value={{
        filtersLoading,
        generateFilters,
        allPersonas,
        setAllPersonas,
        selectedPersona: selectedPersona,
        setSelectedPersona: (persona) => {
          setSelectedPersona(persona || newPersona);
        },
        mayNeedToRegenerateFilters,
        setMayNeedToRegenerateFilters,
        setShowFiltersCard,
        showFiltersCard,
      }}
    >
      <CiroContainer className={classNames("bg-zinc-100")}>
        <CiroBreadCrumbs
          values={[
            "Autopilot",
            onboarding ? "Start searching" : "Search Builder",
          ]}
          href="/autopilot"
        />
        <CiroTitle
          title={onboarding ? "Start searching" : "Search Builder"}
          subtitle="Narrow your search based on your specific personas and filters"
        />
        <div className={classNames("w-full", "py-4", "flex", "justify-center")}>
          <div className={classNames("flex", "gap-4", "w-full")}>
            <div
              className={classNames("w-full", {
                "opacity-50": upsertAutopilotCuratedSearchLoading,
                "pointer-events-none": upsertAutopilotCuratedSearchLoading,
              })}
            >
              <div
                className={classNames(
                  "text-lg",
                  "font-semibold",
                  "pb-4",
                  "flex",
                  "justify-between",
                  "items-center",
                  "gap-2",
                  "h-12",
                )}
              >
                <div className={classNames("flex", "gap-2", "items-center")}>
                  <div>
                    <CursorArrowRippleIcon
                      className={classNames("w-6", "h-6", "text-orange-500")}
                    />
                  </div>
                  <div>Persona</div>
                </div>
                <CuratedSearchPersonaDropdown />
              </div>
              <CuratedSearchPersonaCard
                initialLoading={autopilotCuratedSearchLoading}
              />
            </div>
            <div className={classNames("w-full")}>
              <CiroTooltipContainer
                tooltip={filtersCardHiddenMessage}
                disabled={!filtersCardHiddenMessage}
              >
                <div
                  className={classNames({
                    "opacity-30": Boolean(filtersCardHiddenMessage),
                    "pointer-events-none": Boolean(filtersCardHiddenMessage),
                  })}
                >
                  <div
                    className={classNames(
                      "text-lg",
                      "font-semibold",
                      "pb-4",
                      "flex",
                      "justify-between",
                      "items-center",
                      "gap-2",
                      "h-12",
                    )}
                  >
                    <div
                      className={classNames("flex", "gap-2", "items-center")}
                    >
                      <div>
                        <AdjustmentsVerticalIcon
                          className={classNames(
                            "w-6",
                            "h-6",
                            "text-orange-500",
                          )}
                        />
                      </div>
                      <div>Sales Navigator Filters</div>
                    </div>
                  </div>
                  <CuratedSearchFiltersCard
                    currentFilters={currentFilters}
                    setCurrentFilters={setCurrentFilters}
                    initialLoading={autopilotCuratedSearchLoading}
                    additionalFiltering={additionalFiltering}
                    onSave={async () => {
                      if (mayNeedToRegenerateFilters) {
                        setLaunchConfirmationMessage(
                          "Looks like you have edited your persona and didn't regenerate your filters. Would you like to continue?",
                        );
                        return;
                      }

                      await onSaveFilters();
                    }}
                  />
                </div>
              </CiroTooltipContainer>
            </div>
          </div>
        </div>
      </CiroContainer>
      <CiroConfirmationModal
        isOpen={!!launchConfirmationMessage}
        text={launchConfirmationMessage || ""}
        onClose={() => setLaunchConfirmationMessage(null)}
        onConfirm={() => {
          onSaveFilters();
          setLaunchConfirmationMessage(null);
          setMayNeedToRegenerateFilters(false);
        }}
        analyticsField="Launch Sales Navigator Saved Search"
        confirmText="Continue"
        cancelText="Cancel"
        confirmStyle={CiroButtonStyleEnum.LOUD}
      />
    </AutopilotCuratedSearchContext.Provider>
  );
};

export default AutopilotCuratedSearch;
