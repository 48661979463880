import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import classNames from "classnames";
import { useCallback, useContext, useState } from "react";
import fileDownload from "js-file-download";
import toast from "react-hot-toast";
import AppContext from "../../contexts/AppContext";
import CiroSpinner from "../shared/CiroSpinner";

const ChromeExtensionNumbersDownloadButton = ({ ids }: { ids: number[] }) => {
  const [isExporting, setIsExporting] = useState(false);
  const { accessToken } = useContext(AppContext);

  const downloadPhoneNumbersRequestTransaction = useCallback(async () => {
    try {
      setIsExporting(true);
      const response = await fetch("/export-enriched-contacts", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumberTransactionIds: ids,
          csvTitle: "Ciro Phone Numbers - Chrome Extension",
          allChromeExtension: true,
        }),
      });
      const blob = await response.blob();
      fileDownload(blob, `Ciro Phone Numbers.csv`);
      toast.success("CSV downloaded successfully");
    } catch (error) {
      console.error("Error during CSV download:", error);
      toast.error("Error during CSV download");
    } finally {
      setIsExporting(false);
    }
  }, [ids, accessToken]);

  return (
    <CiroButton
      analyticsField="Download Phone Numbers Request Transaction"
      style={CiroButtonStyleEnum.UNSTYLED}
      onClick={downloadPhoneNumbersRequestTransaction}
      disabled={isExporting}
    >
      <div
        className={classNames(
          "flex",
          "items-center",
          "border-1",
          "rounded-lg",
          "px-4",
          "py-2",
          "text-sm",
          "text-neutral-700",
          isExporting && "bg-gray-300",
          "hover:bg-gray-300",
        )}
      >
        {isExporting ? (
          <div className={classNames("mr-1", "flex", "items-center")}>
            <CiroSpinner loading={isExporting} />
          </div>
        ) : (
          <CloudArrowDownIcon
            className={classNames("w-6", "h-6", "mr-2", "text-neutral-600")}
          />
        )}
        <div className={classNames("text-neutral-600")}>
          {isExporting ? "Downloading contacts" : "Download contacts"}
        </div>
      </div>
    </CiroButton>
  );
};

export default ChromeExtensionNumbersDownloadButton;
