import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import classNames from "classnames";
import { useCallback, useContext, useState } from "react";
import fileDownload from "js-file-download";
import toast from "react-hot-toast";
import AppContext from "../../contexts/AppContext";
import CiroSpinner from "../shared/CiroSpinner";

const EnrichedContactsDownloadButton = ({
  selectedIds,
  allIds,
}: {
  selectedIds: number[];
  allIds: number[];
}) => {
  const [isExporting, setIsExporting] = useState(false);
  const { accessToken } = useContext(AppContext);

  const downloadEnrichedContacts = useCallback(async () => {
    try {
      setIsExporting(true);
      const response = await fetch("/export-enriched-contacts", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customContactIds: selectedIds.length > 0 ? selectedIds : allIds,
          csvTitle: "Ciro Phone Numbers - Enriched Contacts",
        }),
      });
      const blob = await response.blob();
      fileDownload(blob, `Ciro Phone Numbers.csv`);
      toast.success("CSV downloaded successfully");
    } catch (error) {
      console.error("Error during CSV download:", error);
      toast.error("Error during CSV download");
    } finally {
      setIsExporting(false);
    }
  }, [selectedIds, allIds, accessToken]);

  return (
    <CiroButton
      analyticsField="Download Phone Numbers Request Transaction"
      onClick={downloadEnrichedContacts}
      style={CiroButtonStyleEnum.UNSTYLED}
      disabled={isExporting}
      customPaddingY="py-2"
    >
      <div
        className={classNames(
          "border-1",
          "rounded-lg",
          "px-4",
          "py-2",
          "text-neutral-700",
          "hover:bg-gray-300",
          "whitespace-nowrap",
          "w-fit",
          "flex",
          "text-sm",
          "items-center",
          "justify-center",
          isExporting ? "bg-gray-300" : "bg-white",
          selectedIds.length > 0 ? "text-orange-500" : "text-neutral-700",
        )}
      >
        {isExporting ? (
          <div className={classNames("mr-2", "flex", "items-center")}>
            <CiroSpinner loading={isExporting} />
          </div>
        ) : (
          <CloudArrowDownIcon className={classNames("w-6", "h-6", "mr-2")} />
        )}
        {selectedIds.length > 0
          ? `${isExporting ? "Downloading" : "Download"} ${selectedIds.length} selected`
          : `${isExporting ? "Downloading" : "Download"} all`}
      </div>
    </CiroButton>
  );
};

export default EnrichedContactsDownloadButton;
