import classNames from "classnames";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import MobileNumbersOverview from "../../components/mobileNumbers/MobileNumbersOverview";
import { gql, useQuery } from "@apollo/client";
import CiroButton, {
  CiroButtonStyleEnum,
} from "../../components/shared/CiroButton";
import { useState } from "react";
import MobileNumbersRefreshButton from "../../components/mobileNumbers/MobileNumbersRefreshButton";
import DotIcon from "../../assets/img/icons/DotIcon";
import SalesforceIcon from "../../assets/img/icons/SalesforceIcon";
import ApolloIcon from "../../assets/img/icons/ApolloIcon";
import { useNavigate } from "react-router-dom";
import {
  CreateIntegrationButton_UserAccountFragmentDoc,
  MobileNumbers_TransactionCountQuery,
  MobileNumbers_TransactionCountQueryVariables,
  MobileNumbers_UserAccountQuery,
  MobileNumbers_UserAccountQueryVariables,
} from "../../__generated__/graphql";
import HubspotIcon from "../../assets/img/icons/HubspotIcon";
import MobileNumbersBodyContainer from "../../components/mobileNumbers/MobileNumbersBodyContainer";
import { ForwardIcon } from "@heroicons/react/20/solid";
import MobileNumbersRunModal from "../../components/mobileNumbers/MobileNumbersRunModal";
import {
  ManageIntegrationsButton,
  CreateIntegrationButton_UserAccount,
} from "../../components/manageIntegration/manageIntegrationButton/ManageIntegrationsButton";
import { useFragment } from "../../__generated__";

export const MobileNumbers_UserAccount = gql`
  query MobileNumbers_UserAccount {
    userAccount {
      org {
        apolloIntegration {
          id
        }
        crmIntegration
        organizationMergeIntegration {
          integration
          invalid_number_statuses
        }
      }
      ...CreateIntegrationButton_UserAccount
    }
  }
  ${CreateIntegrationButton_UserAccount}
`;

export const MobileNumbers_TransactionCount = gql`
  query MobileNumbers_TransactionCount {
    phoneNumbersRequestTransactionsCount
  }
`;

interface IMobileNumbersProps {
  startOnWorkflows?: boolean;
}

const MobileNumbers = ({ startOnWorkflows }: IMobileNumbersProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: userAccountData, loading: userAccountLoading } = useQuery<
    MobileNumbers_UserAccountQuery,
    MobileNumbers_UserAccountQueryVariables
  >(MobileNumbers_UserAccount);
  const { data: transactionCountData } = useQuery<
    MobileNumbers_TransactionCountQuery,
    MobileNumbers_TransactionCountQueryVariables
  >(MobileNumbers_TransactionCount);
  const navigator = useNavigate();

  const hasApolloIntegration =
    userAccountData?.userAccount?.org?.apolloIntegration != null;
  const hasCiroNumbersIntegration =
    userAccountData?.userAccount?.org?.crmIntegration != null;

  const userAccountForCreateIntegrationButton = useFragment(
    CreateIntegrationButton_UserAccountFragmentDoc,
    userAccountData?.userAccount,
  );

  return (
    <CiroContainer className={classNames("bg-zinc-100")}>
      <div className={classNames("flex", "justify-between", "w-full")}>
        <div className={classNames("flex", "flex-row", "items-center")}>
          <CiroTitle title="Enrich CRM" />
          <span className={classNames("px-2")}>
            <MobileNumbersRefreshButton />
          </span>
        </div>
        <div
          className={classNames("flex", "flex-row", "items-center", "gap-2")}
        >
          {!hasCiroNumbersIntegration && (
            <ManageIntegrationsButton
              includeApollo
              userAccount={userAccountForCreateIntegrationButton}
            />
          )}
          {hasApolloIntegration && (
            <CiroButton
              analyticsField="Open Apollo settings"
              onClick={() => navigator("/enrich-crm/apollo-integration")}
            >
              <div className={classNames("flex", "flex-row", "items-center")}>
                <span className={classNames("mr-2")}>
                  <ApolloIcon />
                </span>
                Manage Apollo settings
                <span className={classNames("text-green-500", "ml-2")}>
                  <DotIcon />
                </span>
              </div>
            </CiroButton>
          )}
          {hasCiroNumbersIntegration && !hasApolloIntegration && (
            <CiroButton
              analyticsField="Open crm mobile connection settings"
              onClick={() => {
                navigator("/enrich-crm/crm-integration");
              }}
            >
              <div className={classNames("flex", "flex-row", "items-center")}>
                <span className={classNames("mr-2")}>
                  {userAccountData?.userAccount?.org
                    ?.organizationMergeIntegration?.integration ===
                  "Salesforce" ? (
                    <SalesforceIcon />
                  ) : (
                    <HubspotIcon />
                  )}
                </span>
                Manage{" "}
                {
                  userAccountData?.userAccount?.org
                    ?.organizationMergeIntegration?.integration
                }{" "}
                settings
                <span className={classNames("text-green-500", "ml-2")}>
                  <DotIcon />
                </span>
              </div>
            </CiroButton>
          )}
          {hasCiroNumbersIntegration && (
            <>
              <CiroButton
                analyticsField="Open Run Mobile Numbers modal"
                style={CiroButtonStyleEnum.LOUD}
                onClick={() => setIsModalOpen(true)}
              >
                <ForwardIcon className={classNames("w-4", "h-4", "mr-2")} />
                Run now
              </CiroButton>
              <MobileNumbersRunModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
              />
            </>
          )}
        </div>
      </div>
      <MobileNumbersOverview />
      {!userAccountLoading && (
        <MobileNumbersBodyContainer
          startOnWorkflows={Boolean(startOnWorkflows)}
          hasIntegration={hasCiroNumbersIntegration || hasApolloIntegration}
          hasTransactions={Boolean(
            transactionCountData?.phoneNumbersRequestTransactionsCount,
          )}
        />
      )}
    </CiroContainer>
  );
};

export default MobileNumbers;
