import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import FilterContext from "../../../contexts/FilterContext";
import { useContext } from "react";

interface ICiroFilterModalFilterOptionProps {
  label?: any;
  filter: any;
  switchFilter?: boolean;
}

const CiroFilterModalFilterOption = ({
  label,
  filter,
  switchFilter,
}: ICiroFilterModalFilterOptionProps) => {
  if (!switchFilter)
    return (
      <div className={classNames("pb-2")}>
        <div className={classNames("text-slate-700", "text-sm")}>{label}</div>
        {filter}
      </div>
    );

  return (
    <div
      className={classNames(
        "pb-2",
        "mt-1",
        "flex",
        "flex-row",
        "gap-2",
        "items-center",
      )}
    >
      {filter}
      <div className={classNames("text-slate-700", "text-sm")}>{label}</div>
    </div>
  );
};

const CiroFilterNumber = ({ filtersApplied }: { filtersApplied: number }) => {
  if (!filtersApplied) {
    return null;
  }

  return (
    <div
      className={classNames(
        "bg-orange-400",
        "rounded-full",
        "text-white",
        "w-5",
        "h-5",
        "ml-3",
      )}
    >
      {filtersApplied}
    </div>
  );
};

interface ICiroFilterModalDropdownSectionProps {
  btnClassName?: string;
  defaultOpen?: boolean;
  filterOptions: ICiroFilterModalFilterOptionProps[];
  numFiltersApplied: number;
  sectionTitle: any;
  smbFilter?: boolean;
  previews?: string[];
}

const CiroFilterModalDropdownSection = ({
  btnClassName,
  defaultOpen = false,
  filterOptions,
  numFiltersApplied,
  sectionTitle,
  smbFilter,
  previews,
}: ICiroFilterModalDropdownSectionProps) => {
  const { showSmbFilters } = useContext(FilterContext);

  if (smbFilter && !showSmbFilters) return null;
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classNames("px-4", "py-2", "w-full", btnClassName)}
          >
            <div className={classNames("flex", "justify-between", "w-full")}>
              <div className={classNames("text-slate-700", "text-sm", "flex")}>
                <div className={classNames("flex", "flex-row", "items-center")}>
                  {smbFilter && (
                    <div
                      className={classNames(
                        "w-1",
                        "h-1",
                        "bg-orange-500",
                        "rounded-[1px]",
                        "mr-2",
                      )}
                    />
                  )}
                  <div>{sectionTitle}</div>
                </div>
                <CiroFilterNumber filtersApplied={numFiltersApplied} />
                {previews && !open && (
                  <div
                    className={classNames(
                      "px-2",
                      "text-slate-400",
                      "text-xs",
                      "italic",
                      "text-left",
                      "mt-1",
                      "line-clamp-1",
                      "flex-1", // Changed to flex-1 to take up remaining space
                    )}
                  >
                    {previews.join(", ")}
                  </div>
                )}
              </div>
              <div className={classNames("w-3", "pr-4")}>
                <ChevronUpIcon
                  className={classNames("h-5", "text-slate-700", "transform", {
                    "rotate-180": !open,
                  })}
                />
              </div>
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className={classNames("bg-zinc-200", "p-4")}>
            {filterOptions.map((filterOption, i) => {
              return (
                <CiroFilterModalFilterOption
                  key={i}
                  label={filterOption.label}
                  filter={filterOption.filter}
                  switchFilter={filterOption.switchFilter}
                />
              );
            })}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default CiroFilterModalDropdownSection;
