import { gql, useQuery } from "@apollo/client";
import cloudUpload from "../../assets/img/icons/cloud-upload.svg";
import CiroContainer, {
  FULL_SCREEN_CONTAINER_CLASSES,
} from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import EnrichmentsTable, {
  EnrichmentFlowsTable_EnrichmentFlows,
} from "../../components/enrichments/EnrichmentsTable";
import classNames from "classnames";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import {
  CollectionsTable_CollectionsFragmentDoc,
  EnrichmentFlowFromCollectionModal_PermissionsFragmentDoc,
  EnrichmentFlowUploadCsvModal_PermissionsFragmentDoc,
  EnrichmentFlowsTable_EnrichmentFlowsFragmentDoc,
  Enrichments_CollectionsQuery,
  Enrichments_CollectionsQueryVariables,
  Enrichments_EnrichmentFlowsQuery,
} from "../../__generated__/graphql";
import { useFragment } from "../../__generated__";
import { useEffect, useRef, useState } from "react";
import SquareSparkleIcon from "../../assets/img/icons/SquareSparkleIcon";
import EnrichmentFlowUploadCSVDropFileCard from "../../components/enrichmentFlow/EnrichmentFlowUploadCSVDropFileCard";
import EnrichmentFlowUploadCSVPreviewModal, {
  EnrichmentFlowUploadCSVModal_Permissions,
} from "../../components/enrichmentFlow/EnrichmentFlowUploadCSVPreviewModal";
import CiroButton, {
  CiroButtonStyleEnum,
} from "../../components/shared/CiroButton";
import DownArrowIcon from "../../assets/img/icons/DownArrowIcon";
import CollectionIcon from "../../assets/img/icons/CollectionIcon";
import EnrichmentFlowFromCollectionModal, {
  EnrichmentFlowFromCollectionModal_Permissions,
} from "../../components/enrichments/EnrichmentFlowFromCollectionModal";
import CollectionsTable, {
  CollectionsTable_Collections,
} from "../../components/collections/CollectionsTable";
import EnrichmentsContext from "../../contexts/EnrichmentsContext";
import CiroTooltipContainer from "../../components/shared/CiroTooltipContainer";

export const Enrichments_enrichmentFlows = gql`
  query Enrichments_enrichmentFlows {
    enrichmentFlows {
      count
      ...EnrichmentFlowsTable_EnrichmentFlows
    }
  }
  ${EnrichmentFlowsTable_EnrichmentFlows}
`;

export const Enrichments_collections = gql`
  query Enrichments_collections {
    ...CollectionsTable_Collections
    permissions {
      maxEnrichmentTables
      canViewCollections
      ...EnrichmentFlowUploadCSVModal_Permissions
      ...EnrichmentFlowFromCollectionModal_Permissions
    }
  }
  ${EnrichmentFlowUploadCSVModal_Permissions}
  ${EnrichmentFlowFromCollectionModal_Permissions}
  ${CollectionsTable_Collections}
`;

export enum EnrichmentsFlowViewEnum {
  UPLOAD_CSV,
  INDEX,
  CREATING_FLOW,
}

const Enrichments = () => {
  const [newEnrichmentOpen, setNewEnrichmentOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [collectionModalOpen, setCollectionModalOpen] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState<number | null>(
    null,
  );
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const enrichmentFlowsQueryResult = useQuery<Enrichments_EnrichmentFlowsQuery>(
    Enrichments_enrichmentFlows,
  );

  const {
    data,
    loading,
    refetch: Enrichments_refetchEnrichmentFlows,
  } = enrichmentFlowsQueryResult;

  const { loading: collectionsLoading, data: collectionsData } = useQuery<
    Enrichments_CollectionsQuery,
    Enrichments_CollectionsQueryVariables
  >(Enrichments_collections);

  const enrichmentsTableData = useFragment(
    EnrichmentFlowsTable_EnrichmentFlowsFragmentDoc,
    data?.enrichmentFlows,
  );

  const collectionsTableData = useFragment(
    CollectionsTable_CollectionsFragmentDoc,
    collectionsData,
  );

  const enrichmentFlowUploadCsvPreviewModalPermissions = useFragment(
    EnrichmentFlowUploadCsvModal_PermissionsFragmentDoc,
    collectionsData?.permissions,
  );

  const enrichmentFlowFromCollectionModalPermissions = useFragment(
    EnrichmentFlowFromCollectionModal_PermissionsFragmentDoc,
    collectionsData?.permissions,
  );

  useEffect(() => {
    Enrichments_refetchEnrichmentFlows();
  }, [collectionModalOpen, Enrichments_refetchEnrichmentFlows]);

  const onRowClick = (collection: number) => {
    setSelectedCollection(collection);
    setCollectionModalOpen(true);
  };

  const hasCollections = Boolean(collectionsTableData?.collections?.length);

  if (loading) {
    return (
      <div className={classNames(FULL_SCREEN_CONTAINER_CLASSES)}>
        <SkeletonLoading
          containerCustomClassName={classNames("px-60", "pt-8")}
          numSkeletons={1}
          skeletonHeight={"8rem"}
        />
        <SkeletonLoading
          containerCustomClassName={classNames("px-60", "py-16")}
          numSkeletons={25}
          skeletonHeight={"3rem"}
        />
      </div>
    );
  }

  const enrichmentFlowsCount = data?.enrichmentFlows?.count || 0;

  return (
    <EnrichmentsContext.Provider value={{ Enrichments_refetchEnrichmentFlows }}>
      <CiroContainer className={classNames("bg-zinc-100")}>
        <div className={classNames("w-full")}>
          <div
            className={classNames(
              "flex",
              "justify-between",
              "pb-8",
              "border-b-1",
              "border-gray-200",
            )}
          >
            <CiroTitle
              icon={<SquareSparkleIcon />}
              subtitle={"Upload LinkedIn URLs or first, last, and company name"}
              title="Enrich CSV"
            />
            {Boolean(enrichmentFlowsCount) && (
              <div className={classNames("h-10")}>
                <CiroTooltipContainer
                  disabled={
                    (collectionsData?.permissions?.maxEnrichmentTables ?? 0) >
                    enrichmentFlowsCount
                  }
                  tooltip={
                    "You have reached the maximum number of enrichment tables. Please delete an existing table or upgrade your account to create a new one."
                  }
                >
                  <CiroButton
                    analyticsField="New Enrichment button pressed"
                    style={CiroButtonStyleEnum.LOUD}
                    customPaddingY="py-2"
                    onClick={() => {
                      setNewEnrichmentOpen(!newEnrichmentOpen);
                    }}
                    onBlur={() => {
                      setNewEnrichmentOpen(false);
                    }}
                    disabled={
                      (collectionsData?.permissions?.maxEnrichmentTables ??
                        0) <= enrichmentFlowsCount
                    }
                  >
                    <div
                      className={classNames(
                        "border-r-1",
                        "border-white",
                        "pr-2",
                        "mr-4",
                      )}
                    >
                      <span className={classNames("text-center")}>
                        New Enrichment List
                      </span>
                    </div>
                    <div
                      className={classNames(
                        [
                          "duration-500",
                          "ease-in-out",
                          "flex",
                          "items-center",
                          "origin-center",
                          "transform",
                          "transition-all",
                        ],
                        {
                          "rotate-180": newEnrichmentOpen,
                        },
                      )}
                    >
                      <DownArrowIcon stroke="white" height="6" width="10" />
                    </div>
                  </CiroButton>
                </CiroTooltipContainer>
                <div
                  className={classNames(
                    [
                      "ease",
                      "transition-all",
                      "duration-500",
                      "text-slate-600",
                      "overflow-y-auto",
                      "bg-white",
                      "shadow",
                      "rounded",
                    ],
                    {
                      "max-h-0": !newEnrichmentOpen,
                      "max-h-56": newEnrichmentOpen,
                    },
                  )}
                >
                  <div className={classNames("hover:bg-gray-100")}>
                    <CiroButton
                      analyticsField="upload new CSV for enrichment pressed"
                      style={CiroButtonStyleEnum.BORDERLESS}
                      onClick={() => hiddenFileInput.current!.click()}
                      customClassName={classNames(
                        "w-full",
                        "flex",
                        "justify-start",
                      )}
                    >
                      <div
                        className={classNames(
                          "flex",
                          "justify-start",
                          "w-full",
                        )}
                      >
                        <span className={classNames("mr-2")}>
                          <img
                            src={cloudUpload}
                            alt="Upload New CSV"
                            width={20}
                          />
                        </span>
                        Enrich CSV
                      </div>
                    </CiroButton>
                  </div>
                  <input
                    type="file"
                    onChange={(event) => {
                      const fileUploaded = event.target.files![0];
                      setCsvFile(fileUploaded);
                      event.target.value = "";
                    }}
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                  />
                  {collectionsData?.permissions?.canViewCollections && (
                    <div className={classNames("hover:bg-gray-100")}>
                      <CiroButton
                        analyticsField="use existing collection for enrichment pressed"
                        style={CiroButtonStyleEnum.BORDERLESS}
                        onClick={() => setCollectionModalOpen(true)}
                        customClassName={classNames("w-full")}
                      >
                        <div
                          className={classNames(
                            "flex",
                            "justify-start",
                            "w-full",
                          )}
                        >
                          <span className={classNames("mr-2")}>
                            <CollectionIcon width="15" height="15" />
                          </span>
                          Use existing collection
                        </div>
                      </CiroButton>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={classNames("w-full", "mt-8", "z-0")}>
            {!Boolean(enrichmentFlowsCount) && (
              <>
                <div className={classNames("pb-2", "font-semibold")}>
                  Upload
                </div>
                <EnrichmentFlowUploadCSVDropFileCard
                  setCsvFile={(csvFile: File) => {
                    setCsvFile(csvFile);
                  }}
                />
                {hasCollections && (
                  <div
                    className={classNames(
                      "relative",
                      "flex",
                      "py-5",
                      "items-center",
                    )}
                  >
                    <div
                      className={classNames(
                        "flex-grow",
                        "border-t",
                        "border-gray-400",
                      )}
                    ></div>
                    <span
                      className={classNames(
                        "flex-shrink",
                        "mx-4",
                        "text-gray-400",
                      )}
                    >
                      OR
                    </span>
                    <div
                      className={classNames(
                        "flex-grow",
                        "border-t",
                        "border-gray-400",
                      )}
                    ></div>
                  </div>
                )}
              </>
            )}
            <EnrichmentFlowUploadCSVPreviewModal
              csvFile={csvFile}
              onClose={() => {
                setCsvFile(null);
              }}
              permissions={enrichmentFlowUploadCsvPreviewModalPermissions}
            />
          </div>
        </div>
        <div>
          {Boolean(enrichmentFlowsCount) ? (
            <EnrichmentsTable enrichments={enrichmentsTableData} />
          ) : collectionsLoading ? (
            <SkeletonLoading
              containerCustomClassName={classNames("px-60", "pt-8")}
              numSkeletons={10}
              skeletonHeight={"8rem"}
            />
          ) : (
            hasCollections && (
              <>
                <div className={classNames("pb-2", "font-semibold")}>
                  Select a Ciro collection to start
                </div>
                <CollectionsTable
                  collectionsData={collectionsTableData}
                  showExport={false}
                  onRowClick={onRowClick}
                />
              </>
            )
          )}
        </div>
        {collectionModalOpen && (
          <EnrichmentFlowFromCollectionModal
            isOpen={collectionModalOpen}
            setIsOpen={setCollectionModalOpen}
            onClose={Enrichments_refetchEnrichmentFlows}
            clickedCollection={selectedCollection}
            permissions={enrichmentFlowFromCollectionModalPermissions}
          />
        )}
      </CiroContainer>
    </EnrichmentsContext.Provider>
  );
};
export default Enrichments;
