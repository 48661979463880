import { useContext } from "react";
import { gql } from "@apollo/client";
import {
  CreateIntegrationButton_UserAccountFragment,
  IntegrationEnum,
} from "../../../__generated__/graphql";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import classNames from "classnames";
import NavigationContext from "../../../contexts/NavigationContext";
import CiroTooltipContainer from "../../shared/CiroTooltipContainer";
import CiroLink from "../../shared/CiroLink";

export const CreateIntegrationButton_UserAccount = gql`
  fragment CreateIntegrationButton_UserAccount on UserAccount {
    org {
      crm_integration_enabled
      organizationMergeIntegration {
        integration
      }
      apolloIntegration {
        id
      }
    }
  }
`;

interface IManageIntegrationsButton {
  userAccount: CreateIntegrationButton_UserAccountFragment | null | undefined;
  homeButton?: boolean;
  includeApollo?: boolean;
}

export const enum IntegrationModalPhases {
  GET_STARTED = "GET_STARTED",
  CHOOSE_INTEGRATION = "CHOOSE_INTEGRATION",
  MERGE_MODAL = "MERGE_MODAL",
  APOLLO_API_KEY = "APOLLO_API_KEY",
}

export function ManageIntegrationsButton({
  userAccount,
  homeButton,
  includeApollo = true,
}: IManageIntegrationsButton) {
  const { integrationModalPhase, setIntegrationModalPhase } =
    useContext(NavigationContext);
  const hasIntegrations = Boolean(
    userAccount?.org?.organizationMergeIntegration?.integration ||
      userAccount?.org?.apolloIntegration?.id,
  );

  const canConnectCRM = userAccount?.org?.crm_integration_enabled;

  const { setAvailableIntegrations } = useContext(NavigationContext);

  return (
    <div className={classNames({ "w-full": homeButton })}>
      {integrationModalPhase === IntegrationModalPhases.GET_STARTED && (
        <CiroTooltipContainer
          tooltip={
            !canConnectCRM ? (
              <span>
                <CiroLink href="https://ciro.io/pricing" shouldOpenInNewTab>
                  Upgrade your account
                </CiroLink>{" "}
                to connect your CRM
              </span>
            ) : null
          }
        >
          <CiroButton
            style={CiroButtonStyleEnum.LOUD}
            onClick={() => {
              setAvailableIntegrations(
                [
                  includeApollo ? IntegrationEnum.Apollo : null,
                  IntegrationEnum.Salesforce,
                  IntegrationEnum.HubSpot,
                ].filter(Boolean) as IntegrationEnum[],
              );
              setIntegrationModalPhase(
                IntegrationModalPhases.CHOOSE_INTEGRATION,
              );
            }}
            disabled={!canConnectCRM}
            analyticsField="Connect integration"
          >
            {hasIntegrations ? "Manage integrations" : "Connect CRM"}
          </CiroButton>
        </CiroTooltipContainer>
      )}
    </div>
  );
}
