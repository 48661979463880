import classNames from "classnames";
import CiroTable from "../../shared/CiroTable/CiroTable";
import CiroTableHeader from "../../shared/CiroTable/CiroTableHeader";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import { gql } from "@apollo/client";
import {
  ContactListContactRow_ContactFragment,
  ContactListContactRow_ContactFragmentDoc,
  ContactListContactTable_ContactListFragment,
} from "../../../__generated__/graphql";
import ContactListContactRow, {
  ContactListContactRow_Contact,
} from "./ContactListRow/ContactListContactRow";
import { useFragment as getFragmentData } from "../../../__generated__";
import { IContactListFilters } from "../../../reactHooks/filters/contactList/useContactListFiltersAndSort";
import ContactListHeaderSortButton from "./ContactListHeaderSortButton";
import { useState } from "react";
import CiroDropdownCheckboxButton from "../../shared/CiroTableHeaderCheckbox/CiroDropdownCheckboxButton";
import { InformationCircleIcon, SparklesIcon } from "@heroicons/react/24/solid";
import pluralize from "pluralize";
import NumberFormat from "react-number-format";
import CiroTooltipContainer from "../../shared/CiroTooltipContainer";
import CiroLink from "../../shared/CiroLink";

export const ContactListContactTable_ContactList = gql`
  fragment ContactListContactTable_ContactList on ContactList {
    id
    contacts {
      id
      ...ContactListContactRow_Contact
    }
  }

  ${ContactListContactRow_Contact}
`;

export const CONTACTS_MAX_NUM_ROWS = 25;

interface IContactListContactTableProps {
  contactsList?: ContactListContactTable_ContactListFragment | null;
  filters: IContactListFilters;
  filteredContactIds: number[];
  selectedContactIdsToOrgContactIds: Map<number, number>;
  setSelectedContactIdsToOrgContactIds: (ids: Map<number, number>) => void;
  missingPersonaCount: number;
}

const ContactListContactTable = ({
  contactsList,
  filteredContactIds,
  filters,
  selectedContactIdsToOrgContactIds,
  setSelectedContactIdsToOrgContactIds,
  missingPersonaCount,
}: IContactListContactTableProps) => {
  const contactRowFragments = contactsList?.contacts.map((contact) =>
    getFragmentData(ContactListContactRow_ContactFragmentDoc, contact),
  );

  const [offset, setOffset] = useState(1);
  const offsetStart = (offset - 1) * CONTACTS_MAX_NUM_ROWS;
  const offsetEnd = offset * CONTACTS_MAX_NUM_ROWS;
  const offsetContactIds = filteredContactIds.slice(offsetStart, offsetEnd);

  const { hideDisqualifiedPersonas } = filters;

  const contactMap = new Map(
    contactRowFragments?.map((contact) => [contact.id, contact]) || [],
  );
  const filteredContacts =
    contactRowFragments?.filter((contact) =>
      filteredContactIds.includes(contact.id),
    ) ?? [];

  const handleSelectAll = () => {
    if (selectedContactIdsToOrgContactIds.size === filteredContacts.length) {
      setSelectedContactIdsToOrgContactIds(new Map());
    } else {
      setSelectedContactIdsToOrgContactIds(
        new Map(
          filteredContacts.map((contact) => [
            contact.id,
            contact.org_contact.id!,
          ]),
        ),
      );
    }
  };

  const toggleSelectVisible = () => {
    const newSelectedIds = new Map(selectedContactIdsToOrgContactIds);
    // Check if all visible contacts are selected
    const allVisibleSelected = offsetContactIds.every((contactId) =>
      selectedContactIdsToOrgContactIds.has(contactId),
    );

    offsetContactIds.forEach((contactId) => {
      const contact = contactMap.get(contactId);
      if (contact) {
        if (allVisibleSelected) {
          // If all visible are selected, unselect them
          newSelectedIds.delete(contact.id);
        } else {
          // If not all visible are selected, select them
          newSelectedIds.set(contact.id, contact.org_contact.id ?? 0);
        }
      }
    });
    setSelectedContactIdsToOrgContactIds(newSelectedIds);
  };

  const handleUnselectVisible = () => {
    const newSelectedIds = new Map(selectedContactIdsToOrgContactIds);
    offsetContactIds.forEach((contactId) => {
      newSelectedIds.delete(contactId);
    });
    setSelectedContactIdsToOrgContactIds(newSelectedIds);
  };

  const handleSelectContact = (
    contact: ContactListContactRow_ContactFragment,
  ) => {
    const newSelectedIds = new Map(selectedContactIdsToOrgContactIds);
    if (selectedContactIdsToOrgContactIds.has(contact.id)) {
      newSelectedIds.delete(contact.id);
    } else {
      newSelectedIds.set(contact.id, contact.org_contact.id ?? 0);
    }
    setSelectedContactIdsToOrgContactIds(newSelectedIds);
  };

  const noContacts = filteredContacts.length === 0 && missingPersonaCount === 0;

  return (
    <div
      className={classNames("rounded-t-lg", "pt-2", {
        "bg-orange-100": hideDisqualifiedPersonas,
        "bg-gray-100": !hideDisqualifiedPersonas,
      })}
    >
      <div
        className={classNames(
          "flex",
          "justify-center",
          "items-center",
          "text-sm",
          "font-medium",
          "pb-2",
          {
            "text-orange-500": hideDisqualifiedPersonas,
            "text-gray-500": !hideDisqualifiedPersonas,
          },
        )}
      >
        {hideDisqualifiedPersonas && (
          <SparklesIcon className={classNames("w-4", "h-4")} />
        )}
        <div className={classNames("pl-2")}>
          {noContacts && (
            <>
              <div>No contacts found</div>
            </>
          )}
          {!noContacts && (
            <div className={classNames("flex", "flex-row", "items-center")}>
              Showing
              {filteredContacts.length > 0 ? (
                <div className={classNames("mx-1")}>
                  <NumberFormat
                    value={offsetStart + 1}
                    thousandSeparator=","
                    displayType="text"
                  />
                  {"–"}
                  <NumberFormat
                    value={Math.min(offsetEnd, filteredContacts.length)}
                    thousandSeparator=","
                    displayType="text"
                  />
                </div>
              ) : (
                <div className={classNames("mx-1")}>0</div>
              )}
              {hideDisqualifiedPersonas ? " qualified " : " "}
              {pluralize("lead", filteredContacts.length)} of
              <NumberFormat
                value={filteredContacts.length}
                thousandSeparator=","
                displayType="text"
                className={classNames("pl-1")}
              />
              {hideDisqualifiedPersonas && missingPersonaCount > 0 && (
                <div className={classNames("px-1")}>
                  (
                  <NumberFormat
                    value={missingPersonaCount}
                    thousandSeparator=","
                    displayType="text"
                    className={classNames("pr-1")}
                  />
                  hidden)
                </div>
              )}
              {!noContacts && filteredContacts.length === 0 && (
                <CiroTooltipContainer
                  tooltip={
                    <p>
                      No qualified contacts found? Try adjusting your{" "}
                      <CiroLink
                        href={"/autopilot/search-builder"}
                        shouldOpenInNewTab
                      >
                        Sales Navigator filters
                      </CiroLink>
                      .
                    </p>
                  }
                >
                  <InformationCircleIcon className={classNames("w-4", "h-4")} />
                </CiroTooltipContainer>
              )}
            </div>
          )}
        </div>
      </div>
      <CiroTable
        scrollable={true}
        pagination={{
          currentPage: offset,
          totalPages: Math.ceil(
            filteredContacts.length / CONTACTS_MAX_NUM_ROWS,
          ),
          onPageChange: (page: number) => setOffset(page),
        }}
      >
        <thead className={classNames("table-header-group")}>
          <CiroTableRow clickable={false}>
            <CiroTableHeader isFirst={true} width="w-24">
              <CiroDropdownCheckboxButton
                canSelectRecordsInBulk={true}
                canSelectMoreRecords={
                  selectedContactIdsToOrgContactIds.size <
                  filteredContacts.length
                }
                numDisplayedRecords={filteredContacts.length}
                handleTopCheckboxSelection={toggleSelectVisible}
                noDropdownActions={false}
                noSelectionLimit={true}
                numberVisibleChecked={selectedContactIdsToOrgContactIds.size}
                selectFirstMaxNumberRecords={handleSelectAll}
                startIdx={0}
                totalCount={filteredContacts.length}
                totalNumberChecked={selectedContactIdsToOrgContactIds.size}
                unselectAll={() => {
                  setSelectedContactIdsToOrgContactIds(new Map());
                }}
                unselectVisible={handleUnselectVisible}
              />
            </CiroTableHeader>
            <CiroTableHeader>
              <ContactListHeaderSortButton
                filters={filters}
                columnName="name"
                columnDisplay="Name"
              />
            </CiroTableHeader>
            <CiroTableHeader>
              <ContactListHeaderSortButton
                filters={filters}
                columnName="companyName"
                columnDisplay="Company"
              />
            </CiroTableHeader>
            <CiroTableHeader>
              <ContactListHeaderSortButton
                filters={filters}
                columnName="persona"
                columnDisplay="Persona & Confidence"
              />
            </CiroTableHeader>
            <CiroTableHeader colSpan={2}>Rationale</CiroTableHeader>
            <CiroTableHeader isLast={true}>Contact</CiroTableHeader>
          </CiroTableRow>
        </thead>
        <tbody className={classNames("table-row-group")}>
          {offsetContactIds.map((contactId) => {
            const contact = contactMap.get(contactId);
            if (!contact) {
              return null;
            }
            return (
              <ContactListContactRow
                key={contact.id}
                contactListId={contactsList?.id ?? null}
                contact={contact}
                isSelected={selectedContactIdsToOrgContactIds.has(contact.id)}
                onSelect={() => handleSelectContact(contact)}
              />
            );
          })}
        </tbody>
      </CiroTable>
    </div>
  );
};

export default ContactListContactTable;
