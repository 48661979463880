import classNames from "classnames";
import CiroModal from "./CiroModal";
import { SelectTemplateButton } from "./SelectTemplateButton";
import {
  BriefcaseIcon,
  DocumentIcon,
  PencilSquareIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import XIcon from "../../assets/img/icons/XIcon";
import pluralize from "pluralize";

interface ITemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  setTemplate: (template: string | null) => void;
  onTemplateSelected: () => void;
  accountsSelected?: number;
}

const CiroTemplateModal = ({
  isOpen,
  onClose,
  setTemplate,
  onTemplateSelected,
  accountsSelected,
}: ITemplateModalProps) => {
  return (
    <CiroModal isOpen={isOpen} onClose={onClose}>
      <div className={classNames("mb-10")}>
        <div
          className={classNames(
            "flex",
            "text-2xl",
            "items-center",
            "justify-between",
            "font-bold",
            "text-[21px]",
          )}
        >
          <div className={classNames("")}>Enrich Accounts</div>
          <span
            onClick={() => onClose()}
            className={classNames("cursor-pointer")}
          >
            <XIcon />
          </span>
        </div>
        {accountsSelected && (
          <div
            className={classNames(
              "text-neutral-600",
              "text-sm",
              "font-normal",
              "leading-tight",
            )}
          >
            {accountsSelected} {pluralize("Account", accountsSelected)}{" "}
            selected.
          </div>
        )}
      </div>
      <div
        className={classNames(
          "text-sm",
          "font-semibold",
          "text-neutral-600",
        )}
      >
        Start here
      </div>
      <div className={classNames("mb-10")}>
        <SelectTemplateButton
          setTemplateSelected={setTemplate}
          template={null}
          buttonText="Start from scratch"
          icon={
            <DocumentIcon
              className={classNames(
                "h-4",
                "w-4",
                "mr-2",
              )}
            />
          }
          onClick={() => onTemplateSelected()}
        />
      </div>
      <div
        className={classNames(
          "text-sm",
          "font-semibold",
          "text-neutral-600",
        )}
      >
        Start with a Template
      </div>
      <SelectTemplateButton
        setTemplateSelected={setTemplate}
        template="summarizeInOneSentence"
        buttonText="Summarize what these businesses do"
        icon={
          <PencilSquareIcon
            className={classNames("h-4", "w-4", "mr-2")}
          />
        }
        onClick={() => onTemplateSelected()}
      />
      <SelectTemplateButton
        setTemplateSelected={setTemplate}
        template="findSeniorSalesLeader"
        buttonText="Find the most senior sales leader"
        icon={
          <BriefcaseIcon
            className={classNames("h-4", "w-4", "mr-2")}
          />
        }
        onClick={() => onTemplateSelected()}
      />
      <SelectTemplateButton
        setTemplateSelected={setTemplate}
        template="findOwnerEmails"
        buttonText="Find SMB owner names & enrich with emails"
        icon={
          <UserIcon
            className={classNames("h-4", "w-4", "mr-2")}
          />
        }
        onClick={() => onTemplateSelected()}
      />
    </CiroModal>
  );
};

export default CiroTemplateModal;
