import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

interface IHasPhoneNumberFilterProps {
  hasPhoneNumberFilter: boolean | null;
  setHasPhoneNumberFilter: (v: boolean | null) => void;
}

const HasPhoneNumberFilter = ({
  hasPhoneNumberFilter,
  setHasPhoneNumberFilter,
}: IHasPhoneNumberFilterProps) => {
  const filterOptions = [
    {
      value: true,
      label: "Has phone number",
    },
    {
      value: false,
      label: "No phone number",
    },
  ];

  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={hasPhoneNumberFilter == null ? 0 : 1}
      sectionTitle="Phone Number"
      filterOptions={[
        {
          filter: (
            <CiroDropDown
              options={filterOptions}
              value={hasPhoneNumberFilter}
              onChange={(newValue) => {
                setHasPhoneNumberFilter(newValue ?? null);
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default HasPhoneNumberFilter;
