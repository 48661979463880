const MSG_FROM_EXTENSION = "MSG_FROM_EXTENSION";
const MSG_FROM_CIRO_PAGE = "MSG_FROM_CIRO_PAGE";

// Actions
export const LAUNCH_AUTOPILOT = "LAUNCH_AUTOPILOT";
export const CHECK_EXTENSION_INSTALLED = "CHECK_EXTENSION_INSTALLED";

async function sendPayloadToExtension<
  T extends { action: string; [key: string]: any },
>({ payload, timeout = 500 }: { payload: T; timeout?: number }) {
  return new Promise((resolve, reject) => {
    let receivedResponse = false;

    // Listen for a response
    function responseHandler(event: MessageEvent) {
      if (event.source === window && event.data.type === MSG_FROM_EXTENSION) {
        receivedResponse = true;
        window.removeEventListener("message", responseHandler);
        resolve(event.data);
      }
    }

    window.addEventListener("message", responseHandler);

    // Send message to content script
    window.postMessage({ type: MSG_FROM_CIRO_PAGE, ...payload }, window.origin);

    // Timeout check
    setTimeout(() => {
      window.removeEventListener("message", responseHandler);
      if (!receivedResponse) {
        reject(new Error("Extension not installed or not responding."));
      }
    }, timeout);
  });
}

export default sendPayloadToExtension;
