import { useAuth0 } from "@auth0/auth0-react";
import logo from "../../assets/ciro_logo_transparent.png";
import NavLink from "./NavLink";
import LogoutIcon from "../../assets/img/icons/LogoutIcon";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ISharedNavigationProps } from "./NavigationContainer";
import NavigationLinksSection from "./NavigationLinksSection";
import CiroCoinPill from "../shared/CiroCoinPill";
import SkeletonLoading from "../shared/SkeletonLoading";

const LargeScreenNavigation = ({
  isLoggedIn,
  loading,
  navData,
  stopImpersonating,
}: ISharedNavigationProps) => {
  const { logout } = useAuth0();

  if (!isLoggedIn) {
    return null;
  }

  if (loading) {
    return (
      <div
        className={classNames([
          "bg-white",
          "border-gray-200",
          "border-r-2",
          "fixed",
          "flex-col",
          "flex",
          "h-screen",
          "hidden",
          "justify-between",
          "lg:w-72",
          "lg:flex",
          "items-center",
        ])}
      >
        <SkeletonLoading
          numSkeletons={5}
          skeletonHeight={"30px"}
          containerCustomClassName={classNames(
            "w-60",
            "flex",
            "flex-col",
            "gap-8",
            "mt-8",
          )}
        />
      </div>
    );
  }

  const impersonating = navData?.userAccount?.impersonating;
  const creditPlan = navData?.userAccount?.org?.credit_plan;

  return (
    <div
      className={classNames([
        "bg-white",
        "border-gray-200",
        "border-r-2",
        "fixed",
        "flex-col",
        "flex",
        "h-screen",
        "hidden",
        "justify-between",
        "lg:w-72",
        "lg:flex",
      ])}
    >
      <div className="flex flex-col">
        <Link to="/">
          <img
            src={logo}
            width="80"
            height="70"
            className="ml-5 inline-block align-top my-6"
            alt="Ciro logo"
          />
        </Link>
        <NavigationLinksSection
          navData={navData}
          stopImpersonating={stopImpersonating}
        />
      </div>
      <div>
        {creditPlan && (
          <div
            className={classNames(
              "bg-gray-100",
              "mx-4",
              "mb-4",
              "p-4",
              "rounded-lg",
            )}
          >
            <div
              className={classNames("flex", "items-center", "justify-between")}
            >
              <span
                className={classNames(
                  "text-neutral-900",
                  "font-sans",
                  "text-sm",
                  "font-semibold",
                  "leading-5",
                  "tracking-normal",
                  "mr-2",
                )}
              >
                Credits
              </span>
              <CiroCoinPill amount={creditPlan.balance || 0} />
            </div>
          </div>
        )}
        {impersonating && (
          <NavLink
            onClick={stopImpersonating}
            title={`Impersonating (${impersonating.email})`}
            icon={<LogoutIcon />}
          />
        )}
        {!impersonating && (
          <NavLink
            onClick={() => {
              logout({ returnTo: window.location.origin });
              sessionStorage?.localStorage?.clear();
            }}
            title="Log out"
            icon={<LogoutIcon />}
          />
        )}
        <div className="flex justify-evenly py-4 border-t-2 border-t-stone-200">
          <a
            className="no-underline my-1 text-stone-300 text-xs hover:text-neutral-600"
            href="https://www.ciro.io/terms-of-service"
          >
            Terms of service
          </a>
          <a
            className="no-underline my-1 text-stone-300 text-xs hover:text-neutral-600"
            href="https://www.ciro.io/privacy-policy"
          >
            Privacy policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default LargeScreenNavigation;
